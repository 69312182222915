<template>
  <buttton class="btn__component__arrrow" @click="edit()">
    <span
      class="btn__component__arrrow__text"
      :style="{ marginRight: getMarginRight }"
      >{{ title }}
    </span>
    <svg
      class="btn__component__arrrow__svg"
      :style="{ width: getWidthArrow }"
      width="44"
      height="24"
      viewBox="0 0 44 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5V10.5ZM43.0607 13.0607C43.6464 12.4749 43.6464 11.5251 43.0607 10.9393L33.5147 1.3934C32.9289 0.807612 31.9792 0.807612 31.3934 1.3934C30.8076 1.97918 30.8076 2.92893 31.3934 3.51472L39.8787 12L31.3934 20.4853C30.8076 21.0711 30.8076 22.0208 31.3934 22.6066C31.9792 23.1924 32.9289 23.1924 33.5147 22.6066L43.0607 13.0607ZM2 13.5H42V10.5H2V13.5Z"
        fill="url(#paint0_linear_1072_8367)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1072_8367"
          x1="2"
          y1="12"
          x2="5.89864"
          y2="24.3742"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7277FF" />
          <stop offset="1" stop-color="#FF5A5A" />
        </linearGradient>
      </defs>
    </svg>
  </buttton>
</template>

<script  lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BtnCopmonentBgGradient",
  props: {
    title: String,
    width_arrow: String,
    margin_right: String,
    methods: String || undefined,
  },
  computed: {
    getWidthArrow(): string {
      return this.width_arrow + "px";
    },
    getMarginRight(): string {
      return this.margin_right + "px";
    },
  },
  methods: {
    edit(): void {
      if (this.methods === "linkToCourse") {
        return this.$emit("linkToCourse");
      }
      if (this.methods === "linkToFreeLearn") {
        return this.$emit("linkToFreeLearn");
      }
      if (this.methods === "modalOpenFooter") {
        return this.$emit("openModalBtn");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.btn__component__arrrow {
  display: flex;
  align-items: center;
  border: none;
  background: $color-dark-E343D;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: all 0.2s ease-out;
  user-select: none;

  .btn__component__arrrow__text {
    font-family: "Gramatika-Medium";
    margin-right: 16px;
    margin-top: -5px;
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
  }

  .btn__component__arrrow__svg {
    width: 24px;
  }
}

.btn__component__arrrow:hover {
  .btn__component__arrrow__text {
    color: $color-hover;
  }

  .btn__component__arrrow__svg path {
    fill: $color-hover;
  }
}

.btn__component__arrrow:active {
  .btn__component__arrrow__text {
    color: $color-form-text;
  }

  .btn__component__arrrow__svg path {
    fill: $color-form-text;
  }
}

.btn__component__arrrow:disabled {
  .btn__component__arrrow__text {
    color: $color-blue;
  }

  .btn__component__arrrow__svg path {
    fill: $color-blue;
  }
}
</style>