<template>
    <section class="courses__corporate" id="corporate">
        <div class="courses__corporate__wrap">
            <h2 class="courses__corporate__title">Корпоративные программы обучения</h2>
            <div class="courses__corporate__text_container">
                <p class="courses__corporate__text">
                    Повышайте квалификацию кадров за счет профессионального обучения с использованием математического, имитационного моделирования и отображения процессов в VR для более экономичной и реалистичной отработки практических кейсов.
                    <span>Функциональные возможности:</span>
                </p>
                <ul>
                    <li class="courses__corporate__text courses__corporate__item">Организацию подготовки пользователей и контроль результатов.</li>
                    <li class="courses__corporate__text courses__corporate__item">Загрузку моделей систем кондиционирования и вентиляции (СКВ), сценариев сборки, эксплуатации.</li>
                    <li class="courses__corporate__text courses__corporate__item">Визуализацию конструкции устройств в 3D пространстве.</li>
                    <li class="courses__corporate__text courses__corporate__item">Работу с нейронными сетями, основанную на данных реальных комплексов и подборе настроек.</li>
                    <li class="courses__corporate__text courses__corporate__item">Моделирование функционирования СКВ в различных условиях.</li>
                </ul>
            </div>
            <button class="courses__corporate__btn"><span>Узнать подробности</span></button>
        </div>
        <div class="courses__corporate__img">
        </div>
    </section>
</template>

<script>
export default {
    name: 'CoursesCorporateComponent',
};
</script>

<style lang="scss" scoped>
.courses__corporate{
    box-sizing: border-box;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 148px;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px){
        margin-top: 80px;
        padding-left: 60px;
        padding-right: 60px;
        flex-direction: column-reverse;
        justify-content: center;
    }

    @media (max-width: 576px){
        margin-top: 64px;
        padding-left: 16px;
        padding-right: 16px;
    }

    &__wrap{
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 50%;
        margin-right: 16px;

        @media (max-width: 991px){
            width: 100%;
            padding-right: 0px;
        }
    }

    &__title{
        font-family: 'Gramatika-Medium';
        font-size: 3rem;
        line-height: 4.5rem;
        font-weight: 400;
        color: $color-dark-E343D;
        margin-top: -10px;
        margin-bottom: 32px;

        @media (max-width: 991px){
           font-size: 1.75rem;
            line-height: 2.625rem;
            margin-bottom: 30px;
        }
    }

    &__text_container{
        margin-bottom: 56px;
    }

    &__text{
        font-family: 'Inter-Regular';
        font-size: 1.25rem;
        line-height: 1.875rem;
        color: $color-dark-E343D;
        margin-bottom: 0px;

        span{
            display: block;
        }

        @media (max-width: 991px){
            margin-bottom: 24px;
        }

        @media (max-width: 576px) {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
    &__item{
        margin-left: 22px;
    }

    &__btn{
        box-sizing: border-box;
        background: linear-gradient(135deg, #7277FF 0%, #FF5A5A 100%) border-box;
        border: 2px solid transparent;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-out;
        user-select: none;
        cursor: pointer;
        align-self: flex-start;
        padding: 6px 14px;

        @media (max-width: 991px){

            align-self: center;
        }
        @media (max-width: 576px){
            display: flex;
            width: 100%;

        }

        span{
            font-family: 'Inter-Medium';
            font-size: 1rem;
            line-height: 1.5rem;
            color: $color-text;
        }
        &:hover {
            background: $color-hover;
        }
        &:active {
            background: $color-form-text;
        }
        &:disabled {
            background: $color-blue;
        }
    }

    &__img{
        width: 50%;
        background-image: url("@/assets/images/courses/courses-corporate.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 24px;
        
        @media (max-width: 991px){
            margin-bottom: 42px;
            width: 100%;
            height: 220px;
            background-position: 50% 30%;
        }

        @media (max-width: 576px){
            height: 128px;
            margin-bottom: 32px;
        }
    }
}
</style>