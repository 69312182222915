<template>
  <p class="error__message">{{ errorMessage }}</p>
</template>

<script>
export default {
  name: "ErrorComponent",
  props: ["error"],
  data() {
    return {
      errorMessage: this.error,
    };
  },
};
</script>

<style lang="scss" scoped>
.error__message{
    margin-top: 30px;
    font-family: "Gramatika-Medium";
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    color: $color-primary-red;
    min-height: 100px;
}
</style>