<template>
  <div class="learn-process" id="learn-process">
    <h2 class="learn-process__title">Как проходит обучение</h2>
    <div class="learn-process__list">
      <div class="learn-process__item" ref="refItem1">
        <img
          src="@/assets/images/course/learn_process1.svg"
          alt="img"
          :class="getLearnProcess1"
        />
        <div class="learn-process__item__description">
          <p class="learn-process__count">/01</p>
          <p class="learn-process__subtitle">
            Получите доступ к материалам курса
          </p>
          <p class="learn-process__text">
            После оплаты курса вы получите доступ к личному кабинету на нашей
            образовательной платформе в которой появится вся информация по
            выбранному направлению
          </p>
        </div>
      </div>

      <div class="learn-process__item" ref="refItem2">
        <img
          src="@/assets/images/course/learn_process2.svg"
          alt="img"
          :class="getLearnProcess2"
        />
        <div class="learn-process__item__description">
          <p class="learn-process__count">/02</p>
          <p class="learn-process__subtitle">
            Изучайте модули в удобном для вас темпе
          </p>
          <p class="learn-process__text">
            Вы самостоятельно подбираете время и темп изучения программы
          </p>
        </div>
      </div>

      <div class="learn-process__item" ref="refItem3">
        <img
          src="@/assets/images/course/learn_process3.svg"
          alt="img"
          :class="getLearnProcess3"
        />
        <div class="learn-process__item__description">
          <p class="learn-process__count">/03</p>
          <h3 class="learn-process__subtitle">
            Попробуйте новейшие технологии на практике
          </h3>
          <p class="learn-process__text">
            Модули содержат теоретическую часть, видеоуроки и практику в VR,
            которая помогает отработать полученные знания в условиях максимально
            приближенным к реальным
          </p>
        </div>
      </div>

      <div class="learn-process__item" ref="refItem4">
        <img
          src="@/assets/images/course/learn_process4.svg"
          alt="img"
          :class="getLearnProcess4"
        />
        <div class="learn-process__item__description">
          <p class="learn-process__count">/04</p>
          <p class="learn-process__subtitle">Пройдите итоговую аттестацию</p>
          <p class="learn-process__text">
            По окончании курса вас ждет итоговое тестирование и VR-тест для
            проверки теоретических знаний и практических навыков
          </p>
        </div>
      </div>

      <div class="learn-process__item" ref="refItem5">
        <img
          src="@/assets/images/course/learn_process5.svg"
          alt="img"
          :class="getLearnProcess5"
        />
        <div class="learn-process__item__description">
          <p class="learn-process__count">/05</p>
          <p class="learn-process__subtitle">
            Получите подтверждающие документы установленного образца
          </p>
          <p class="learn-process__text">
            При успешном прохожении аттестации в вашем личном кабинете появится
            электронный сертификат об окончании курс. Так же наш менеджер
            свяжется с вами и отправит вам оригинальную версию документов по
            почте
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

let refItem1: HTMLDivElement;
let refItem2: HTMLDivElement;
let refItem3: HTMLDivElement;
let refItem4: HTMLDivElement;
let refItem5: HTMLDivElement;

export default defineComponent({
  name: "LearnProcess",
  data() {
    return {
      learnProcess1: false,
      learnProcess2: false,
      learnProcess3: false,
      learnProcess4: false,
      learnProcess5: false,
      data:this.dataArr
    };
  },
  props: ["dataArr"],
  mounted() {
    window.addEventListener("scroll", this.checkScrollPosition);
    refItem1 = this.$refs.refItem1 as HTMLDivElement;
    refItem2 = this.$refs.refItem2 as HTMLDivElement;
    refItem3 = this.$refs.refItem3 as HTMLDivElement;
    refItem4 = this.$refs.refItem4 as HTMLDivElement;
    refItem5 = this.$refs.refItem5 as HTMLDivElement;
  },
  methods: {
    checkScrollPosition() {
      if (
        window.innerHeight - refItem1.getBoundingClientRect().height >
        refItem1.getBoundingClientRect().y
      ) {
        this.learnProcess1 = true;
      }
      if (
        window.innerHeight - refItem2.getBoundingClientRect().height >
        refItem2.getBoundingClientRect().y
      ) {
        this.learnProcess2 = true;
      }
      if (
        window.innerHeight - refItem3.getBoundingClientRect().height >
        refItem3.getBoundingClientRect().y
      ) {
        this.learnProcess3 = true;
      }
      if (
        window.innerHeight - refItem4.getBoundingClientRect().height >
        refItem4.getBoundingClientRect().y
      ) {
        this.learnProcess4 = true;
      }
      if (
        window.innerHeight - refItem5.getBoundingClientRect().height >
        refItem5.getBoundingClientRect().y
      ) {
        this.learnProcess5 = true;
      }
    },
  },
  computed: {
    getLearnProcess1(): string {
      return this.learnProcess1
        ? "learn-process__item__img learn-process__item__img__active "
        : "learn-process__item__img";
    },
    getLearnProcess2(): string {
      return this.learnProcess2
        ? "learn-process__item__img learn-process__item__img__active "
        : "learn-process__item__img";
    },
    getLearnProcess3(): string {
      return this.learnProcess3
        ? " learn-process__item__img learn-process__item__img__active "
        : "learn-process__item__img";
    },
    getLearnProcess4(): string {
      return this.learnProcess4
        ? "learn-process__item__img learn-process__item__img__active "
        : "learn-process__item__img";
    },
    getLearnProcess5(): string {
      return this.learnProcess5
        ? "learn-process__item__img learn-process__item__img__active "
        : "learn-process__item__img";
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkScrollPosition);
  },
});
</script>

<style lang="scss" scoped>
.learn-process {
  margin-top: 48px;
  width: 100%;
  position: relative;
  left: -80px;
  background: $color-dark-E343D;
  padding: 0 80px 48px 80px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (max-width: 991px) {
    margin-top: 64px;
    padding: 0px 60px 48px 60px;
    left: -60px;
  }

  @media (max-width: 576px) {
    margin-top: 64px;
    padding: 0px 16px 24px 16px;
    left: -16px;
    gap: 16px;
  }
}

.learn-process__title {
  font-family: Gramatika-Medium;
  font-size: 3rem;
  font-weight: 400;
  line-height: 4.5rem;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 536px;

  @media (max-width: 991px) {
    font-size: 2.25rem;
    line-height: 3.375rem;
    max-width: 402px;
  }

  @media (max-width: 576px) {
    font-size: 1.75rem;
    line-height: 2.625rem;
  }
}

.learn-process__list {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 24px;
  box-sizing: border-box;

  @media (max-width: 991px) {
    row-gap: 36px;
  }

  @media (max-width: 576px) {
    row-gap: 24px;
  }
}

.learn-process__item {
  display: grid;
  grid-template-columns: repeat(2, minmax(400px, 600px));
  justify-content: space-between;
  grid-template-rows: auto;
  box-sizing: border-box;
  padding: 16px 0;
  align-items: center;
  gap: 40px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 0;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.learn-process__item:nth-child(even) .learn-process__item__description {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;

  @media (max-width: 991px) {
    grid-row-start: inherit;
    grid-row-end: inherit;
    grid-column-start: inherit;
    grid-column-end: inherit;

    & .learn-process__count {
      align-self: end;
    }
  }
}

.learn-process__item__img {
  width: 100%;
  max-width: 600px;
  max-height: 302px;
  opacity: 0.1;
}

.learn-process__item__img__active {
  opacity: 1;
}

.learn-process__item__description {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 576px) {
    gap: 16px;
  }
}

.learn-process__count {
  font-family: Inter-Medium;
  font-size: 4rem;
  font-weight: 900;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 120px;
}

.learn-process__subtitle {
  color: $color-text;
  font-family: Gramatika-Medium;
  font-size: 2rem;
  line-height: 3rem;

  @media (max-width: 576px) {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

.learn-process__text {
  color: $color-text;
  font-family: Inter-Regular;
  font-size: 1.25rem;
  line-height: 1.875rem;

  @media (max-width: 576px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>