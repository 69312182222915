<template>
    <div>
      <HeadersComponent />
      <ContactsDetailsComponent/>
      <ContactsMapComponent/>
      <ContactsFormComponent/>
      <FooterComponent hiddenBtn="true"/>
    </div>
  </template>
  
  <script lang="ts">
  import HeadersComponent from "@/components/headers/Headers.vue";
  import ContactsDetailsComponent from '@/components/contacts/contactsDetailsComponent/ContactsDetailsComponent.vue';
  import ContactsMapComponent from '@/components/contacts/contactsMapComponent/ContactsMapComponent.vue';
  import ContactsFormComponent from '@/components/contacts/contactsFormComponent/ContactsFormComponent.vue';
  import FooterComponent from "@/components/footer/Footer.vue";

  export default {
    name: "ContactsPage",
    components: {
      HeadersComponent,
      ContactsDetailsComponent,
      ContactsMapComponent,
      ContactsFormComponent,
      FooterComponent,
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>