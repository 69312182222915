<template>
  <section class="footer">
    <Error401ModalComponent
      v-if="modalActive"
      @closeModalBtn="closeModalBtn()"
    />
    <button class="btn__up" :style="hiddenBtn ? 'display:none' : ''">
      <span class="btn__up__icons" @click="click">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="10"
          viewBox="0 0 18 10"
          fill="none"
        >
          <path
            d="M17 9L9 1L1 9"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </button>
    <footer class="footer__container">
      <div class="footer__logo__wrap">
        <LogoComponent class="footer__logo" />
        <h3 class="footer__description footer_logo__description">
          Обучение и профессиональная подготовка специалистов для
          климатического, холодильного и строительного бизнеса.
        </h3>
      </div>

      <div class="footer__menu">
        <h2 class="footer__description">
          Обучение и профессиональная подготовка специалистов для
          климатического, холодильного и строительного бизнеса
        </h2>
        <div class="footer__sections">
          <h3 class="footer__title">Разделы</h3>
          <ul class="footer__section__list">
            <li>
              <router-link to="/" class="footer__sections__item"
                >О центре</router-link
              >
            </li>
            <li>
              <router-link to="/courses" class="footer__sections__item"
                >Курсы</router-link
              >
            </li>
            <li>
              <router-link to="/blog" class="footer__sections__item"
                >Блог</router-link
              >
            </li>
            <li>
              <router-link to="/" class="footer__sections__item"
                >Контакты</router-link
              >
            </li>
          </ul>
        </div>

        <div class="footer__sections">
          <h3 class="footer__title">Документы</h3>
          <ul class="footer__section__list">
            <li>
              <router-link
                to="/license"
                class="footer__sections__item"
                target="_blank"
                >Лицензия</router-link
              >
            </li>

            <li>
              <router-link
                to="/diploms"
                class="footer__sections__item"
                target="_blank"
                >Дипломы</router-link
              >
            </li>
            <li>
              <router-link to="/сontract" class="footer__sections__item"
                >Договор оферты</router-link
              >
            </li>
            <li>
              <router-link to="/tariffs" class="footer__sections__item"
                >Тарифы</router-link
              >
            </li>
            <li>
              <router-link to="/requirements" class="footer__sections__item"
                >О системе</router-link
              >
            </li>
          </ul>
        </div>

        <div class="footer__contacs">
          <div class="footer__contacs__wraper">
            <span class="footer__icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M19.4542 14.7815L16.6631 11.9904C15.6663 10.9936 13.9717 11.3924 13.573 12.6882C13.274 13.5854 12.2772 14.0838 11.38 13.8844C9.38643 13.386 6.69505 10.7943 6.19665 8.70098C5.89761 7.80381 6.49569 6.807 7.39282 6.508C8.68867 6.10928 9.08739 4.41471 8.09058 3.4179L5.29953 0.626839C4.50208 -0.0709256 3.30591 -0.0709256 2.60815 0.626839L0.714213 2.52077C-1.17972 4.51439 0.913575 9.79746 5.59857 14.4825C10.2836 19.1674 15.5666 21.3605 17.5603 19.3668L19.4542 17.4729C20.152 16.6754 20.152 15.4793 19.4542 14.7815Z"
                  fill="white"
                />
              </svg>
            </span>
            <div>
              <p class="footer__contacs__text">
                <a href="tel:+74996538790" class="footer__contacs__link"
                  >+7 499 653 87 90</a
                >
              </p>
              <p class="footer__contacs__text">
                <a href="tel:+74996539510" class="footer__contacs__link"
                  >+7 499 653 95 10</a
                >
              </p>
            </div>
          </div>

          <div class="footer__contacs__wraper">
            <span class="footer__icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
              >
                <path
                  d="M11.6714 10.3571C11.1739 10.6888 10.5959 10.8641 10 10.8641C9.40414 10.8641 8.82617 10.6888 8.32859 10.3571L0.133164 4.89328C0.0876953 4.86297 0.0433984 4.83137 0 4.79887V13.7519C0 14.7784 0.833008 15.593 1.84113 15.593H18.1588C19.1853 15.593 20 14.76 20 13.7519V4.79883C19.9565 4.83141 19.9121 4.86309 19.8665 4.89344L11.6714 10.3571Z"
                  fill="white"
                />
                <path
                  d="M0.783203 3.91814L8.97863 9.38197C9.28887 9.58881 9.64441 9.69221 9.99996 9.69221C10.3555 9.69221 10.7111 9.58877 11.0214 9.38197L19.2168 3.91814C19.7072 3.59139 20 3.04451 20 2.45428C20 1.43939 19.1743 0.61377 18.1595 0.61377H1.84051C0.825664 0.613809 0 1.43943 0 2.45525C0 3.04451 0.292812 3.59139 0.783203 3.91814Z"
                  fill="white"
                />
              </svg>
            </span>
            <div>
              <p class="footer__contacs__text">
                <a href="mailto:info@prof2.ru" class="footer__contacs__link"
                  >info@prof2.ru</a
                >
              </p>
              <p class="footer__contacs__text">
                <a href="mailto:info@vercont.ru" class="footer__contacs__link"
                  >info@vercont.ru</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="footer__feedback">
        <div class="footer__feedback__contnent">
          <h4 class="footer__feedback__text">Нам интересно ваше мнение!</h4>
          <BtnCopmonentBgGradient
            class="footer__feedback__btn"
            title="Оставьте свои вопросы и предложения"
            methods="modalOpenFooter"
            @openModalBtn="openModalBtn()"
          />
        </div>
        <div class="footer__feedback__icons">
          <img
            class="footer__feedback__logo-fond"
            src="@/assets/icons/footer/logo-fond.svg"
            alt="logo-fond"
          />
          <img
            class="footer__feedback__logo-vercont"
            src="@/assets/icons/footer/logo-vercont.svg"
            alt="logo-vercont"
          />
        </div>
      </div>
      <div class="footer__info">
        <h4 class="footer__info__text">
          Лицензия на осуществление образовательной деятельности № 035443 от
          16.09.2014
        </h4>
        <h4 class="footer__info__text">
          Все права пренадлежат компании ООО "Верконт сервис"
        </h4>
      </div>
      <div class="footer__info__creator">
        <p class="footer__info__creator__text">Сделано в:</p>
        <a href="https://club.1t.ru" class="footer__info__creator__logo">
          <img src="@/assets/icons/footer/logo_1t.svg" alt="icon" />
        </a>

        <span class="footer__info__creator__text__commads"> |</span>
        <span class="footer__info__creator__text__commads"> BitWizArts</span>
      </div>
    </footer>
  </section>
</template>

<script lang="ts">
import BtnCopmonentBgGradient from "../../components/btnCopmonent/BtnCopmonentArrow.vue";
import LogoComponent from "../logoComponent/LogoComponent.vue";
import Error401ModalComponent from "../error401/Error401ModalComponent.vue";
import { defineComponent } from "vue";

interface IData {
  modalActive: boolean;
}

export default defineComponent({
  name: "FooterComponent",
  data(): IData {
    return {
      modalActive: false,
    };
  },
  components: {
    BtnCopmonentBgGradient,
    LogoComponent,
    Error401ModalComponent,
  },
  props: {
    hiddenBtn: String,
  },

  methods: {
    click(): void {
      scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    openModalBtn() {
      this.modalActive = true;
    },
    closeModalBtn(): void {
      this.modalActive = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.footer {
  max-width: 1440px;
  position: relative;
  margin: 144px auto 0 auto;
}

.btn__up {
  position: absolute;
  top: -64px;
  right: 80px;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: $color-form-text;
  cursor: pointer;

  @media (max-width: 768px) {
    bottom: -125px;
    right: 60px;
  }

  @media (max-width: 576px) {
    right: 16px;
  }
}

.btn__up__icons {
  width: 18px;
  height: 16px;
}

.footer__container {
  max-width: 1440px;
  background: $color-dark-E343D;
  color: $color-text;
  padding: 80px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (max-width: 991px) {
    padding: 60px 70px;
  }

  @media (max-width: 576px) {
    padding: 16px 16px 48px 16px;
  }
}

.footer__logo__wrap {
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: start;
  }
}

.footer__logo {
  @media (max-width: 1280px) {
    width: 93px;
    height: 28px;
    margin-right: 80px;
  }
}

.footer__description {
  width: 24%;
  font-family: "Gramatika-Medium";
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  background-image: $color-gradient;
  background-clip: text;
  color: transparent;

  @media (max-width: 1280px) {
    width: 29%;
  }

  @media (max-width: 1280px) {
    width: 36%;
  }

  @media (max-width: 991px) {
    display: none;
    font-family: "Gramatika-Medium";
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 388px;
  }

  @media (max-width: 768px) {
    margin-top: 32px;
    max-width: 100%;
  }
}

.footer_logo__description {
  display: none;

  @media (max-width: 991px) {
    width: 449px;
  }

  @media (max-width: 640px) {
    width: 473px;
  }

  @media (max-width: 480px) {
    width: 449px;
  }

  @media (max-width: 390px) {
    width: 358px;
  }

  @media (max-width: 360px) {
    width: 328px;
  }

  @media (max-width: 360px) {
    width: 288px;
  }
}

.footer__menu {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;

  @media (max-width: 1280px) {
    margin-top: 44px;
  }

  @media (max-width: 991px) {
    margin-top: 48px;
    flex-wrap: wrap;
  }

  @media (max-width: 720px) {
    margin-top: 40px;
    justify-content: flex-start;
  }
}

.footer__sections {
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    margin-right: 40px;
  }

  @media (max-width: 720px) {
    justify-content: flex-start;
    min-width: 176px;
  }

  @media (max-width: 640px) {
    width: 50%;
    margin-right: 0;
  }

  @media (max-width: 390px) {
    width: 100%;
    min-width: 163px;
    margin-bottom: 24px;
  }
}

.footer__sections:nth-last-child(2) {
  @media (max-width: 390px) {
    margin-bottom: 0;
  }
}

.footer__title {
  font-family: "Inter-Regular";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: $color-form-text;

  @media (max-width: 991px) {
    color: $color-form-text;
  }

  @media (max-width: 576px) {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

.footer__section__list li {
  list-style-type: none;
  margin-top: 14px;

  @media (max-width: 1280px) {
    margin-top: 16px;
  }

  @media (max-width: 576px) {
    margin-top: 16px;
    width: 100%;
    margin-right: 5px;
  }
}

.footer__sections__item {
  color: $color-text;
  font-family: "Gramatika-Medium";
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 576px) {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

.footer__contacs {
  display: flex;
  flex-direction: column;

  @media (max-width: 720px) {
    margin-top: 80px;
  }

  @media (max-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
}

.footer__contacs__wraper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 640px) {
    width: 50%;
    margin-bottom: 0;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 24px;
  }
}

.footer__contacs__wraper:nth-child(2) {
  margin-bottom: 0;
}

.footer__icons {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}

.footer__contacs__text {
  font-family: "Inter-Regular";
  font-size: 1rem;
  line-height: 1.5rem;

  @media (max-width: 576px) {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

.footer__contacs__text:nth-child(even) {
  font-family: "Inter-Regular";
  margin-top: 8px;
}

.footer__contacs__text:nth-child(0) {
  margin-bottom: 0;
}

.footer__contacs__link {
  color: $color-text;
  text-decoration: none;
}

.footer__feedback {
  margin-top: 28px;
  padding-bottom: 25px;
  border-bottom: 1px solid $color-form-text;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1280px) {
    margin-top: 45px;
    padding-bottom: 30px;
  }

  @media (max-width: 991px) {
    margin-top: 56px;
    align-items: flex-start;
  }

  @media (max-width: 776px) {
    flex-direction: column;
    gap: 40px;
  }

  @media (max-width: 640px) {
    margin-top: 40px;
    padding-bottom: 40px;
  }
}

.footer__feedback__text {
  font-family: "Inter-Regular";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: normal;
}

.footer__feedback__btn {
  box-sizing: border-box;
  margin-top: 16px;

  @media (max-width: 991px) {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

.footer__feedback__icons {
  display: flex;
  gap: 40px;
  justify-content: space-between;

  @media (max-width: 776px) {
    order: -1;
    width: 65px;
    height: 32px;
  }
}

.footer__feedback__logo-fond {
  width: 81.914px;
  height: 40px;

  @media (max-width: 991px) {
    width: 65px;
    height: 32px;
  }
}

.footer__feedback__logo-vercont {
  width: 63x;
  height: 40px;

  @media (max-width: 991px) {
    width: 51px;
    height: 32px;
  }
}

.btn__component__arrrow__svg {
  min-width: 24px;
}

.footer__info {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }

  &__text {
    max-width: 308px;
    font-family: "Inter-Regular";
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 400;

    @media (max-width: 768px) {
      max-width: 468px;
    }

    &:last-child {
      max-width: 100%;

      @media (max-width: 991px) {
        max-width: 208px;
        text-align: right;
      }

      @media (max-width: 768px) {
        text-align: left;
      }
    }
  }
}

.footer__info__creator {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    justify-content: flex-start;
    margin-top: 48px;
  }

  @media (max-width: 576px) {
    margin-top: 40px;
  }
}

.footer__info__creator__text {
  color: #90959d;
  font-family: "Inter-Regular";
  font-size: 0.875rem;
  font-weight: 500;

  @media (max-width: 576px) {
    width: 100%;
  }
}

.footer__info__creator__logo {
  width: 107px;
  height: 24px;
}

.footer__info__creator__text__commads {
  color: $color-text;
  font-family: "Inter-Regular";
  font-size: 1rem;
  font-weight: 600;
}
</style>