<template>
    <div class="center__choose-course">
      <div class="center__choose-course__wrapper">
        <div class="center__choose-course__content">
          <p class="center__choose-course__text">
            Не упустите возможность обучиться в уникальной виртуальной среде,
            моделирующей реалистичные условия работы и ремонта оборудования.
          </p>
          <BtnCopmonentBgGradient
            class="center__choose-course__btn"
            title="Выбрать курс"
            methods="linkToCourse"
            @linkToCourse="linkToCourse()"
          />
        </div>
        <div class="center__choose-course__wrapper-img">
          <img
            class="center__choose-course__img"
            src="@/assets/images/blog/img.png"
            alt="img"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import BtnCopmonentBgGradient from "@/components/btnCopmonent/BtnCopmonentBgGradient.vue";
  
  export default defineComponent({
    name: "CenterChooseCourseComponent",
    components: {
      BtnCopmonentBgGradient,
    },
    props: {
      btnFontSize: Number,
      btnLineHeight: Number,
    },
    methods: {
      linkToCourse(): void {
        this.$router.push({ name: "courses" });
      },
    },
  });
  </script>
  
  <style lang="scss" scoped>
  .center__choose-course {
    margin: 196px auto 0 auto;
    box-sizing: border-box;
    max-width: 1440px;
    position: relative;
  
    @media (max-width: 1280px) {
      margin-top: 108px;
    }

    @media (max-width: 991px){
      margin-top: 170px;
    }

    @media (max-width: 576px){
      margin-top: 56px;
    }
  
    &__wrapper {
      padding-left: 80px;
      padding-right: 80px;
    
      @media (max-width: 991px) {
        padding-left: 60px;
        padding-right: 60px;
      }
    
      @media (max-width: 576px) {
        padding-left: 16px;
        padding-right: 16px;
      }
  
      background-color: $color-questions;
      display: flex;
      padding: 40px 80px;
      box-sizing: border-box;
      justify-content: space-between;
      gap: 5px;
  
      @media (max-width: 991px) {
        padding: 20px 60px;
      }
  
      @media (max-width: 576px) {
        padding: 20px 16px;
        flex-direction: column;
      }
    }
  
    &__content {
      display: flex;
      align-items: center;
      gap: 16px;
      max-width: 671px;
  
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  
    &__text {
      font-family: Gramatika-Medium;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
      max-width: 524px;
      flex-shrink: 1;
  
      @media (max-width: 1036px){
        max-width: 472px;
      }

      @media (max-width: 991px) {
        font-size: 1rem;
        line-height: 1.5rem;
        max-width: 306px;
      }
  
      @media (max-width: 576px) {
        max-width: 100%;
        width: 100%;
      }
    }
  
    &__btn {
      height: 40px;
      padding: 8px 8px;
      width: 131px;
      flex-shrink: 0;

      @media (max-width: 576px) {
        width: 157px;
      }
    }
  
    &__wrapper-img {
      width: 476px;
      height: 90px;
  
      @media (max-width: 1280px) {
        width: 316px;
      }
  
      @media (max-width: 776px) {
        width: 236px;
        flex-shrink: 0;
      }
  
      @media (max-width: 576px) {
        width: 123px;
        height: 80px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  
    &__img {
      position: absolute;
      width: 476px;
      height: 311px;
      bottom: 0;
  
      @media (max-width: 1280px) {
        width: 354px;
        height: 231px;
      }
  
      @media (max-width: 776px) {
        width: 280px;
        height: 186px;
      }
  
      @media (max-width: 576px) {
        width: 123px;
        height: 80px;
      }
    }
  }
  </style>