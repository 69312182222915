<template>
  <div class="popular-articles">
    <h2 class="popular-articles__title">{{ title }}</h2>
    <div v-if="isLoadingPopularArticle">
      <LoaderComponent />
    </div>
    <div v-else-if="isErrorPopularArticle">
      <ErrorComponent :error="isErrorPopularArticle" />
    </div>
    <div v-else-if="dataPopularArticle.length > 0">
      <div class="popular-articles__mask popular-articles__mask__left"></div>
      <div class="popular-articles__list">
        <span class="popular-articles__item-stub"></span>
        <div
          class="popular-articles__item"
          v-for="item in dataPopularArticle"
          :key="item.id"
          @click="linkToCourse(item.id)"
        >
          <div class="popular-articles__item__tags">
            <p class="popular-articles__item__tag" v-if="item.tags[0] !== null">
              {{ item.tags[0].name }}
            </p>
            <p class="popular-articles__item__tag" v-if="item.tags[1] !== null">
              {{ item.tags[1].name }}
            </p>
          </div>
          <h3 class="popular-articles__item__title">
            {{ item.name }}
          </h3>
          <p class="popular-articles__item__date">{{ item.date_begin }}</p>
        </div>
        <span class="popular-articles__item-stub"></span>
      </div>
      <div class="popular-articles__mask popular-articles__mask__right"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoaderComponent from "@/components/loader/Loader.vue";
import ErrorComponent from "@/components/error/Error.vue";
import http from "@/api/http";
import IBlog from "@/interface/blog/IBlog";

interface IData {
  isLoadingPopularArticle: boolean;
  isErrorPopularArticle: string;
  dataPopularArticle: IBlog[];
}

export default defineComponent({
  name: "PopularArticles",
  components: {
    LoaderComponent,
    ErrorComponent,
  },
  props: {
    title: String,
  },
  data(): IData {
    return {
      isLoadingPopularArticle: false,
      isErrorPopularArticle: "",
      dataPopularArticle: [],
    };
  },

  mounted() {
    this.getPopularArticle();
  },
  methods: {
    async getPopularArticle() {
      let url = "blog/all";

      try {
        this.isLoadingPopularArticle = true;
        this.isErrorPopularArticle = "";
        const data = await http.get(url).then((response) => {
          return response.data.data;
        });
        this.dataSort(data);
      } catch (err: any) {
        this.isErrorPopularArticle = err.message;
      } finally {
        this.isLoadingPopularArticle = false;
      }
    },

    dataSort(data: IBlog[]) {
      const { id } = this.$route.params;

      this.dataPopularArticle = data
        .filter((item) => item.id != +id)
        .sort(
          (a: IBlog, b: IBlog) =>
            (b.likes !== null ? b.likes : 0) +
            (b.views !== null ? b.views : 0) -
            ((a.likes !== null ? a.likes : 0) +
              (a.views !== null ? a.views : 0))
        )
        .slice(0, 4);
    },

    linkToCourse(id: number) {
      this.$router.push({ path: `/blog/article/${id}` });
    },
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler() {
        this.getPopularArticle();
      },
    },
  },
});
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.popular-articles {
  margin-top: 148px;
  position: relative;

  @media (max-width: 991px) {
    margin-top: 108px;
  }

  @media (max-width: 576px) {
    margin-top: 68px;
  }

  &__title {
    color: $color-dark-E343D;
    font-family: Gramatika-Medium;
    font-size: 48px;
    font-weight: 400;
    line-height: 72px;

    @media (max-width: 991px) {
      font-size: 1.75rem;
      line-height: 2.625rem;
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(4, 228px);
    }
  }

  &__list {
    position: relative;
    overflow-x: scroll !important;
    left: -80px;
    margin-top: 48px;
    display: grid;
    grid-template-columns: (64px repeat(4, 308px) 64px);
    grid-column-gap: 16px;
    width: calc(100% + 160px);

    @media (max-width: 991px) {
      width: calc(100% + 120px);
      left: -60px;
      margin-top: 32px;
      grid-template-columns: 44px repeat(4, 233px) 44px;
    }

    @media (max-width: 576px) {
      width: calc(100% + 32px);
      left: -16px;
      grid-template-columns: 0 repeat(4, 228px) 0;
    }
  }

  &__item {
    padding: 32px;
    background-color: $color-card-foto;
    border-radius: 24px;
    display: grid;
    grid-template-rows: minmax(24px, 48px) minmax(90px, auto) 24px;
    gap: 16px;
    cursor: pointer;

    @media (max-width: 991px) {
      padding: 20px;
    }

    &:hover {
      background-color: rgb(245, 250, 255);
    }

    &:hover &__title {
      background: $color-gradient;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &__tags {
      display: flex;
      gap: 40px;
    }

    &__tag {
      color: $color-dark-E343D;
      font-family: Inter-Regular;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &__title {
      color: $color-dark-E343D;
      font-family: Gramatika-Medium;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;

      @media (max-width: 991px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    &__date {
      color: $color-dark-E343D;
      font-family: Inter-Regular;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}

.popular-articles__mask {
  position: absolute;
  top: 0;
  width: 80px;
  height: 100%;
  z-index: 10;

  @media (max-width: 991px) {
    width: 60px;
  }

  @media (max-width: 576px) {
    width: 20px;
  }

  &__left {
    left: 0 - 80px;
    background: linear-gradient(
      to right,
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    @media (max-width: 991px) {
      left: 0 - 60px;
    }

    @media (max-width: 576px) {
      left: 0 - 16px;
    }
  }

  &__right {
    background: linear-gradient(
      to left,
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    right: 0 - 80px;

    @media (max-width: 991px) {
      right: 0 - 60px;
    }

    @media (max-width: 576px) {
      right: 0 - 16px;
    }
  }
}
</style>