<template>
    <h1 class="contract">В ближайшее время договор будет добавлен</h1>
    <!-- <div class="pdf">
        <embed src="diploms.pdf" type="application/pdf" width="100%" height="100%">
    </div> -->
</template>

<script>
export default {    
    name: 'СontractPage',
};
</script>

<style lang="scss" scoped>
    .pdf{
        width: 100%;
        height: 100vh;
    }

    .contract{
        margin: 80px auto;
        text-align: center;
    }
</style>