<template>
    <div class="center">
      <HeadersComponent/>
      <CenterAboutComponent/>
      <CenterHistoryComponent/>
      <CenterAdvantagesComponent/>
      <CenterPartnersComponent/>
      <CenterMissionComponent/>
      <CenterPlatformComponent/>
      <CenterFormComponent/>
      <CenterChooseCourseComponent/>
      <FooterComponent hiddenBtn="true" class="center__footer"/>
    </div>
  </template>
  
  <script lang="ts">
  import HeadersComponent from "@/components/headers/Headers.vue";
  import CenterAboutComponent from "@/components/center/centerAboutComponent/CenterAboutComponent.vue";
  import CenterHistoryComponent from "@/components/center/centerHistoryComponent/CenterHistoryComponent.vue";
  import CenterAdvantagesComponent from "@/components/center/centerAdvantagesComponent/CenterAdvantagesComponent.vue";
  import CenterPartnersComponent from "@/components/center/centerPartnersComponent/centerPartnersComponent.vue";
  import CenterMissionComponent from "@/components/center/centerMissionComponent/centerMissionComponent.vue";
  import CenterPlatformComponent from "@/components/center/centerPlatformComponent/CenterPlatformComponent.vue";
  import CenterFormComponent from '@/components/center/centerFormComponent/CenterFormComponent.vue';
  import CenterChooseCourseComponent from "@/components/center/centerChooseCourseComponent/CenterChooseCourseComponent.vue";
  import FooterComponent from "@/components/footer/Footer.vue";

  export default {
    name: "CenterPage",
    components: {
      HeadersComponent,
      CenterAboutComponent,
      CenterHistoryComponent,
      CenterAdvantagesComponent,
      CenterPartnersComponent,
      CenterMissionComponent,
      CenterPlatformComponent,
      CenterFormComponent,
      CenterChooseCourseComponent,
      FooterComponent,
    },
  };
  </script>
  
  <style lang="scss" scoped>

  .center__footer{
    margin-top: 0px;
  }
  </style>