<template>
  <div class="nav">
    <div class="nav__back">
      <svg
        class="nav__back__icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.0004 19C15.2562 18.9999 15.5119 18.9023 15.7071 18.7071C16.0976 18.3166 16.0976 17.6834 15.7071 17.2929L10.4142 12L15.7071 6.70711C16.0976 6.31658 16.0976 5.68342 15.7071 5.29289C15.512 5.09781 15.2564 5.00018 15.0007 5C15.0002 5 14.9998 5 14.9993 5C14.7436 5.00018 14.488 5.09781 14.2929 5.29289L8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12C8 12.2652 8.10536 12.5196 8.29289 12.7071L14.2929 18.7071C14.4881 18.9023 14.7438 18.9999 14.9996 19C14.9999 19 15.0001 19 15.0004 19Z"
          fill="#A8BFDC"
        />
      </svg>
      <router-link class="nav__back__text" to="/courses">Все курсы</router-link>
    </div>
    <div class="nav__list">
      <div class="nav__back nav__list__back">
        <svg
          class="nav__back__icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.0004 19C15.2562 18.9999 15.5119 18.9023 15.7071 18.7071C16.0976 18.3166 16.0976 17.6834 15.7071 17.2929L10.4142 12L15.7071 6.70711C16.0976 6.31658 16.0976 5.68342 15.7071 5.29289C15.512 5.09781 15.2564 5.00018 15.0007 5C15.0002 5 14.9998 5 14.9993 5C14.7436 5.00018 14.488 5.09781 14.2929 5.29289L8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12C8 12.2652 8.10536 12.5196 8.29289 12.7071L14.2929 18.7071C14.4881 18.9023 14.7438 18.9999 14.9996 19C14.9999 19 15.0001 19 15.0004 19Z"
            fill="#A8BFDC"
          />
        </svg>
        <router-link class="nav__back__text" to="/courses"
          >Все курсы</router-link
        >
      </div>
      <li class="nav__item">
        <router-link class="nav__link" :to="courseDescription"
          >О курсе</router-link
        >
      </li>
      <li class="nav__item">
        <router-link class="nav__link" :to="courseSkills"
          >Навыки в VR</router-link
        >
      </li>
      <li class="nav__item">
        <router-link class="nav__link" :to="courseLearnProcess"
          >Как проходит обучение</router-link
        >
      </li>
      <li class="nav__item">
        <router-link class="nav__link" :to="courseProgram"
          >Программа курса</router-link
        >
      </li>
      <li class="nav__item">
        <router-link class="nav__link" :to="coursePrice">Стоимость</router-link>
      </li>
       <li class="nav__item">
        <router-link class="nav__link" :to="courseFormConsultation"
          >Консультация</router-link
        >
      </li>
      <li class="nav__item">
        <router-link class="nav__link" :to="courseReviews">Отзывы</router-link>
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseNavigation",
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  computed: {
    courseDescription: function () {
      return `/course/${this.id}#description`;
    },
    courseSkills: function () {
      return `/course/${this.id}#skills`;
    },
    courseLearnProcess: function () {
      return `/course/${this.id}#learn-process`;
    },
    courseProgram: function () {
      return `/course/${this.id}#program`;
    },
    coursePrice: function () {
      return `/course/${this.id}#price`;
    },
    courseFormConsultation: function () {
      return `/course/${this.id}#form-consultation`;
    },
    courseReviews: function () {
      return `/course/${this.id}#reviews`;
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.nav {
  position: fixed;
  top: 73px;
  z-index: 10;
  width: 100%;

  @media (max-width: 991px) {
    top: 52px;
  }

  @media (max-width: 576px) {
    top: 44px;
  }

  &__back {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    left: -86px;
    width: 100%;
    background-color: $color-bg;
    padding-left: 80px;
    padding-top: 8px;

    &__icon {
      width: 24px;
      height: 24px;
    }

    &__text {
      color: $color-blue;
      font-family: Inter-Medium;
      font-size: 1rem;
      line-height: 1.5rem;
      text-decoration: none;
    }

    @media (max-width: 576px) {
      display: none;
      padding: 0;
    }
  }

  &__list {
    display: flex;
    position: relative;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    left: -80px;
    max-width: 1440px;
    padding: 0 80px 8px 80px;
    background: #f3f3f3;
    list-style-type: none;
    box-sizing: border-box;
    overflow-x: scroll !important;
    user-select: none;

    @media (max-width: 991px) {
      left: -60px;
      padding: 0 60px 8px 60px;
    }

    @media (max-width: 576px) {
      padding: 0 16px 16px 16px;
      align-items: center;
      left: -16px;
    }

    &__back {
      display: none;

      @media (max-width: 576px) {
        display: flex;
        flex-shrink: 0;
        left: 0;
        width: 115px;
        background: transparent;
        border-bottom: 2px solid transparent;
      }
    }
  }

  &__item {
    flex-shrink: 0;
  }

  &__link {
    display: block;
    color: $color-form-text;
    font-family: Inter-Medium;
    font-size: 1rem;
    line-height: 1.5rem;
    text-decoration: none;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;

    &:hover {
      color: $color-hover;
      border-bottom: 2px solid $color-hover;
    }
  }
}
</style>