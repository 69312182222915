<template>
  <div class="recommend">
    <h2 class="recommend__title">Рекомендуемые курсы</h2>
    <div v-if="isLoading">
      <LoaderComponent />
    </div>
    <div v-else-if="isError">
      <ErrorComponent :error="isError" />
    </div>
    <div class="recommend__list" v-else-if="courseArr.length > 0">
      <div class="recommend__item" v-for="item in courseArr" :key="item.id">
        <h3 class="recommend__item__title">
          {{ item.name }}
        </h3>
        <div class="recommend__item__description">
          <p class="recommend__item__description__program-house">
            {{ item.duration }} ч.
          </p>
          <p class="recommend__item__description__program-document">
            {{ item.document_type }}
          </p>
          <p class="recommend__item__description__program-education">
            {{ item.training_program }}
          </p>
        </div>
        <div class="recommend__item__footer">
          <div class="recommend__item__footer__price">
            <div
              class="recommend__item__footer__price__discount__wraper"
              v-if="item.discount_procent"
            >
              <p class="recommend__item__footer__price__discount">
                {{ item.payment }}
              </p>
              <p class="recommend__item__footer__price__discount__percent">
                -{{ item.discount_procent }}%
              </p>
            </div>
            <p class="recommend__item__footer__price__final">
              {{ finalPrice(item.payment, item.discount_procent) | format }}
              руб.
            </p>
          </div>
          <BtnCopmonentBgGradient
            class="description__btn__pay"
            title="Посмотреть подробней"
            fontSize="16"
            lineHeight="24"
            @linkToCourse="linkToCourse(item.id)"
            methods="linkToCourse"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BtnCopmonentBgGradient from "@/components/btnCopmonent/BtnCopmonentBgGradient.vue";
import { defineComponent } from "vue";
import ICourse from "@/interface/courses/ICourse";
import LoaderComponent from "@/components/loader/Loader.vue";
import ErrorComponent from "@/components/error/Error.vue";
import http from "../../../api/http";

interface IData {
  isLoading: boolean;
  isError: string;
  dataArr: ICourse[];
  courseArr: ICourse[];
  idArr: number[];
}

export default defineComponent({
  name: "RecommendCourse",
  data(): IData {
    return {
      dataArr: [],
      courseArr: [],
      isLoading: false,
      isError: "",
      idArr: [],
    };
  },
  components: {
    BtnCopmonentBgGradient,
    LoaderComponent,
    ErrorComponent,
  },
  async mounted() {
    await this.getData();
    this.idArr.push(+this.$route.params.id);
    this.getRandom();
  },
  filters: {
    format: (val: number) =>
      `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 "),
  },
  methods: {
    async getData() {
      let url = "courses/all";
      try {
        this.isLoading = true;
        this.isError = "";
        this.dataArr = await http.get(url).then((response) => {
          return response.data.data;
        });
      } catch (err: any) {
        this.isError = err.message;
      } finally {
        this.isLoading = false;
      }
    },
    finalPrice(payment: number, percent: number) {
      return +(payment * (100 - percent)) / 100;
    },
    getRandom() {
      while (this.courseArr.length < 2) {
        let randomIndex = Math.floor(Math.random() * this.dataArr.length);
        if (!this.idArr.includes(randomIndex)) {
          this.idArr.push(randomIndex);
          this.courseArr.push(this.dataArr[randomIndex]);
        }
      }
    },
    linkToCourse(id: number) {
      this.$router.push({ path: `/course/${id}` });
    },
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      async handler() {
        await this.getData();
        this.idArr = [];
        this.courseArr = [];
        this.idArr.push(+this.$route.params.id);
        this.getRandom();
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.recommend {
  margin-top: 148px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (max-width: 991px) {
    margin-top: 48px;
  }

  @media (max-width: 576px) {
    margin-top: 80px;
  }
}

.recommend__title {
  color: $color-dark-E343D;
  font-family: Gramatika-Medium;
  font-size: 3rem;
  font-weight: 400;
  line-height: 4.5rem;

  @media (max-width: 991px) {
    font-size: 2.25rem;
    line-height: 3.375rem;
  }

  @media (max-width: 576px) {
    font-size: 1.75rem;
    line-height: 2.625rem;
  }
}

.recommend__list {
  display: flex;
  gap: 16px;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  @media (max-width: 576px) {
    padding: 0 16px;
  }
}

.recommend__item {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  border-radius: 16px;
  background: $color-card-foto;
  width: 50%;
  gap: 16px;
  box-sizing: border-box;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.recommend__item__title {
  color: $color-dark-E343D;
  font-family: Gramatika-Medium;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  min-height: 60px;
}

.recommend__item__description {
  display: flex;
  gap: 8px;
  min-height: 56px;

  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
}

.recommend__item__description__program-house,
.recommend__item__description__program-document,
.recommend__item__description__program-education {
  padding: 16px 4px;
  background: $color-bg;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Inter-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $color-dark-E343D;
}

.recommend__item__description__program-document {
  max-width: 223px;
  flex-grow: 1;

  @media (max-width: 991px) {
    max-width: 323px;

    @media (max-width: 640px) {
      max-width: 100%;
    }
  }
}

.recommend__item__description__program-education {
  max-width: 296px;
  flex-grow: 1;

  @media (max-width: 991px) {
    max-width: 496px;
  }
}

.recommend__item__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-grow: 1;

  @media (max-width: 484px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.recommend__item__footer__price {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.recommend__item__footer__price__discount__wraper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.recommend__item__footer__price__discount {
  color: $color-dark-E343D;
  font-family: Inter-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration-line: line-through;
}

.recommend__item__footer__price__discount__percent {
  font-family: Inter-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.recommend__item__footer__price__final {
  font-family: Gramatika-Medium;
  font-size: 1.5rem;
  line-height: 2rem;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description__btn__pay {
  padding: 8px 16px;

  @media (max-width: 484px) {
    width: 100%;
  }
}
</style>