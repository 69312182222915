<template>
  <section class="courses__cards" id="all-courses" ref="container">
    <div class="courses__cards__wrap">
      <h2 class="courses__cards__title">
        Каталог курсов по обслуживанию систем вентиляции и кондиционирования
      </h2>
      <div
        class="courses__cards__animation_container"
        :style="getStyleAnimatedBacground"
      >
        <img
          ref="img"
          :style="getStyleAnimatedRight"
          class="courses__cards__animation_img"
          src="@/assets/images/courses/courses-cards-mockup.png"
          alt="#"
        />
      </div>
    </div>
    <div v-if="isLoading">
      <LoaderComponent />
    </div>
    <div v-else-if="isError">
      <ErrorComponent :error="isError" />
    </div>
    <template v-else-if="dataArr.length > 0">
      <CoursesCardsComponentList
        v-for="(card, i) in dataArr"
        :card="card"
        :index="i"
        :key="i"
      />
    </template>
  </section>
</template>

<script lang=ts>
import http from "../../../api/http";
import CoursesCardsComponentList from "./CoursesCardsComponentList.vue";
import ICourse from "@/interface/courses/ICourse";
import LoaderComponent from "@/components/loader/Loader.vue";
import ErrorComponent from "@/components/error/Error.vue";

import { defineComponent } from "vue";

let refContainer: HTMLDivElement;
let refImg: HTMLImageElement;

interface IData {
  translatePercentScroll: number;
  coefficientWidthImg: number;
  translateXBg: number;
  translateYBg: number;
  isLoading: boolean;
  isError: string;
  dataArr: ICourse[];
}

export default defineComponent({
  name: "CoursesCardsComponent",
  components: {
    CoursesCardsComponentList,
    LoaderComponent,
    ErrorComponent,
  },
  data(): IData {
    return {
      translatePercentScroll: 0,
      coefficientWidthImg: 0,
      translateXBg: 0 || 478,
      translateYBg: 228,
      isLoading: false,
      isError: "",
      dataArr: [],
    };
  },
  async mounted() {
    refContainer = this.$refs.container as HTMLDivElement;
    refImg = this.$refs.img as HTMLImageElement;
    window.addEventListener("scroll", this.checkScrollPosition);
    window.addEventListener("resize", this.getResize);
    await this.getData();
  },
  methods: {
    checkScrollPosition() {
      this.getCoefficientWidthImg();
      this.translatePercentScroll =
        scrollY <= refContainer.offsetTop
          ? Math.round((scrollY / refContainer.offsetTop) * 100)
          : 100;
      this.translateXBg = Math.round(
        478 - (106 * this.translatePercentScroll) / 100
      );
    },

    getResize() {
      this.checkScrollPosition();
    },

    getCoefficientWidthImg() {
      this.coefficientWidthImg =
        (refImg.parentElement?.clientWidth
          ? refImg.parentElement?.offsetWidth - refImg.offsetWidth
          : 0) / refImg.offsetWidth;
    },

    async getData(url = "courses/all") {
      try {
        this.isLoading = true;
        this.isError = "";
        this.dataArr = await http.get(url).then((response) => {
          return response.data.data;
        });
      } catch (err: any) {
        this.isError = err.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    getStyleAnimatedRight: function (): string {
      return `transform: translate(${
        this.translatePercentScroll * this.coefficientWidthImg
      }%, 0)`;
    },
    getStyleAnimatedBacground: function (): string {
      return `backgroundPosition: -${this.translateXBg}px -${this.translateYBg}px ; `;
    },
  },
});
</script>

<style lang="scss" scoped>
.courses__cards {
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 148px;
  padding-left: 80px;
  padding-right: 80px;
  scroll-behavior: smooth;

  @media (max-width: 991px) {
    margin-top: 80px;
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (max-width: 576px) {
    margin-top: 64px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__wrap {
    display: flex;
    margin-bottom: 60px;

    @media (max-width: 1280px) {
      flex-direction: column-reverse;
      margin-bottom: 52px;
    }

    @media (max-width: 576px) {
      margin-bottom: 32px;
    }
  }

  &__title {
    font-family: "Gramatika-Medium";
    font-size: 3rem;
    font-weight: 400;
    line-height: 4.5rem;
    color: #2e343d;
    margin-right: 16px;
    width: 50%;

    @media (max-width: 1280px) {
      width: 100%;
      margin-right: 0px;
    }

    @media (max-width: 991px) {
      font-size: 1.75rem;
      line-height: 2.625rem;
    }
  }

  &__animation_container {
    display: flex;
    align-items: center;
    position: relative;
    width: 50%;
    height: 210px;
    border-radius: 24px;
    background-position: -478px -228px;
    background-image: url("@/assets/images/courses/courses-cards-background.png");
    transition: all 1s ease;

    @media (max-width: 1280px) {
      width: 100%;
      height: 210px;
      margin-bottom: 60px;
    }

    @media (max-width: 991px) {
      height: 145px;
    }

    @media (max-width: 576px) {
      height: 105px;
      margin-bottom: 30px;
    }
  }

  &__animation_img {
    position: absolute;
    bottom: -30px;
    left: 0px;
    width: 308px;
    transform: translate(0, 0);
    transition: all 1s ease;

    @media (max-width: 1280px) {
      bottom: -20px;
    }

    @media (max-width: 991px) {
      width: 240px;
    }

    @media (max-width: 576px) {
      bottom: -12px;
      width: 150px;
    }
  }
}
</style>