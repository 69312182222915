<template>
  <div>
    <HeadersComponent/>
    <section class="section-functional-requirements">
        <div class="grid-system">
          <div class="grid-system-item__title"><h2 class="grid-system-item-title">Функциональные
            требования</h2></div>
          <div class="grid-system-item">Организация обучения пользователей: управление процесса обучения и контроль.</div>
          <div class="grid-system-item">Загрузка моделей систем кондиционирования
            и вентиляции (СКВ), сценариев сборки, эксплуатации.</div>
          <div class="grid-system-item">Работа с нейронными сетями: построение
            на основе накопленных данных реальных систем и обучение с подбором настроек.</div>
          <div class="grid-system-item">Прогнозирование возможных вариантов работы СКВ при получении различных исходных данных.</div>
          <div class="grid-system-item">Визуализация конструкции моделей систем
            в 3D пространстве.</div>
          <div class="grid-system-item">Формирование статистической отчётности
            по различным аспектам использования ПО.</div>
          <div class="grid-system-item">Моделирование прохождения процессов в СКВ с получением результатов, приближенных
            к поведению аналогичных реальных систем.</div>
        </div>
    </section>
    <section class="section-requirements-for-operation">
      <h2 class="grid-system-item-title operation-title ">Требования
        для&nbsp;эксплуатации</h2>
      <div class="grid-system-operations">
        <div class="grid-system-operations-item">
          <h3 class="operations-item__title">Процессор</h3>
          <dl class="operations-item__list">
            <div>
              <dt class="operations-item__sub-title">Рекомендуемые системные требования:</dt>
              <dd class="operations-item__descr">Intel Core i5-4590/AMD FX 8350 аналогичная или более новая модель </dd>
            </div>
            <div>
              <dt class="operations-item__sub-title">Минимальные системные требования:</dt>
              <dd class="operations-item__descr">Intel Core i5-4590/AMD FX 8350 аналогичная или более новая модель</dd>
            </div>



          </dl>
        </div>
        <div class="grid-system-operations-item">
          <h3 class="operations-item__title">Графический процессор</h3>
          <dl class="operations-item__list">

            <div><dt class="operations-item__sub-title">Рекомендуемые системные требования:</dt>
              <dd class="operations-item__descr">NVIDIA GeForce GTX 1060, AMD Radeon RX 480 аналогичная или более новая модель</dd></div>
            <div>
              <dt class="operations-item__sub-title">Минимальные системные требования:</dt>
              <dd class="operations-item__descr">NVIDIA GeForce GTX 970, AMD Radeon R9 290 аналогичная или более новая модель</dd>
            </div>

          </dl>
        </div>
        <div class="grid-system-operations-item">
          <h3 class="operations-item__title">Память</h3>
          <dl class="operations-item__list">
            <div>
              <dt class="operations-item__sub-title">Рекомендуемые системные требования:</dt>
              <dd class="operations-item__descr">8 ГБ ОЗУ или более</dd>
            </div>

            <div>
              <dt class="operations-item__sub-title">Минимальные системные требования:</dt>
              <dd class="operations-item__descr">4 ГБ ОЗУ или более</dd>
            </div>


          </dl>
        </div>
      </div>
      <div class="requirements-link-wrap"><span>Руководство пользователя доступно для скачивания по <a class="requirements-link" target="_blank" href="Руководство_пользователя.pdf">ссылке</a></span></div>
    </section>


    <FooterComponent/>
  </div>
</template>

<script lang="ts">

import {defineComponent} from 'vue'
import HeadersComponent from '../components/headers/Headers.vue';
import FooterComponent from '../components/footer/Footer.vue'

export default defineComponent({
  name: 'RequirementsPage',
  components: {
    HeadersComponent,
    FooterComponent
  },


});
</script>

<style lang="scss" scoped>
.section-functional-requirements, .section-requirements-for-operation {
  max-width: 1280px;
  margin: 88px auto 0 auto;
  position: relative;
  color: #000;
  padding: 0 80px;
  @media(max-width: 767.98px){
    padding: 0 15px;
  }
}
.grid-system{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(416px, 1fr));
  gap: 20px 16px;
  @media (min-width: 768px) and (max-width: 1023.98px){
    //grid-template-columns: repeat(auto-fit, minmax(216px, 288px));
    grid-template-columns: repeat(auto-fit, minmax(216px, 288px));
    justify-content: center;
  }
  @media (max-width: 461.98px){
    grid-template-columns: 1fr;
  }
}
.grid-system-item-title, .operation-title{
  font-family: 'Gramatika-Medium';
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;
  background-image: linear-gradient(135deg, #7277FF 0%, #FF5A5A 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  @media (min-width: 768px) and (max-width: 1023.98px){
    font-size: 34px;
    line-height: 58px;
  }
  @media(max-width: 767.98px){
    font-size: 32px;
    line-height: 56px;
    max-width: 416px;
  }
}
.operation-title{
  max-width: 416px;
  @media (min-width: 768px) and (max-width: 1023.98px) {
    font-size: 32px;
    line-height: 56px;
    display: grid;
    grid-template-columns: repeat(2, minmax(216px, 288px));
    justify-content: center;
    margin: 0;
    max-width: none;

  }
  @media(max-width: 767.98px) {
    font-size: 32px;
    line-height: 56px;
  }
}
.grid-system-item{
  border-radius: 24px;
  background: #EFEFF0;
  padding: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.grid-system-operations{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(416px, 1fr));
  gap: 20px 16px;
  @media(max-width: 1023.98px){
    grid-template-columns: repeat(auto-fit, minmax(216px, 288px));
    justify-content: center;
  }
  @media(max-width: 767.98px){
    grid-template-columns: 1fr;
    //grid-template-columns: repeat(auto-fit, minmax(216px, 288px));
    //justify-content: center;
  }
}
.grid-system-operations-item{
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 24px;
  background: #EFEFF0;
}
.operations-item{
  &__title{
    color: #2E343D;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    @media (min-width: 768px) and (max-width: 1023.98px){
      font-size: 20px;
      line-height: 26px;

    }
  }
  &__list{
    margin: 0;
    div:not(:last-child){
      margin-bottom: 16px;
    }
  }
  &__sub-title{
    margin-bottom: 8px;
    color: #2E343D;
    //font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    @media (min-width: 768px) and (max-width: 1023.98px){

      line-height: 26px;
    }
  }
  &__descr{
    margin: 0;
    color:#2E343D;
    //font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    @media (min-width: 768px) and (max-width: 1023.98px){

      line-height: 26px;
    }
  }
}

.requirements-link-wrap{
  margin-top: 24px;
  color: #2E343D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  @media (min-width: 768px) and (max-width: 1023.98px){
    display: grid;
    grid-template-columns: repeat(1, 592px);
    justify-content: center;
    margin: 0;
    max-width: none;
  }

}
.requirements-link{

  background-image: linear-gradient(135deg, #7277FF 0%, #FF5A5A 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}


</style>