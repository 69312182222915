<template>
  <div class="courses">
    <HeadersComponent />
    <CoursesTagsComponent />
    <CoursesTryFreeComponent />
    <CoursesCardsComponent />
    <MainFormConsultation title="Не можете определиться с выбором?" />
    <CoursesCorporateComponent />
    <FooterComponent />
  </div>
</template>

<script lang="ts">
import HeadersComponent from "../components/headers/Headers.vue";
import CoursesTagsComponent from "../components/courses/coursesTagsComponent/CoursesTagsComponent.vue";
import CoursesTryFreeComponent from "../components/courses/coursesTryFreeComponent/CoursesTryFreeComponent.vue";
import CoursesCardsComponent from "../components/courses/coursesCardsComponent/CoursesCardsComponent.vue";
import MainFormConsultation from "../components/main/mainFormConsultationComponent/MainFormConsultation.vue";
import CoursesCorporateComponent from "../components/courses/coursesCorporateComponent/CoursesCorporateComponent.vue";
import FooterComponent from "../components/footer/Footer.vue";

export default {
  name: "CoursesPage",
  components: {
    HeadersComponent,
    CoursesTagsComponent,
    CoursesTryFreeComponent,
    CoursesCardsComponent,
    MainFormConsultation,
    CoursesCorporateComponent,
    FooterComponent,
  },
  metaInfo: {
    title: "Обучение специалистов климатического бизнеса дистанционно в VR",
    meta: [
      {
        name: "Курсы обслуживания климатического оборудования с практикой в VR",
        content:
          "каталог курсов, обучение рабочим специальностям, строительные курсы, обучение и профессиональная подготовка. курсы холодильного оборудования, обслуживание кондиционеров, курсы электромонтажника, слесарь ремонтник обучение",
      },
      {
        name: "description",
        content:
          "Дистанционное обучение специалистов климатического, холодильного и строительного бизнеса с выдачей документов государственного и установленного образца. Бесплатные уроки.",
      },
    ],
  },
};
</script>

