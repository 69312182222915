<template>
  <div class="panel__icons">
    <div class="panel__icons__likes">
      <button class="panel__icons__likes__thumb-up" @click="toLike()"></button>
      <p class="panel__icons__likes-count">{{ like || 0 }}</p>
      <button
        class="panel__icons__likes__thumb-down"
        @click="toDislike()"
      ></button>
    </div>
    <div class="panel__icons__share" @click="shareMenu()">
      <p class="panel__icons__share-text">Поделиться</p>
      <button class="panel__icons__share-btn"></button>
      <div
        class="panel__icons__list__wraper"
        :style="showMenu ? 'display:block' : 'display:none'"
      >
        <ul class="panel__icons__list">
          <li class="panel__icons__item">
            <a
              :href="getTelgramLink"
              target="_blank"
              class="panel__icons__link"
            >
              <img
                src="@/assets/icons/blog/telegram.svg"
                alt="telegram"
                class="panel__icons__linl-img"
              />
              <p class="panel__icons__link-text">Telegram</p>
            </a>
          </li>
          <li class="panel__icons__item">
            <a :href="getVkLink" target="_blank" class="panel__icons__link">
              <img
                src="@/assets/icons/blog/vcontacte.svg"
                alt="vcontakte"
                class="panel__icons__linl-img"
              />
              <p class="panel__icons__link-text">вконтакте</p>
            </a>
          </li>
          <li class="panel__icons__item">
            <a href="" class="panel__icons__link" @click="copyText($event)">
              <img
                src="@/assets/icons/blog/copy-link.svg"
                alt="copy-link"
                class="panel__icons__linl-img"
              />
              <p class="panel__icons__link-text">Копировать ссылку</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import IBlog from "@/interface/blog/IBlog";
import http from "@/api/http";

export default defineComponent({
  name: "ArticleShareMenu",
  props: {
    data: {
      type: Object as PropType<IBlog>,
    },
  },
  data() {
    return {
      showMenu: false,
      like: this.data?.likes,
      stopLike: false,
      stopDislike: false,
    };
  },

  methods: {
    copyText(event: Event): void {
      event.preventDefault();
      navigator.clipboard.writeText(window.location.href);
    },
    shareMenu() {
      this.showMenu = !this.showMenu;
    },
    async toLike() {
      const { id } = this.$route.params;
      let url = `blog/${id}/publication/like`;

      try {
        if (!this.stopLike && !this.stopDislike) {
          await http.post(url);
          if (this.like) this.like++;
          this.stopLike = true;
          this.stopDislike = false;
        } else if (this.stopDislike && !this.stopLike) {
          await http.post(url);
          await http.post(url);
          if (this.like) this.like = this.like + 2;
          this.stopLike = true;
          this.stopDislike = false;
        }
      } catch (err: any) {
        console.log(err);
      }
    },
    async toDislike() {
      const { id } = this.$route.params;
      let url = `blog/${id}/publication/dislike`;

      try {
        if (!this.stopDislike && !this.stopLike) {
          await http.post(url);
          if (this.like && this.like > 0) this.like--;
          this.stopDislike = true;
          this.stopLike = false;
        } else if (this.stopLike && !this.stopDislike) {
          await http.post(url);
          await http.post(url);
          if (this.like && this.like > 1) this.like = this.like - 2;
          this.stopDislike = true;
          this.stopLike = false;
        }
      } catch (err: any) {
        console.log(err);
      }
    },
  },
  computed: {
    getTelgramLink: function (): string {
      return `https://t.me/share/url?url=${window.location.href}&text=${this.data?.name}`;
    },
    getVkLink: function (): string {
      return `http://vk.com/share.php?url=${window.location.href}&title=${this.data?.name}&image=${this.data?.avatar}`;
    },
  },
});
</script>

<style lang="scss" scoped>
.panel__icons {
  margin-top: 24px;
  display: flex;
  gap: 40px;
  padding: 0 150px;

  @media (max-width: 991px) {
    padding: 0;
  }

  @media (max-width: 576px) {
    gap: 20px;
    padding: 0;
  }

  &__likes {
    display: flex;
    align-items: center;
    gap: 14px;

    &__thumb-up {
      width: 24px;
      height: 24px;
      background-color: white;
      background-image: url("@/assets/icons/blog/thumb-up.svg");
      border: none;
      cursor: pointer;
    }

    &__thumb-down {
      width: 24px;
      height: 24px;
      background-image: url("@/assets/icons/blog/thumb-down.svg");
      border: none;
      background-color: white;
      cursor: pointer;
    }

    &-count {
      margin-top: -4px;
      font-family: "Gramatika-Medium";
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }

  &__share {
    display: flex;
    align-items: center;
    gap: 16px;
    position: relative;

    &:hover &-text {
      background: $color-gradient;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &-text {
      margin-top: -4px;
      font-family: "Gramatika-Medium";
      font-size: 1.25rem;
      line-height: 1.875rem;
      cursor: pointer;
    }

    &-btn {
      width: 24px;
      height: 24px;
      background-image: url("@/assets/icons/blog/share.svg");
      border: none;
      background-color: white;
      cursor: pointer;
    }
  }

  .panel__icons__list__wraper {
    display: none;
  }

  .panel__icons__list {
    list-style-type: none;
    position: absolute;
    width: 468px;
    top: 0;
    display: flex;
    gap: 30px;
    background-color: $color-card-foto;
    padding: 16px;
    border-radius: 16px;
    z-index: 10;
    box-sizing: border-box;

    @media (max-width: 1280px) {
      top: 46px;
      left: 0;
      right: initial;
      right: none;
    }

    @media (max-width: 776px) {
      width: 152px;
      top: 38px;
      padding: 16px;
      gap: 24px;
    }
  }

  .panel__icons__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    text-decoration: none;

    @media (max-width: 776px) {
      gap: 0;
    }
  }

  .panel__icons__linl-img {
    width: 24px;
    height: 24px;
  }

  .panel__icons__link-text {
    color: $color-dark-E343D;
    font-family: Gramatika-Medium;
    font-size: 1.25rem;
    line-height: 1.875rem;

    @media (max-width: 776px) {
      display: none;
    }
  }
}
</style>