export const questions = [
  {
    num: "/01",
    question: "Как проходит обучение?",
    answer:
      "Мы предоставляем возможность дистанционного обучения, что обеспечит вам гибкое освоение учебного материала в удобное время и из любой точки мира, имея компьютер и подключение к сети Интернет. Онлайн-платформа, обеспечивает доступ к образовательным ресурсам, видеоматериалам, заданиям и тестам. Контент будет предоставлен поэтапно, позволяя вам систематически углубляться в темы и возвращаться к ним по необходимости.",
    open: true,
  },
  {
    num: "/02",
    question: "Какие материалы или ресурсы мне понадобятся для обучения?",
    answer:
      "Для обучения потребуется компьютер с доступом в интернет. Это основное требование для успешного освоения программы.",
    open: false,
  },
  {
    num: "/03",
    question:
      "Что нужно для начала обучения?",
    answer:
      "Для начала обучения оплатите курс. Дождитесь на электронную почту данные логина и пароля. Далее необходимо авторизоваться на сайте freon.study в личном кабинете",
    open: false,
  },
  {
    num: "/04",
    question: "Если у меня нет опыта и знаний в данной области, подойдут ли мне ваши курсы?",
    answer:
      "Да, наши курсы подходят для начинающих, не имеющих опыта в выбранной профессии. Мы предлагаем разнообразные обучающие программы, которые обеспечивают полное освоение основных навыков и знаний, требуемых для успешного старта в данной области. Наш подход к обучению разработан таким образом, чтобы обеспечить логичное и последовательное введение в тему, даже для абсолютных новичков. Мы предоставляем все необходимые учебные материалы, чтобы помочь вам достичь профессиональной подготовки и стать квалифицированным специалистом в ремонте и обслуживании климатического оборудования. А задания, выполняемые в виртуальной реальности, поспособствуют развитию практической компетенции.",
    open: false,
  },
  {
    num: "/05",
    question: "Сколько времени нужно уделять обучению?",
    answer:
      "Наша гибкая система обучения позволит изучать материалы в удобное время, адаптированное под ваш график. Вы сможете освоить концепции и приобрести навыки в своем собственном темпе, в соответствии вашим потребностям.",
    open: false,
  },
  {
    num: "/06",
    question: "Для кого рассчитан курс?",
    answer:
      "Курс рассчитан на заинтересованных в получении профессии в области монтажа, ремонта и обслуживания климатического оборудования, на тех, кто стремится повысить свою квалификацию в этой сфере",
    open: false,
  },
  {
    num: "/07",
    question: "Где проходит обучение?",
    answer:
      "Обучение проходит на специально разработанной обучающей платформе, созданной на сайте freon.study. Доступ к курсам открывается личным логином и паролем, которые отправляются на электронную почту слушателя после оплаты.",
    open: false,
  },
  {
    num: "/08",
    question:
      "Предусмотрена ли возможность взаимодействия с преподавателями на курсе?",
    answer:
      "Да, на курсе предусмотрена возможность взаимодействия с преподавателями через чаты в личном кабинете или электронную почту.",
    open: false,
  },
  {
    num: "/09",
    question: "Будут ли доступны материалы курса после обучения?",
    answer:
      "После окончания обучения доступ к курсу предоставляется на протяжении года начиная с даты оплаты.",
    open: false,
  },
  {
    num: "/10",
    question: "Какой документ выдается по окончании обучения?",
    answer:
      "Тип документа зависит от выбранного курса. Это может быть Удостоверение о повышении квалификации установленного образца или Свидетельство о профессии рабочего с занесением в Федеральный реестр сведений о документах об образовании и/или о квалификации, документов об обучении (ФРДО)",
    open: false,
  },
  {
    num: "/11",
    question: "Я могу оплатить курс частями?",
    answer:
      "Да, продумана возможность оплатить курс частями. В нашей платежной системе предусмотрена функция разделения оплаты на несколько платежей.",
    open: false,
  },
];
