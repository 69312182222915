<template>
    <div class="main__review__cards">
        <div class="main__review__cards__left">
            <MainReviewsItem 
            v-for="(review, index) in leftReviews" 
            :review="review" 
            :key="index" />

        </div>
        <div class="main__review__cards__right">
            <MainReviewsItem v-for="(review, index) in rightReviews" :review="review" :key="index" />

        </div>
    </div>
</template>

<script>
import { leftReviews } from '../MainReviewsData/MainReviewsData';
import { rightReviews } from '../MainReviewsData/MainReviewsData';
import MainReviewsItem from '../MainReviewsItem/MainReviewsItem.vue'
export default {
    name: 'MainReviewsList',
    data() {
        return {
            leftReviews: '',
            rightReviews: ''
        };
    },
    components: {
        MainReviewsItem
    },
    created() {
        this.leftReviews = leftReviews;
        this.rightReviews = rightReviews;
    },
};
</script>

<style lang="scss" scoped>
.main__review__cards {
    max-height: 858px;
    overflow-y: scroll;
    display: flex;

    @media (max-width: 768px) {
        max-height: 450px;
        flex-direction: column;
    }

    @media (max-width: 576px) {
        max-height: 572px;
    }
}

.main__review__cards::-webkit-scrollbar {
    width: 0;
}

.main__review__cards__left {
    margin-top: 162px;
    margin-right: 16px;

    @media (max-width: 768px) {
        margin-top: 0;
        margin-right: 0;
    }

}</style>