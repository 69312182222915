<template>
  <div>
    <HeadersComponent />
    <BlogComponent />
    <FooterComponent class="footer__blog" />
  </div>
</template>

<script lang="ts">
import HeadersComponent from "../components/headers/Headers.vue";
import BlogComponent from "../components/blog/BlogComponent.vue";
import FooterComponent from "../components/footer/Footer.vue";

export default {
  name: "BlogPage",
  components: {
    HeadersComponent,
    BlogComponent,
    FooterComponent,
  },
  metaInfo: {
    title: "Курсы обслуживания климатического оборудования с практикой в VR",

    meta: [
      {
        name: "keywords",
        content:
          "информационный блог, новости, статьи, публикации, мероприятия, события, акции, скидки",
      },
      {
        name: "description",
        content:
          "Дистанцинноое обучение специалистов климатического, холодильного и строительного бизнеса с выдачей документов государственного и установленного образца. Бесплатные уроки.",
      },
    ],
  },
};
</script>

<style lang="scss" scoped>
.footer__blog {
  margin-top: 64px;
}
</style>