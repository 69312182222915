import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    checkScrollBg: false,
    closeClickPageMenuHeader: true,
    modalCourseActive: false
  },
  getters: {
    getCheckScrollBg(state) {
      return state.checkScrollBg;
    },
    getCloseClickPageMenuHeader(state) {
      return state.closeClickPageMenuHeader;
    },
  },
  mutations: {
    setCheckScrollBg(state) {
      state.checkScrollBg = true;
    },
    setCloseClickPageMenuHeader(state, payload) {
      state.closeClickPageMenuHeader = payload;
    },  
  },
  actions: {
  },
  modules: {
  },

})
