<template>
    <!-- Блок Отзывы -->
    <section class="main__review">
        <div class="main__review__wrap">
            <h2 class="main__review__title">Отзывы</h2>
            <h3 class="main__review__text">Более 100 участников успешно завершили наши курсы и поделились своими
                впечатлениями</h3>
            <!-- <BtnCopmonentBgGradient class="main__review__btn" title="Больше отзывов" width_arrow="40"
                :margin_right="getMarginRight" /> -->
        </div>
        <MainReviewsList />
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainReviewsList from './MainReviewsList/MainReviewsList.vue'
// import BtnCopmonentBgGradient from '../../btnCopmonent/BtnCopmonentArrow.vue'


export default defineComponent({
    name: 'MainReviewsComponent',
    components: {
        MainReviewsList,
        // BtnCopmonentBgGradient
    },    
    // computed: {
    //     getMarginRight: function () {
    //         return innerWidth >= 991 && innerWidth < 1280 ? '20' : '24';
    //     }
    // }
});
</script>

<style lang="scss" scoped>
.main__review {
    box-sizing: border-box;
    margin: 136px auto 0 auto;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0 80px;

    @media (max-width: 991px) {
        flex-direction: column;
        margin-top: 80px;
        padding: 0 60px;
    }

    @media (max-width: 576px) {
        padding: 0 16px;
    }
}

.main__review__wrap {
    margin-right: 5px;

    @media (max-width: 991px) {
        margin-right: 0;
    }
}

.main__review__title {
    font-family: 'Gramatika-Medium';
    font-size: 3rem;
    font-weight: 400;
    line-height: 4.5rem;
    color: $color-dark-E343D;
    margin-bottom: 56px;

    @media (max-width: 768px) {
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 52px;
    }

    @media (max-width: 576px) {
        margin-bottom: 26px;
    }
}

.main__review__text {
    max-width: 308px;
    color: $color-dark-E343D;
    font-family: 'Inter-Regular';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 30px;

    @media (max-width: 991px) {
        max-width: 100%;
        margin-bottom: 24px;
    }

    @media (max-width: 576px) {
        margin-bottom: 24px;
    }
}

.main__review__btn {
    font-size: 1.25rem;
    line-height: 2.15rem;
    background: $color-bg;

    @media (max-width: 992px) {
        margin-bottom: 48px;
    }

    @media (max-width: 576px) {
        margin-bottom: 24px;
        justify-content: center;
    }
}

</style>