<template>
  <div class="price" id="price">
    <h2 class="price__title">Стоимость курса</h2>
    <div class="price__description">
      <div class="price__description__list">
        <div v-if="price_persent > 0" class="price__description__item">
          <h3 class="price__description__item__subtitle">
            Оплатите курс полностью и получите скидку
          </h3>
          <div class="price__description__item__amount">
            <div class="price__description__item__amount__discount">
              <p class="price__description__item__amount__discount__text">
                {{ price_discount | format }}
              </p>
              <p class="price__description__item__amount__discount__precent">
                -{{ price_persent }}%
              </p>
            </div>
            <p class="price__description__item__amount__final">
              {{ price_final | format }} руб.
            </p>
          </div>
          <BtnCopmonentBgGradient
            ref="modal"
            class="description__btn__pay"
            title="Купить курс"
            fontSize="16"
            lineHeight="24"
            @showModalCourse="showModalCourse()"
            methods="showModalCourse"
          />
        </div>
        <div
          v-else
          class="price__description__item price__description__item__no-discount"
        >
          <p class="price__description__item__amount__final">
            {{ price_final | format }} руб.
          </p>
          <BtnCopmonentBgGradient
            class="description__btn__pay"
            title="Купить курс"
            fontSize="16"
            lineHeight="24"
            @showModalCourse="showModalCourse()"
            methods="showModalCourse"
          />
        </div>
        <div class="price__description__item">
          <h3 class="price__description__item__subtitle">
            Предусмотрен вариант оплаты курса по частям
          </h3>
          <p class="price__description__item__text">
            При выборе этого варианта свяжитесь с нами и наш менеджер подробно
            расскажет вам все условия
          </p>
          <BtnCopmonentBgGradient
            class="description__btn__pay"
            title="Заказать консультацию"
            fontSize="16"
            lineHeight="24"
            @showModalCourse="showModalCourseConsultation()"
            methods="showModalCourse"
          />
        </div>
      </div>
      <p class="price__description__text">
        Мы акредитованная образовательная организация, вы можете получить
        налоговый вычет 13% за обучение
      </p>
      <p class="price__description__text">
        Для корпоративных клиентов у нас действуют специальные предложения, вы
        можете подробно ознакомится с ними нажав кнопку ниже
      </p>
      <button class="price__description__btn" @click="linkToArticle">
        <span class="price__description__btn__text">Узнать подробнее </span>
        <svg
          class="price__description__btn__arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="24"
          viewBox="0 0 28 24"
          fill="none"
        >
          <path
            d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5L2 10.5ZM27.0607 13.0607C27.6464 12.4749 27.6464 11.5251 27.0607 10.9393L17.5147 1.3934C16.9289 0.807611 15.9792 0.807611 15.3934 1.3934C14.8076 1.97919 14.8076 2.92893 15.3934 3.51472L23.8787 12L15.3934 20.4853C14.8076 21.0711 14.8076 22.0208 15.3934 22.6066C15.9792 23.1924 16.9289 23.1924 17.5147 22.6066L27.0607 13.0607ZM2 13.5L26 13.5V10.5L2 10.5L2 13.5Z"
            fill="url(#paint0_linear_884_641)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_884_641"
              x1="2"
              y1="12"
              x2="7.59892"
              y2="22.6625"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7277FF" />
              <stop offset="1" stop-color="#FF5A5A" />
            </linearGradient>
          </defs>
        </svg>
      </button>
    </div>
    <CoursesCardsComponentModal
      v-if="modalActive"
      @showModal="showModal()"
      :price_discount="price_discount"
      :price_persent="price_persent"
      :price_final="price_final"
      :modal_consuilation="modalConsuilation"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BtnCopmonentBgGradient from "@/components/btnCopmonent/BtnCopmonentBgGradient.vue";
import CoursesCardsComponentModal from "@/components/courses/coursesCardsComponent/CoursesCardsComponentModal.vue";

export default defineComponent({
  name: "PriceCourse",
  components: {
    BtnCopmonentBgGradient,
    CoursesCardsComponentModal,
  },
  props: ["data"],
  data() {
    return {
      modalActive: false,
      modalConsuilation: false,
      price_discount: 0,
      price_persent: 0,
      price_final: 0,
    };
  },
  methods: {
    showModalCourse() {
      this.modalActive = true;
    },
    showModalCourseConsultation() {
      this.modalActive = true;
      this.modalConsuilation = true;
    },
    showModal() {
      this.modalActive = false;
      this.modalConsuilation = false;
    },
    linkToArticle() {
      this.$router.push({ path: `/blog/article/17` });
    },
  },
  mounted() {
    if (this.data) {
      this.price_discount = +this.data.payment;

      this.price_persent = +this.data.discount_procent;
      this.price_final =
        +(this.data.payment * (100 - this.data.discount_procent)) / 100;
    }
  },
  filters: {
    format: (val: number) =>
      `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 "),
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (this.data) {
          this.price_discount = +this.data.payment;
          this.price_persent = +this.data.discount_procent;
          this.price_final =
            +(this.data.payment * (100 - this.data.discount_procent)) / 100;
        }
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.price {
  margin-top: 48px;
  padding: 0 80px 48px 80px;
  background: $color-dark-E343D;
  width: 100%;
  position: relative;
  left: -80px;
  display: flex;
  gap: 48px;
  flex-direction: column;

  @media (max-width: 991px) {
    margin-top: 64px;
    padding: 0px 60px 48px 60px;
    left: -60px;
  }

  @media (max-width: 576px) {
    margin-top: 64px;
    padding: 0px 16px 24px 16px;
    left: -16px;
    gap: 24px;
  }
}

.price__title {
  font-family: Gramatika-Medium;
  font-size: 3rem;
  font-weight: 400;
  line-height: 4.5rem;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 393px;

  @media (max-width: 991px) {
    font-size: 2.25rem;
    line-height: 3.375rem;
  }

  @media (max-width: 576px) {
    font-size: 1.75rem;
    line-height: 2.625rem;
    width: 288px;
  }
}

.price__description {
  display: flex;
  gap: 24px;
  flex-direction: column;

  @media (max-width: 576px) {
    gap: 16px;
  }
}

.price__description__list {
  display: flex;
  gap: 24px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
}

.price__description__item {
  width: 50%;
  border-radius: 16px;
  background: #efefef;
  padding: 16px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: 991px) {
    width: 100%;
  }

  &__no-discount {
    justify-content: center;
    background: none;

    @media (max-width: 991px) {
      flex-direction: row;
      justify-content: flex-start;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &:nth-last-child(1) {
    background: $color-form-fon;

    & .price__description__item__subtitle {
      color: $color-text;
    }
  }
}

.price__description__item__subtitle {
  color: $color-dark-E343D;
  text-align: center;
  font-family: Gramatika-Medium;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

.price__description__item__amount {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 16px;
  }
}

.price__description__item__amount__discount {
  display: flex;
  gap: 8px;
}

.price__description__item__amount__discount__text {
  color: $color-form-text;
  font-family: Inter-Regular;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;
  text-decoration-line: line-through;
}

.price__description__item__amount__discount__precent {
  font-family: Inter-Regular;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.price__description__item__amount__final {
  font-family: Gramatika-Medium;
  font-size: 2rem;
  font-weight: 400;
  line-height: 3rem;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: -6px;
  text-align: center;
}

.description__btn__pay {
  padding: 8px 16px;
  align-self: center;
}

.price__description__item__text {
  color: $color-text;
  text-align: center;
  font-family: Inter-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
}
.price__description__text {
  color: $color-text;
  font-family: Inter-Regular;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.price__description__btn {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 1.875rem;
  transition: all 0.2s ease-out;
  user-select: none;
  gap: 16px;

  &__text {
    font-family: "Gramatika-Medium";
    margin-top: -5px;
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
  }

  &__arrow {
    width: 24px;
  }
}
</style>