<template>
    <section class="contacts__details">
            <h2 class="contacts__details__title">
                Контакты учебного центра Фреон
            </h2>
            <div class="contacts__details__wrap">
                <div class="contacts__details__wrap_with_contacts">
                    <div class="contacts__details__text_medium__wrap contacts__details__vertical_wrap">
                        <p class="contacts__details__text_medium">Телефон</p>
                        <p class="contacts__details__text_medium">Электронная почта</p>
                        <p class="contacts__details__text_medium">Юридический адрес</p>
                    </div>
                    <div class="contacts__details__img__wrap  contacts__details__vertical_wrap">
                        <img class="contacts__details__img" src="@/assets/icons/contacts/phone.svg" alt="phone">
                        <img class="contacts__details__img" src="@/assets/icons/contacts/mail.svg" alt="mail">
                        <img class="contacts__details__img" src="@/assets/icons/contacts/map.svg" alt="map">
                    </div>
                    <div class="contacts__details__text_regular__wrap  contacts__details__vertical_wrap">
                        <div class="contacts__details__text_regular">
                            <span>+7 499 653 87 90</span>
                            <span>+7 499 653 95 10</span>
                        </div>
                        <div class="contacts__details__text_regular">
                            <span>info@prof2.ru</span>
                            <span>info@vercont.ru</span>
                        </div>
                        <p class="contacts__details__text_regular">г. Москва, ул. Автозаводская, дом 17, корп. 3, офис 11</p>
                    </div>
                </div>
                <div class="contacts__details__wrap_with_logo">
                    <div class="contacts__details__logo_card">
                        <a class="contacts__details__logo" href="https://vercont.ru/" target="_blank">
                            <svg class="contacts__details__logo_img" xmlns="http://www.w3.org/2000/svg" width="64" height="32" viewBox="0 0 64 32" fill="none">
                                <path d="M29.3157 2.75152L32.0711 -0.000794333L34.826 2.75097C35.151 3.07515 35.4088 3.46012 35.5848 3.88387C35.7608 4.30763 35.8514 4.76187 35.8516 5.22064C35.8517 5.6794 35.7614 6.1337 35.5857 6.55758C35.41 6.98145 35.1524 7.36659 34.8277 7.69099L32.0722 10.4433L29.3157 7.68988C28.6601 7.03502 28.2918 6.14683 28.2918 5.2207C28.2918 4.29458 28.6601 3.40639 29.3157 2.75152Z" fill="#2E343D"/>
                                <path d="M46.0547 21.4291C54.6938 21.4291 63.3846 2.30664 63.3846 2.30664H53.7926L46.0547 21.4291Z" fill="#2E343D"/>
                                <path d="M18.0799 21.4291C9.40319 21.4291 0.75 2.30664 0.75 2.30664H10.342L18.0799 21.4291Z" fill="#2E343D"/>
                                <path d="M34.8395 13.6083L32.8516 18.6551L35.9051 30.3447C36.3075 31.3614 37.1163 32.0027 37.9987 32.0027H38.1396C39.1363 32.0027 40.05 31.28 40.5041 30.1304L51.5038 2.30664H51.4428C47.859 2.30666 44.3583 3.38485 41.3968 5.40071C38.4353 7.41658 36.1503 10.2767 34.8395 13.6083Z" fill="#2E343D"/>
                                <path d="M28.3255 30.1273L32.0547 20.683L29.2079 13.538C27.8878 10.2239 25.6024 7.38174 22.6473 5.37939C19.6923 3.37704 16.2036 2.30662 12.6328 2.30664L23.627 30.128C24.0788 31.2714 24.9855 31.9925 25.9759 31.9995C26.9671 31.9925 27.8738 31.2706 28.3255 30.1273Z" fill="#2E343D"/>
                                <path d="M35.9044 30.3433L32.0546 20.6816L28.3254 30.1259C27.8737 31.2693 26.967 31.9912 25.9766 31.9982H37.9965C37.1141 31.999 36.3053 31.36 35.9044 30.3433Z" fill="#2E343D"/>
                            </svg>
                            <svg class="contacts__details__logo_vercont" xmlns="http://www.w3.org/2000/svg" width="138" height="22" viewBox="0 0 138 22" fill="none">
                                <path d="M12.8639 1.08398C13.942 1.08398 15.4171 1.19738 16.4654 2.2743C17.5718 3.40752 17.7135 5.22114 17.7135 6.52407C17.7135 7.60098 17.6571 8.70605 16.7739 9.75402C16.2063 10.4344 15.6394 10.7175 14.9019 10.7746V10.888C15.8414 10.9725 16.6032 11.3979 17.0855 11.936C18.0251 12.9527 18.0251 14.3729 18.0251 15.478C18.0251 16.7809 18.0251 18.3678 16.8906 19.6425C15.7561 20.9173 14.4791 20.9181 13.2593 20.9181H3.7589C2.76612 20.9181 1.8579 21.0315 1.06398 20.1243C0.865112 19.8975 0.382812 19.246 0.382812 18.1128V3.94636C0.382812 3.20965 0.581683 2.41585 1.00917 1.90594C1.605 1.19738 2.48426 1.08398 3.10827 1.08398H12.8639ZM11.3888 9.41461C12.1263 9.41461 12.921 9.41461 13.4315 8.70605C13.5732 8.53634 13.8567 8.05458 13.8567 6.69456C13.8567 6.15572 13.8285 5.16405 13.2044 4.56967C12.6658 4.05898 11.9846 4.05898 11.3606 4.05898H5.23399C4.92081 4.05898 4.60763 4.03083 4.38292 4.28578C4.15821 4.54074 4.12768 4.93803 4.12768 5.16405V9.41461H11.3888ZM4.12768 16.4697C4.12768 17.1211 4.21223 17.4613 4.35395 17.631C4.66713 17.9712 5.29349 17.9439 5.57379 17.9439H11.4741C12.0699 17.9439 13.0909 17.9149 13.6586 17.2353C14.0837 16.7254 14.169 15.8471 14.169 15.2238C14.169 14.6568 14.1119 13.7504 13.7431 13.2116C13.5161 12.8987 12.9492 12.3904 11.7294 12.3904H4.12768V16.4697Z" fill="#2E343D"/>
                                <path d="M23.3597 20.9189C22.5086 20.9189 21.7429 20.9189 21.0891 20.2932C20.4079 19.641 20.3516 18.7345 20.3516 17.9697V3.91743C20.3516 3.12441 20.6068 2.33061 21.0327 1.84963C21.6849 1.14108 22.6495 1.08398 23.4153 1.08398H36.1515V4.05585H25.4306C25.09 4.05585 24.693 4.08479 24.4127 4.33975C24.0996 4.62286 24.0996 5.01937 24.0996 5.30326V9.29808H35.8713V12.2699H24.0972V16.9233C24.0972 17.2361 24.2107 17.5489 24.3814 17.6889C24.6077 17.9149 25.0618 17.9439 25.6576 17.9439H36.2659V20.9157L23.3597 20.9189Z" fill="#2E343D"/>
                                <path d="M38.3281 20.9181V4.05898C38.3281 3.32305 38.3281 2.52925 39.0085 1.84963C39.6615 1.19738 40.5689 1.08398 41.2501 1.08398H50.6957C51.7448 1.08398 53.4188 1.08398 54.6386 2.2743C56.0002 3.57801 56.1419 5.8163 56.1419 7.48758C56.1419 9.10256 55.9712 11.6529 54.4115 12.9277C53.674 13.5228 52.5395 13.8912 51.376 13.8912H42.0722V20.9181H38.3281ZM49.1924 10.9162C50.0145 10.9162 50.9791 10.9162 51.6313 10.1795C52.2271 9.49907 52.2843 8.22429 52.2843 7.45943C52.2843 6.58116 52.1989 5.39085 51.5749 4.73938C50.9227 4.08792 49.8728 4.05898 49.2488 4.05898H43.2067C42.9515 4.05898 42.6117 4.03083 42.3275 4.31472C42.0722 4.56967 42.0722 4.90909 42.0722 5.24929V10.9162H49.1924Z" fill="#2E343D"/>
                                <path d="M58.1562 1.08398H61.9003V20.9181H58.1562V1.08398ZM72.424 20.9181L64.2547 13.296C63.0912 12.2191 62.8931 11.2564 62.8931 10.7465C62.8931 10.3492 63.0066 9.47091 63.9712 8.5074L71.4876 1.08398H76.1117L67.5446 9.21752C66.8924 9.84318 66.4955 10.3226 66.4955 10.748C66.4955 11.3737 67.2048 12.051 67.7717 12.5609L77.1609 20.9197L72.424 20.9181Z" fill="#2E343D"/>
                                <path d="M94.2504 2.95415C96.2641 4.93749 96.4912 8.39424 96.4912 11.0009C96.4912 13.1258 96.4058 16.9219 94.2504 19.0476C92.1231 21.1443 88.5771 21.1443 86.3363 21.1443C83.9819 21.1443 80.6066 21.1443 78.4222 19.0476C76.125 16.8375 76.125 13.2392 76.125 11.0009C76.125 8.30899 76.2667 4.99379 78.4222 2.95415C80.6348 0.857422 83.9256 0.857422 86.3363 0.857422C88.6342 0.857422 92.0949 0.857422 94.2504 2.95415ZM81.2588 16.4691C82.4223 17.9707 84.0673 18.1693 86.3081 18.1693C87.9812 18.1693 90.1093 18.1123 91.3855 16.4691C92.1231 15.5306 92.6335 13.9188 92.6335 11.0009C92.6335 8.02901 92.1513 6.46487 91.3855 5.50371C90.1093 3.88873 87.9531 3.83242 86.3081 3.83242C84.5778 3.83242 82.564 3.88873 81.2588 5.50371C80.4649 6.49537 79.9826 7.96879 79.9826 11.0009C79.9826 13.8335 80.4367 15.4204 81.2588 16.4691Z" fill="#2E343D"/>
                                <path d="M116.602 20.9181H112.858V12.191H102.533V20.9181H98.7891V1.08398H102.533V9.21752H112.858V1.08398H116.602V20.9181Z" fill="#2E343D"/>
                                <path d="M125.736 20.9189V4.05976H117.992V1.08789H137.253V4.05976H129.478V20.9189H125.736Z" fill="#2E343D"/>
                            </svg>
                        </a>
                        <p class="contacts__details__logo_text">Все права пренадлежат компании ООО "Верконт сервис"</p>
                    </div>
                </div>
            </div>
    </section>
</template>

<script lang="ts">
export default {
    name: 'ContactsDetailsComponent',

    data() {
        return {
            
        };
    },
};
</script>

<style lang="scss" scoped>

.contacts__details{
    box-sizing: border-box;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 105px;
    padding-left: 80px;
    padding-right: 80px;

    @media (max-width: 991px){
        margin-top: 86px;
        padding-left: 60px;
        padding-right: 60px;
    }

    @media (max-width: 576px){
        margin-top: 70px;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.contacts__details__title{
    font-family: 'Gramatika-Medium';
    font-size: 4rem;
    font-weight: 400;
    line-height: 6rem;
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
    margin-bottom: 78px;
    max-width: 1033px;

    @media (max-width: 991px){
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 38px;
    }

    @media (max-width: 576px){
        font-size: 1.75rem;
        line-height: 2.625rem;
    }
}
.contacts__details__wrap{
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px){
        flex-direction: column;
    }
}
.contacts__details__wrap_with_contacts{
    display: flex;
    gap: 17px;
    height: 336px;

    @media (max-width: 991px){
        margin-bottom: 120px;
        height: 304px;
        justify-content: center;
    }

    @media (max-width: 576px){
        justify-content: flex-start;
        margin-bottom: 55px;
    }
}
.contacts__details__vertical_wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contacts__details__text_medium{
    font-family: 'Inter-Medium';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    width: 205px;
    text-align: end;

    &__wrap{
        padding-top: 25px;
        padding-bottom: 23px;

        @media (max-width: 991px){
        padding-top: 28px;
        padding-bottom: 28px;
        }

        @media (max-width: 576px){
            display: none;
        }
    }
}
.contacts__details__img{
    width: 48px;
    height: 48px;
    
    &__wrap{
        background-color: $color-skills;
        border-radius: 16px;
        padding: 16px 22px;

        @media (max-width: 991px){
            padding: 16px 10px;
        }
    }
}

.contacts__details__text_regular{
    font-family: 'Inter-Regular';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    max-width: 370px;
    min-width: 233px;

    span{
        display: block;
    }

    @media (max-width: 991px){
        font-size: 1rem;
        line-height: 1.5rem;
        max-width: 233px;
        min-width: 200px;
    }
    &__wrap{
        padding-top: 10px;
        padding-bottom: 8px;

        @media (max-width: 991px){
            padding-top: 17px;
            padding-bottom: 18px;
        }

        @media (max-width: 676px){
            padding-bottom: 8px;
        }
            
        @media (max-width: 576px){
            padding-bottom: 18px;
        }

        @media (max-width: 366px){
            padding-bottom: 8px;
        }

    }
}

.contacts__details__logo_card{
    padding: 16px;
    background-color: #EFEFF0;
    border-radius: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 991px){
        margin-left: -60px;
        width: calc(100% + 88px);
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 0px;
    }
    @media (max-width: 576px){
        margin-left: -16px;
        width: 100%;

    }
}
.contacts__details__wrap_with_logo{
    width: 40%;
    
    @media (max-width: 991px){
        width: 100%;
    }
}

.contacts__details__logo{
    border-bottom: 1px solid $color-dark-E343D;
    display: flex;
    align-items: center;
    gap: 11px;
    padding-bottom: 4px;
    margin-bottom: 24px;

    @media (max-width: 991px){
        margin-bottom: 8px;
    }
    @media (max-width: 576px){
        gap: 4px;
        padding-bottom: 0px;
    }

    &_img{
        @media (max-width: 576px){
            width: 36px;
            height: 20px;
        }
    }
    &_vercont{
        @media (max-width: 576px){
            width: 77px;
            height: 20px;
        }
    }
}

.contacts__details__logo:hover{
    border-bottom: 1px solid $color-hover;

    path{
        fill: $color-hover;
    }
}

.contacts__details__logo_text{
    font-family: 'Inter-Medium';
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.875rem;
    text-align: center;

    max-width: 343px;

    @media (max-width: 991px){
        max-width: 575px;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    @media (max-width: 576px){
        max-width: 288px;
    }
}
</style>