<template >
  <div class="blog__top" :style="getUrl" @click="linkToCourse(data.id)">
    <div class="blog__top__tags">
      <p
        class="blog__top__tags__item"
        v-if="data.tags[0].name !== undefined"
        @click.stop="changeCategory(data.tags[0].id)"
      >
        {{ data.tags[0].name }}
      </p>
      <p
        class="blog__top__tags__item"
        v-if="data.tags[1] !== undefined"
        @click.stop="changeHashTag(data.tags[1].id)"
      >
        {{ data.tags[1].name }}
      </p>
    </div>
    <h3 class="blog__top__title">
      {{ data.name }}
    </h3>
    <p class="blog__top__description">{{ data.preview }}</p>
    <div class="blog__top__footer">
      <div class="blog__top__footer__icons">
        <div class="blog__top__footer__icons__wraper">
          <span class="blog__top__footer__icons__wraper__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.744 22.8888C12.336 23.0371 11.664 23.0371 11.256 22.8888C7.776 21.6652 0 16.5607 0 7.90899C0 4.08989 2.988 1 6.672 1C8.856 1 10.788 2.08764 12 3.76854C12.6165 2.91063 13.4196 2.21337 14.3448 1.7326C15.27 1.25184 16.2917 1.00094 17.328 1C21.012 1 24 4.08989 24 7.90899C24 16.5607 16.224 21.6652 12.744 22.8888Z"
                fill="#E5607A"
              />
            </svg>
          </span>
          <p class="blog__top__footer__icons__wraper__text">
            {{ data.likes || 0 }}
          </p>
        </div>
        <div class="blog__top__footer__icons__wraper">
          <span class="blog__item__footer__icons__wraper__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.5632 11.8275C22.8444 12.1491 23 12.5668 23 13C23 13.4332 22.8444 13.8509 22.5632 14.1725C20.7829 16.15 16.7305 20 12 20C7.26947 20 3.21714 16.15 1.43683 14.1725C1.15565 13.8509 1 13.4332 1 13C1 12.5668 1.15565 12.1491 1.43683 11.8275C3.21714 9.85 7.26947 6 12 6C16.7305 6 20.7829 9.85 22.5632 11.8275ZM12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17ZM14 13C14 14.1046 13.1046 15 12 15C10.8954 15 10 14.1046 10 13C10 11.8954 10.8954 11 12 11C13.1046 11 14 11.8954 14 13Z"
                fill="#7277FF"
              />
            </svg>
          </span>
          <p class="blog__top__footer__icons__wraper__text">
            {{ data.views || 0 }}
          </p>
        </div>
      </div>
      <p class="blog__top__footer__date">{{ data.date_begin }}</p>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import { v4 as uuid } from "uuid";
import IBlog from "@/interface/blog/IBlog";

interface IData {
  data: IBlog;
}

export default defineComponent({
  name: "BlogTop",
  props: ["item"],
  data(): IData {
    return {
      data: this.item,
    };
  },
  methods: {
    keyId(index: number): string {
      return index + uuid();
    },
    changeCategory(id: number) {
      this.$emit("change-сategory", id);
    },
    changeHashTag(id: number) {
      this.$emit("change-hash-tag", id);
    },
    linkToCourse(id: number) {
      this.$router.push({ path: `/blog/article/${id}` });
    },
  },
  computed: {
    getUrl: function (): string {
      return this.data ? `backgroundImage: url("${this.data.avatar}")` : "";
    },
  },
});
</script>

<style lang="scss" scoped>
.blog__top {
  margin-top: 48px;
  display: flex;
  padding: 32px 32px 32px 96px;
  flex-direction: column;
  border-radius: 24px;
  background-position: center;
  background-size: 100% 120%;
  position: relative;
  z-index: 0;
  color: $color-text;
  font-family: "Inter-Regular";
  line-height: 1.5rem;
  transition: all 1.4s ease;

  @media (max-width: 991px) {
    padding: 32px 32px 32px 48px;
    border-radius: 16px;
  }

  @media (max-width: 576px) {
    padding: 16px;
    width: 100%;
    left: -16px;
    border-radius: 0;
  }

  &:hover {
    background-size: 120% 140%;
  }

  &:hover .blog__top__title {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
    max-width: 70%;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: black;
    border-radius: 24px;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7) 73.62%,
      rgba(0, 0, 0, 0) 100%
    );

    @media (max-width: 991px) {
      border-radius: 16px;
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.7) 73.62%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    @media (max-width: 768px) {
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.7) 100%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    @media (max-width: 576px) {
      border-radius: 0;
    }
  }
}

.blog__top__tags {
  display: flex;
  gap: 40px;

  &__item {
    line-height: 1.5rem;
    cursor: pointer;
    user-select: none;
  }
}

.blog__top__title {
  margin-top: 24px;
  font-family: "Gramatika-Medium";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  max-width: 70%;

  @media (max-width: 991px) {
    max-width: 70%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.blog__top__description {
  margin-top: 20px;
  font-size: 1.25rem;
  line-height: 1.875rem;
  max-width: 70%;
  min-height: 150px;

  @media (max-width: 991px) {
    max-width: 70%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    display: none;
  }
}

.blog__top__footer {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 70%;

  @media (max-width: 991px) {
    max-width: 70%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &__icons {
    display: flex;
    gap: 40px;

    @media (max-width: 576px) {
      gap: 20px;
    }

    &__wraper {
      display: flex;
      gap: 10px;
    }
  }
}
</style>


