<template>
  <div class="courses__card">
    <CoursesCardsComponentModal
      v-if="modalActive"
      @showModal="showModal()"
      :price_discount="price_discount"
      :price_persent="price_persent"
      :price_final="price_final"
    />

    <div class="courses__card__container">
      <h3 class="courses__card__title">{{ card.name }}</h3>
      <BtnCopmonentArrow
        class="courses__card__btn_arrow courses__card__btn_arrow_mobile"
        title="Узнать подробнее"
        width_arrow="24"
        @linkToCourse="linkToCourse()"
        methods="linkToCourse"
      />
      <div class="courses__card__wrap">
        <div class="courses__card__document_wrap">
          <div class="courses__card__document_wrap__row">
            <p class="courses__card__document_wrap__box">
              {{ card.duration }} ч.
            </p>
            <p
              class="courses__card__document_wrap__box courses__card__document_wrap__document"
            >
              {{ card?.document_type }}
            </p>
          </div>
          <p class="courses__card__document_wrap__box">
            {{ card?.training_program }}
          </p>
        </div>
        <div class="courses__card__prices_container">
          <div class="courses__card__prices_wrap">
            <p class="courses__card__prices" v-if="card?.discount_procent">
              <span class="courses__card__price"
                >{{ this.price_discount | format }}
              </span>
              <span class="courses__card__sale">-{{ price_persent }}%</span>
            </p>
            <p class="courses__card__price_sale">
              {{ price_final | format }} руб.
            </p>
          </div>
          <button
            class="courses__card__btn"
            @click="showModalCourse(card.price_sale, card.price, card.sale)"
          >
            <span>Купить курс</span>
          </button>
        </div>
      </div>
    </div>
    <div class="courses__card__container courses__card__container_description">
      <p
        class="courses__card__description"
        v-html="card.description.will_learn"
      ></p>
      <BtnCopmonentArrow
        class="courses__card__btn_arrow"
        title="Узнать подробнее"
        width_arrow="24"
        @linkToCourse="linkToCourse()"
        methods="linkToCourse"
      />
    </div>
  </div>
</template>
  
  <script lang="ts">
import { defineComponent } from "vue";
import BtnCopmonentArrow from "@/components/btnCopmonent/BtnCopmonentArrow.vue";
import CoursesCardsComponentModal from "./CoursesCardsComponentModal.vue";

export default defineComponent({
  name: "CoursesCardsComponentList",
  props: ["card", "index"],
  components: {
    BtnCopmonentArrow,
    CoursesCardsComponentModal,
  },
  data() {
    return {
      modalActive: false,
      price_discount: 0,
      price_persent: 0,
      price_final: 0,
    };
  },
  filters: {
    format: (val: number) =>
      `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 "),
  },
  mounted() {
    if (this.card) {
      this.price_discount = +this.card.payment;
      this.price_persent = +this.card.discount_procent;
      this.price_final =
        +(this.card.payment * (100 - this.card.discount_procent)) / 100;
    }
  },

  methods: {
    showModalCourse(price: number, discount: number, percent: number): void {
      this.modalActive = true;
    },
    showModal() {
      this.modalActive = false;
    },
    linkToCourse(id: number) {
      this.$router.push({ path: `/course/${this.card.id}` });
    },
  },
});
</script>
  
  <style lang="scss" scoped>
.courses__card {
  background-color: $color-skills;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1180px) {
    flex-direction: column;
  }

  &__container {
    width: 48%;

    @media (max-width: 1180px) {
      width: 100%;
    }
  }

  &__title {
    font-family: "Gramatika-Medium";
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    color: #2e343d;
    margin-bottom: 24px;

    @media (max-width: 1180px) {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }

  &__document_wrap {
    @media (max-width: 635px) {
      margin-bottom: 16px;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      gap: 8px;

      @media (max-width: 635px) {
        margin-bottom: 8px;
      }
    }

    &__box {
      font-family: "Inter-Regular";
      font-size: 1rem;
      line-height: 1.5rem;
      color: $color-dark-E343D;
      background-color: $color-bg;
      border-radius: 8px;
      padding: 16px;
      display: flex;
      justify-content: center;

      @media (max-width: 635px) {
        flex-grow: 1;
      }
    }

    &__document {
      flex-grow: 1;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1180px) {
      margin-bottom: 24px;
    }

    @media (max-width: 635px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__prices_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 635px) {
      display: block;
    }
  }

  &__prices_wrap {
    @media (max-width: 635px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 24px;
    }
  }

  &__prices {
    text-align: end;

    @media (max-width: 635px) {
      text-align: start;
    }
  }

  &__price {
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    color: $color-form-text;
    text-decoration-line: line-through;
    margin-right: 20px;
  }

  &__sale {
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
  }

  &__price_sale {
    font-family: "Gramatika-Medium";
    font-size: 2rem;
    line-height: 3rem;
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
    text-align: end;

    @media (max-width: 635px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  &__btn {
    box-sizing: border-box;
    background: linear-gradient(135deg, #7277ff 0%, #ff5a5a 100%) border-box;
    padding: 6px 16px;
    border: 2px solid transparent;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-out;
    user-select: none;
    cursor: pointer;

    span {
      font-family: "Inter-Medium";
      font-size: 1rem;
      line-height: 1.5rem;
      color: $color-text;
    }

    @media (max-width: 635px) {
      width: 100%;
    }
  }

  &__btn:hover {
    background: $color-hover;
  }

  &__btn:active {
    background: $color-form-text;
  }

  &__btn:disabled {
    background: $color-blue;
  }

  &__container_description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 635px) {
      display: none;
    }
  }

  &__description {
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    color: $color-dark-E343D;

    @media (max-width: 1180px) {
      margin-bottom: 16px;
    }
  }

  &__btn_arrow {
    font-size: 1.25rem;
    line-height: 1.875rem;
    background-color: $color-skills;
    align-self: flex-end;

    @media (max-width: 635px) {
      align-self: center;
    }

    &_mobile {
      display: none;

      @media (max-width: 635px) {
        display: flex;
        margin-bottom: 30px;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
</style>
