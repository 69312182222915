<template>
  <buttton @click="edit()" class="btn__component"
    ><span class="btn__component__text">{{ title }}</span></buttton
  >
</template>

<script  lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BtnComponent",
  props: {
    title: String,
    methods: String || undefined,
  },
  methods: {
    edit(): void {
      if (this.methods === "login") {
        this.$router.push("login");
      }
      if (this.methods === "linkToCourse") {
        this.$emit("linkToCourse");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.btn__component {
  box-sizing: border-box;
  background: linear-gradient(#fff, #fff) padding-box,
    $color-gradient border-box;
  padding: 6px 16px;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: all 0.2s ease-out;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: $color-bg;
    border-color: $color-hover;

    .btn__component__text {
      color: $color-hover;
    }
  }

  &:active {
    background: $color-bg;
    border-color: $color-form-text;

    .btn__component__text {
      color: $color-form-text;
    }
  }

  &:disabled {
    background: $color-bg;
    border-color: $color-blue;

    .btn__component__text {
      color: $color-blue;
    }
  }
}

.btn__component__text {
  font-family: "Inter-Medium";
  font-size: 1rem;
  line-height: 1.5rem;
  background-image: $color-gradient;
  background-clip: text;
  color: transparent;
}
</style>