<template>
  <div>
    <div class="reviews" id="reviews">
      <h2 class="reviews__title">Отзывы студентов</h2>
      <div class="reviews__list">
        <div
          class="reviews__item"
          v-for="(item, i) in arrreViews"
          :key="i"
          @click="setActiveModal(true, item.value)"
        >
          <div class="reviews__item__header">
            <div class="reviews__item__header__initials">
              <p class="reviews__item__header__initials__text">
                {{ item.value.split("\n")[0].slice(0, 1) }}
              </p>
            </div>
            <div class="reviews__item__header__content">
              <p class="reviews__item__header__content__name">
                {{ item.value.split("\n")[0] }}
              </p>
              <p class="reviews__item__header__content__date">
                {{ item.value.split("\n")[1] }}
              </p>
            </div>
          </div>
          <div class="reviews__item__content">
            <p class="reviews__item__content__text">
              {{ item.value.split("\n")[2] }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="avtiveModal">
      <div class="modal__content">
        <div class="modal__content__header">
          <div class="modal__content__header__initials">
            <p class="modal__content__header__initials__text">
              {{ reviewModal.split("\n")[0].slice(0, 1) }}
            </p>
          </div>
          <div class="modal__content__header__content">
            <p class="modal__content__header__content__name">
              {{ reviewModal.split("\n")[0] }}.
            </p>
            <p class="modal__content__header__content__date">
              {{ reviewModal.split("\n")[1] }}
            </p>
          </div>
        </div>
        <div class="modal__content__item__content">
          <p class="modal__content__item__content__text">
            {{ reviewModal.split("\n")[2] }}
          </p>
        </div>
        <div class="closeModal" @click="setActiveModal(false)"></div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReviewsCourse",
  props: ["reviews"],
  data() {
    return {
      avtiveModal: false,
      arrreViews: null,
      reviewModal: "",
    };
  },
  methods: {
    setActiveModal(flag: boolean, review: string) {
      this.avtiveModal = flag;
      this.reviewModal = review;
    },
  },
  mounted() {
    if (this.reviews.length > 3) {
      this.arrreViews = this.reviews.slice(0, 3);
    } else {
      this.arrreViews = this.reviews;
    }
  },
});
</script>

<style lang="scss" scoped>
.reviews {
  margin-top: 148px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (max-width: 991px) {
    margin-top: 80px;
  }
}

.reviews__title {
  color: $color-dark-E343D;
  font-family: Gramatika-Medium;
  font-size: 3rem;
  font-weight: 400;
  line-height: 4.5rem;

  @media (max-width: 991px) {
    font-size: 2.25rem;
    line-height: 3.375rem;
  }
}

.reviews__list {
  display: flex;
  gap: 16px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.reviews__item {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  background: $color-questions;
  box-sizing: border-box;
  cursor: pointer;
  flex-grow: 1;
  min-width: 33%;

  @media (max-width: 991px) {
    width: 100%;
  }

  &:hover {
    background: #c1dcff;
  }
}

.reviews__item__header,
.modal__content__header {
  display: flex;
  gap: 19px;
  align-items: center;
}

.reviews__item__header__initials,
.modal__content__header__initials {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    color: $color-text;
    font-family: Gramatika-Medium;
    font-size: 1.5rem;
    margin-top: -6px;
  }
}

.reviews__item__header__content__name,
.modal__content__header__content__name {
  color: $color-dark-E343D;
  font-family: Inter-Regular;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.reviews__item__header__content__date,
.modal__content__header__content__date {
  color: $color-dark-E343D;
  font-family: Inter-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
}

.reviews__item__content {
  position: relative;
  mask-image: linear-gradient(
    to bottom,
    #e5f0ff 20%,
    rgba(229, 240, 255, 0) 110%
  );
}

.reviews__item__content__text,
.modal__content__item__content__text {
  color: $color-dark-E343D;
  font-family: Inter-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal__content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $color-skills;
  padding: 16px;
  border-radius: 24px;
  background: $color-questions;
  box-sizing: border-box;
  width: 640px;

  @media (max-width: 768px) {
    max-width: 80%;
    transform: translate(-50%, -25%);
  }

  @media (max-width: 576px) {
    max-width: 90%;
  }
}

.closeModal {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 24px;
  height: 24px;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity ease 0.5s;
  z-index: 500;
  &:hover {
    opacity: 1;
  }
}

.closeModal::before,
.closeModal::after {
  content: "";
  position: absolute;
  top: 0px;
  display: block;
  width: 24px;
  height: 3px;
  background: #000;
}

.closeModal::before {
  transform: rotate(45deg);
}

.closeModal::after {
  transform: rotate(-45deg);
}
</style>