<template>
    <div class="pdf">
        <embed src="diploms.pdf" type="application/pdf" width="100%" height="100%">
    </div>
</template>

<script>
export default {    
    name: 'DiplomsPage',
};
</script>

<style lang="scss" scoped>
    .pdf{
        width: 100%;
        height: 100vh;
    }
</style>