<template>
  <div class="pagination__container">
    <ul class="blog__pagination">
      <li :class="classPrev" @click="prevPage()">&lt;</li>
      <li :class="classBreakLineLeft">...</li>
      <template v-for="i in totalPage">
        <li
          v-if="i < currentPage + 3 && i > currentPage - 3"
          class="pagination__item"
          :key="i"
        >
          <span
            class="pagination__span"
            :class="{ pagination__item__active: currentPage === i }"
            @click="changePage(i)"
          >
            {{ i }}
          </span>
        </li>
      </template>
      <li :class="classBreakLineRight">...</li>
      <li :class="classNext" @click="nextPage()">&gt;</li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaginationComponent",
  props: ["total", "page"],

  data() {
    return {
      currentPage: this.page,
      totalPage: this.total,
    };
  },
  methods: {
    changePage(page: number): void {
      this.currentPage = page;
      this.$emit("page-change", page);
      scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    prevPage(): void {
      this.currentPage > 0 ? this.currentPage-- : null;
      this.$emit("page-change", this.currentPage);
    },
    nextPage(): void {
      this.currentPage < this.totalPage ? this.currentPage++ : null;
      this.$emit("page-change", this.currentPage);
    },
  },
  computed: {
    classPrev: function (): string {
      return this.currentPage > 1
        ? "pagination__prev"
        : "pagination__hidden__element";
    },
    classNext: function (): string {
      return this.currentPage < this.totalPage
        ? "pagination__next"
        : "pagination__hidden__element";
    },
    classBreakLineLeft: function (): string {
      return this.currentPage > 3
        ? "pagination__break__line pagination__break__line__left"
        : "pagination__hidden__element";
    },
    classBreakLineRight: function (): string {
      return this.currentPage <= this.totalPage - 3
        ? "pagination__break__line pagination__break__line__right"
        : "pagination__hidden__element";
    },
  },
  watch: {
    total: function (newVal) {
      this.totalPage = newVal;
      this.currentPage = 1;
    },
    page: function (newVal) {
      this.currentPage = newVal;
    },
  },
});
</script>

<style lang="scss" scoped>
.pagination__container {
  width: 100%;
  margin-top: 32px;
}
.blog__pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  align-items: center;
  gap: 20px;
  font-family: "Gramatika-Medium";
  font-size: 1.5rem;
  line-height: 2rem;
  color: $color-dark-E343D;
}
.pagination__prev {
  cursor: pointer;
}
.pagination__break__line {
  user-select: none;
}
.pagination__break__line__left {
  margin-left: -10px;
}
.pagination__break__line__right {
  margin-right: -10px;
}
.pagination__item {
}

.pagination__span {
  display: block;
  text-align: center;
  min-width: 15px;
  cursor: pointer;
}

.pagination__item__active {
  box-sizing: border-box;
  display: block;
  height: 32px;
  border-bottom: 2px solid $color-dark-E343D;
}

.pagination__hidden__element {
  display: none;
}

.pagination__next {
  cursor: pointer;
}
</style>