<template>
  <div>
    <div class="description" id="description">
      <h2 class="description__title">
        {{ data?.name }}
      </h2>
      <div class="description__btn">
        <BtnCopmonentBgGradient
          class="description__btn__pay"
          title="Купить курс"
          fontSize="16"
          lineHeight="24"
          @showModalCourse="showModalCourse()"
          methods="showModalCourse"
        />
        <button class="description__btn__free-learn" @click="linkToFreeLearn">
          <span class="description__btn__free-learn__text"
            >Попробовать бесплатно
          </span>
          <svg
            class="description__btn__free-learn__arrow"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="24"
            viewBox="0 0 28 24"
            fill="none"
          >
            <path
              d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5L2 10.5ZM27.0607 13.0607C27.6464 12.4749 27.6464 11.5251 27.0607 10.9393L17.5147 1.3934C16.9289 0.807611 15.9792 0.807611 15.3934 1.3934C14.8076 1.97919 14.8076 2.92893 15.3934 3.51472L23.8787 12L15.3934 20.4853C14.8076 21.0711 14.8076 22.0208 15.3934 22.6066C15.9792 23.1924 16.9289 23.1924 17.5147 22.6066L27.0607 13.0607ZM2 13.5L26 13.5V10.5L2 10.5L2 13.5Z"
              fill="url(#paint0_linear_884_641)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_884_641"
                x1="2"
                y1="12"
                x2="7.59892"
                y2="22.6625"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#7277FF" />
                <stop offset="1" stop-color="#FF5A5A" />
              </linearGradient>
            </defs>
          </svg>
        </button>
      </div>
      <div class="description__programs">
        <div class="description__programs__item">
          <p class="description__programs__item__title">Программа обучения</p>
          <p class="description__programs__item__subtitle">
            {{ data?.training_program }}
          </p>
        </div>
        <div class="description__programs__item">
          <p class="description__programs__item__title">Формат</p>
          <p class="description__programs__item__subtitle">
            {{ data?.format_type }}
          </p>
        </div>
        <div class="description__programs__item">
          <p class="description__programs__item__title">Старт</p>
          <p class="description__programs__item__subtitle">{{ data?.start }}</p>
        </div>
        <div class="description__programs__item">
          <p class="description__programs__item__title">Длительность</p>
          <p class="description__programs__item__subtitle">
            {{ data?.duration }} ч.
          </p>
        </div>
        <div class="description__programs__item">
          <p class="description__programs__item__title">Документ</p>
          <p class="description__programs__item__subtitle">
            {{ data?.document_type }}
          </p>
        </div>
      </div>
      <div class="description__contnent">
        <p
          class="description__contnent__item"
          v-for="(item, i) in data?.description.course"
          :key="i"
          v-html="item.value"
        ></p>
      </div>
    </div>
    <CoursesCardsComponentModal
      v-if="modalActive"
      @showModal="showModal()"
      :price_discount="price_discount"
      :price_persent="price_persent"
      :price_final="price_final"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BtnCopmonentBgGradient from "@/components/btnCopmonent/BtnCopmonentBgGradient.vue";
import CoursesCardsComponentModal from "@/components/courses/coursesCardsComponent/CoursesCardsComponentModal.vue";

export default defineComponent({
  name: "DescriptionCourse",
  props: ["data"],
  components: {
    BtnCopmonentBgGradient,
    CoursesCardsComponentModal,
  },
  data() {
    return {
      modalActive: false,
      price_discount: 0,
      price_persent: 0,
      price_final: 0,
    };
  },
  methods: {
    showModalCourse() {
      this.modalActive = true;
    },
    showModal() {
      this.modalActive = false;
    },
    linkToFreeLearn() {
      this.$router.push({ name: `free-learn` });
    },
  },
  mounted() {
    if (this.data) {
      this.price_discount = +this.data.payment;
      this.price_persent = +this.data.discount_procent;
      this.price_final =
        +(this.data.payment * (100 - this.data.discount_procent)) / 100;
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (this.data) {
          this.price_discount = +this.data.payment;
          this.price_persent = +this.data.discount_procent;
          this.price_final =
            +(this.data.payment * (100 - this.data.discount_procent)) / 100;
        }
      },
    },
  },
});
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.description {
  margin-top: 126px;
  padding: 0 80px 23px 80px;
  width: 100%;
  position: relative;
  left: -80px;
  background-image: url("../../../assets/images/course/title.png");
  background-position: center;
  z-index: 0;

  @media (max-width: 991px) {
    margin-top: 132px;
    padding: 69px 60px 21px 60px;
    left: -60px;
  }

  @media (max-width: 576px) {
    margin-top: 84px;
    padding: 69px 16px 21px 16px;
    left: -16px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: $color-dark-E343D;
    backdrop-filter: blur(50px);
    mask-image: linear-gradient(
      to right,
      rgba(46, 52, 61, 1) 23%,
      rgba(46, 52, 61, 0) 127%
    );

    @media (max-width: 991px) {
      mask-image: linear-gradient(
        to right,
        rgba(46, 52, 61, 1) 35%,
        rgba(46, 52, 61, 0) 130%
      );
    }
  }
}

.description__title {
  color: $color-text;
  font-family: Gramatika-Medium;
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 5.25rem;

  @media (max-width: 991px) {
    font-size: 3rem;
    line-height: 4.5rem;
  }

  @media (max-width: 576px) {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.description__btn {
  margin-top: 64px;
  display: flex;
  gap: 48px;
  align-items: center;

  @media (max-width: 576px) {
    margin-top: 32px;
    gap: 24px;
    align-items: flex-start;
    flex-direction: column;
  }

  &__pay {
    padding: 6px 16px;

    @media (max-width: 576px) {
      flex-shrink: 0;
      width: 274px;
    }
  }

  &__free-learn {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.25rem;
    line-height: 1.875rem;
    transition: all 0.2s ease-out;
    user-select: none;
    gap: 16px;

    &__text {
      font-family: "Gramatika-Medium";
      margin-top: -5px;
      background-image: $color-gradient;
      background-clip: text;
      color: transparent;
    }

    &__arrow {
      width: 24px;
    }
  }
}

.description__programs {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  flex-wrap: wrap;

  @media (max-width: 576px) {
    position: relative;
    width: calc(100% + 32px);
    flex-wrap: nowrap;
    overflow-x: scroll !important;
    left: -16px;
    padding: 0 16px;
    box-sizing: border-box;
    margin-top: 32px;
  }

  &__item {
    display: flex;
    gap: 8px;
    flex-direction: column;
    padding: 8px 16px;
    background: $color-text-blue;
    border-radius: 8px;
    max-width: 50%;

    @media (max-width: 768px) {
      flex-grow: 1;
      flex-shrink: 0;
      align-items: center;

      &:nth-child(4) {
        max-width: 33%;
      }

      &:last-child {
        flex-grow: 0;
      }
    }

    @media (max-width: 667px) {
      max-width: 70%;

      &:nth-child(4) {
        max-width: 43%;
      }

      &:last-child {
        max-width: 45%;
        flex-grow: 1;
      }
    }

    &__title {
      color: $color-form-fon;
      font-family: Inter-Regular;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &__subtitle {
      color: $color-form-fon;
      font-family: Gramatika-Medium;
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }
}

.description__contnent {
  display: flex;
  gap: 16px;
  margin-top: 16px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  &__item {
    color: $color-text;
    padding: 16px;
    font-family: Inter-Regular;
    font-size: 1rem;
    line-height: 1.5rem;
    background: $color-dark-E343D;
    border-radius: 8px;

    &-no-wrap {
      white-space: nowrap;
    }

    @media (max-width: 991px) {
      width: 100%;
    }
  }
}
</style>
