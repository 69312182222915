<template>
    <div>
      <HeadersComponent />
      <Error401Component/>
      <FooterComponent hiddenBtn="true" class="error401__footer"/>
    </div>
  </template>
  
  <script lang="ts">
  import HeadersComponent from "@/components/headers/Headers.vue";
  import Error401Component from "@/components/error401/Error401Component.vue";
  import FooterComponent from "@/components/footer/Footer.vue";

  export default {
    name: "Error401Page",
    components: {
      HeadersComponent,
      Error401Component,
      FooterComponent,
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .error401__footer{
    margin-top: 0px;
  }
  </style>