<template>
  <div class="course-answers">
    <h2 class="course-answers__title">
      Почему стоит пройти пробный бесплатный курс?
    </h2>
    <div class="course-answers__list">
      <div class="course-answers__item">
        <p class="course-answers__item__subtitle">/01</p>
        <p class="course-answers__item__text">
          Это отличная возможность оценить качество материалов и методику
          преподавания.
        </p>
      </div>
      <div class="course-answers__item">
        <p class="course-answers__item__subtitle">/02</p>
        <p class="course-answers__item__text">
          Вы сможете определить свой уровень знаний и понять, на чем вам следует
          сосредоточиться.
        </p>
      </div>
      <div class="course-answers__item">
        <p class="course-answers__item__subtitle">/03</p>
        <p class="course-answers__item__text">
          Пробный курс поможет понять, насколько эффективно вы усваиваете
          материал.
        </p>
      </div>
      <div class="course-answers__item">
        <p class="course-answers__item__subtitle">/04</p>
        <p class="course-answers__item__text">
          Вы сможете оценить свои возможности и решить, подходит ли данный курс
          для вас.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FreeCourseAnswers",
};
</script>

<style lang="scss" scoped>
.course-answers {
  display: flex;
  padding: 100px 80px 48px 80px;
  flex-direction: column;
  gap: 48px;
  background: $color-dark-E343D;

  @media (max-width: 991px) {
    padding: 60px 60px 24px 60px;
    gap: 32px;
  }

  @media (max-width: 576px) {
    padding: 48px 16px 24px 16px;
    gap: 48px;
  }
}

.course-answers__title {
  color: $color-text;
  font-family: Gramatika-Medium;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5rem;
}

.course-answers__list {
  display: flex;
  gap: 16px;
  max-width: 1280px;
  flex-wrap: wrap;
}

.course-answers__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  background: $color-form-fon;
  border-radius: 16px;
  box-sizing: border-box;
  width: 23.8%;
  gap: 75px;

  @media (max-width: 1280px) {
    width: 48%;
  }

  @media (max-width: 640px) {
    width: 100%;
  }
  
  &:first-child {
    background: $color-blue;
  }

  &:first-child .course-answers__item__text {
    color: $color-dark-E343D;
  }
}

.course-answers__item__subtitle {
  font-family: Gramatika-Medium;
  font-size: 4rem;
  font-weight: 900;
  line-height: 4rem;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 126px;
}

.course-answers__item__text {
  color: $color-dark-E343D;
  font-family: Inter-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $color-text;
}
</style>