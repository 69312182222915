<template>
  <div class="authPage">
    <HeadersComponent />
    <AuthForm />
    <FooterComponent class="authPage__footer" />
  </div>
</template>

<script lang="ts">
import HeadersComponent from "../components/headers/Headers.vue";
import AuthForm from "../components/authForm/AuthForm.vue";
import FooterComponent from "../components/footer/Footer.vue";

export default {
  name: "AuthPage",
  components: {
    HeadersComponent,
    AuthForm,
    FooterComponent,
  },

  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.authPage__footer {
  margin: 0 auto;
  position: initial;
}
</style>