<template>
    <div class="fixed-modal" @click.self="closeModal">
      <div class="error401__modal">
        <button class="error401__modal__btn_close" @click="closeModal">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.92969 5L19.0718 19.1421"
                stroke="#2E343D"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M5 19.4102L19.1421 5.26802"
                stroke="#2E343D"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </span>
        </button>
        
        <div class="error401__modal__radio__container">
          <button 
            :class="{error401__modal__radio__btn_active : toggleRadio}"
            class="error401__modal__radio__btn" 
            @click="toggleForm">
            <span class="error401__modal__radio__text">Информация</span>
          </button>
          <button 
            :class="{error401__modal__radio__btn_active : !toggleRadio}"
            class="error401__modal__radio__btn" 
            :disabled="disabled">
            <span class="error401__modal__radio__text">Статус сообщения</span>
          </button>
        </div>
    
        <form action="#" class="error401__modal__form" v-if="toggleRadio">
          <label class="error401__modal__form__title" for="author-name"
            >Как Вас зовут?</label
          >
          <div class="error401__modal__form__input__wrapper">
            <input
              type="text"
              :class="getInputClassName()"
              name="name"
              v-model="name"
              id="author-name"
              placeholder="Фамилия Имя Отчество"
            />
            <span class="error401__modal__form__input__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="24"
                viewBox="0 0 22 24"
                fill="none"
              >
                <path
                  d="M11.0047 12.4626C14.4333 12.4626 17.236 9.6599 17.236 6.2313C17.236 2.80271 14.4333 0 11.0047 0C7.57609 0 4.77344 2.80271 4.77344 6.2313C4.77344 9.6599 7.57615 12.4626 11.0047 12.4626Z"
                  fill="#A8BFDC"
                />
                <path
                  d="M21.6948 17.442C21.5315 17.0339 21.3139 16.6529 21.069 16.2992C19.8173 14.4488 17.8853 13.2243 15.7085 12.925C15.4364 12.8978 15.137 12.9522 14.9193 13.1155C13.7765 13.959 12.416 14.3944 11.001 14.3944C9.58595 14.3944 8.22543 13.959 7.08257 13.1155C6.86486 12.9522 6.56554 12.8706 6.29345 12.925C4.11658 13.2243 2.15741 14.4488 0.932931 16.2992C0.688035 16.6529 0.470327 17.0611 0.307098 17.442C0.225483 17.6053 0.252671 17.7958 0.334285 17.9591C0.551993 18.34 0.824077 18.721 1.06897 19.0475C1.44991 19.5645 1.85809 20.0271 2.32069 20.4624C2.70163 20.8434 3.13699 21.1971 3.57241 21.5509C5.72205 23.1564 8.3071 23.9998 10.9738 23.9998C13.6404 23.9998 16.2255 23.1563 18.3751 21.5509C18.8105 21.2244 19.2458 20.8434 19.6268 20.4624C20.0622 20.0271 20.4976 19.5645 20.8786 19.0475C21.1506 18.6937 21.3956 18.34 21.6132 17.9591C21.7492 17.7958 21.7764 17.6053 21.6948 17.442Z"
                  fill="#A8BFDC"
                />
              </svg>
            </span>
          </div>
  
          <div class="error401__modal__form__error" v-if="nameError">
            <span class="error401__modal__form__error__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM12 2C6.47 2 2 6.5 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
                  fill="#CC6666"
                />
              </svg>
            </span>
            <p class="error401__modal__form__error__text">Поле заполнено некорректно</p>
          </div>
  
          <div class="error401__modal__form__wrapper">
            <div class="error401__modal__form__wrap">
              <label class="error401__modal__form__title" for="user-phone">Телефон</label>
              <div class="error401__modal__form__input__wrapper">
                <input
                  type="tel"
                  :class="getInputClassPhone()"
                  name="phone"
                  v-model="phone"
                  id="user-phone"
                  placeholder="+7 (912) 281 80 13"
                />
                <span class="error401__modal__form__input__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M23.3707 17.6136L20.0143 14.2643C18.8157 13.0681 16.7779 13.5466 16.2984 15.1016C15.9388 16.1782 14.7401 16.7763 13.6613 16.537C11.2639 15.9389 8.02748 12.8289 7.42814 10.3169C7.06853 9.24034 7.78775 8.04418 8.86656 7.68537C10.4249 7.20691 10.9043 5.17342 9.70564 3.97726L6.34932 0.627988C5.39038 -0.209329 3.95195 -0.209329 3.11287 0.627988L0.835372 2.90071C-1.44213 5.29304 1.07511 11.6327 6.70893 17.2547C12.3428 22.8767 18.6958 25.5083 21.0932 23.1159L23.3707 20.8432C24.2098 19.8863 24.2098 18.4509 23.3707 17.6136Z"
                      fill="#A8BFDC"
                    />
                  </svg>
                </span>
              </div>
  
              <div class="error401__modal__form__error" v-if="phoneError">
                <span class="error401__modal__form__error__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM12 2C6.47 2 2 6.5 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
                      fill="#CC6666"
                    />
                  </svg>
                </span>
                <p class="error401__modal__form__error__text">Поле заполнено некорректно</p>
              </div>
            </div>
  
            <div class="error401__modal__form__wrap">
              <label class="error401__modal__form__title" for="user-email">E-mail</label>
              <div class="error401__modal__form__input__wrapper">
                <input
                  type="email"
                  :class="getInputClassEmail()"
                  name="email"
                  v-model="email"
                  id="user-email"
                  placeholder="example@mail.ru"
                />
                <span class="error401__modal__form__input__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.0057 15.6106C13.4086 16.1421 12.7151 16.4231 12 16.4231C11.285 16.4231 10.5914 16.1421 9.99431 15.6106L0.159797 6.85641C0.105234 6.80785 0.0520781 6.75721 0 6.70514V21.0499C0 22.6945 0.999609 23.9998 2.20936 23.9998H21.7906C23.0224 23.9998 24 22.6651 24 21.0499V6.70508C23.9478 6.75728 23.8945 6.80803 23.8398 6.85666L14.0057 15.6106Z"
                      fill="#A8BFDC"
                    />
                    <path
                      d="M0.939844 5.29435L10.7744 14.0486C11.1466 14.38 11.5733 14.5457 12 14.5457C12.4267 14.5457 12.8534 14.38 13.2256 14.0486L23.0602 5.29435C23.6487 4.77081 24 3.89459 24 2.94891C24 1.32284 23.0092 0 21.7914 0H2.20861C0.990797 6.25869e-05 0 1.3229 0 2.95047C0 3.89459 0.351375 4.77081 0.939844 5.29435Z"
                      fill="#A8BFDC"
                    />
                  </svg>
                </span>
              </div>
  
              <div class="error401__modal__form__error" v-if="emailError">
                <span class="error401__modal__form__error__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM12 2C6.47 2 2 6.5 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
                      fill="#CC6666"
                    />
                  </svg>
                </span>
                <p class="error401__modal__form__error__text">Поле заполнено некорректно</p>
              </div>
            </div>
          </div>
  
          <label class="error401__modal__form__title">Введите текст</label>
          <div class="error401__modal__form__input__wrapper">
            <textarea 
            :class="getInputClassMessage()"
            rows="5"
            name="message"
            v-model="message"
            id="user-message"
            placeholder="Оставьте свои вопросы или предложения"
            maxlength="500"
            ></textarea>
          </div>
  
          <div class="error401__modal__form__error" v-if="messageError">
            <span class="error401__modal__form__error__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM12 2C6.47 2 2 6.5 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
                  fill="#CC6666"
                />
              </svg>
            </span>
            <p class="error401__modal__form__error__text">Поле заполнено некорректно</p>
          </div>
  

          <div class="error401__modal__footer">
            <p class="error401__modal__text" >
                Нажимая кнопку “Отправить” вы соглашаетесь с 
                <router-link
                  to="/privacy-policy-prof"
                  class="error401__modal__text"
                  target="_blank"
                  >Политикой обработки персональных данных 
                </router-link>
              </p>
    
              <BtnCopmonentBgGradient
                    class="error401__modal__form__btn_submit"
                    title="Отправить"
                    methods="formSubmitPay"
                    @formSubmitPay="formSubmit()"
                />
          </div>
        </form>

        <div class="error401__modal__info" v-if="!toggleRadio && successForm">
          <p class="error401__modal__info__title">Спасибо за Ваше сообщение, мы ценим Ваш вклад!</p>
          <p class="error401__modal__info__text">Наши менеджеры рассмотрят Ваше обращение и в ближайшее время отправят Вам письменный ответ на почту или свяжутся с Вами по телефону.</p>
        </div>
      </div>
    </div>
</template>
    
<script lang="ts">
  import { defineComponent } from "vue";
  import * as yup from "yup";
  import BtnCopmonentBgGradient from "../btnCopmonent/BtnCopmonentBgGradient.vue";
  
  const formShema = yup.object({
    name: yup.string().required("name required"),
    phone: yup
      .string()
      .required("phone required")
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^([\+]?[7|8][\s-(]?[9][0-9]{2}[\s-)]?)?([\d]{3})[\s-]?([\d]{2})[\s-]?([\d]{2})$/g,
        "phone matches"
      ),
    email: yup.string().required("email required").email("email incorect"),
    message: yup.string().required("message required"),
  });
  
  interface IData {
    disabled: boolean;
    toggleRadio: boolean;
    name: string;
    nameError: boolean;
    phone: string;
    phoneError: boolean;
    email: string;
    emailError: boolean;
    message: string;
    messageError: boolean;
    successForm: boolean;
    val: string;
  }
  
  export default defineComponent({
    name: "CoursesCardsComponentModal",
    components: {
      BtnCopmonentBgGradient,
    },
    props: {
      modal_consuilation: Boolean,
    },
    data(): IData {
      return {
        toggleRadio: true,
        disabled: false,
        name: "",
        nameError: false,
        phone: "",
        phoneError: false,
        email: "",
        emailError: false,
        message: "",
        messageError: false,
        successForm: false,
        val: "",
      };
    },
  
    methods: {
      closeModal() {
        return this.$emit("closeModalBtn");
      },

      toggleForm: function() {
        this.toggleRadio = true;
        this.disabled = false;
      },
      toggleStatus: function() {
        this.toggleRadio = false;
        this.disabled = true;
      },

      getInputClassName: function (): string {
        return this.nameError
          ? "error401__modal__form__name error401__modal__form__input error401__modal__form__error__input"
          : "error401__modal__form__name error401__modal__form__input";
      },
  
      getInputClassPhone: function (): string {
        return this.phoneError
          ? "error401__modal__form__phone error401__modal__form__input error401__modal__form__input__w252 error401__modal__form__error__input "
          : "error401__modal__form__phone error401__modal__form__input error401__modal__form__input__w252";
      },
      getInputClassEmail: function (): string {
        return this.emailError
          ? "error401__modal__form__phone error401__modal__form__input error401__modal__form__input__w252 error401__modal__form__error__input "
          : "error401__modal__form__phone error401__modal__form__input error401__modal__form__input__w252";
      },
      getInputClassMessage: function (): string {
        return this.messageError
          ? "error401__modal__form__message error401__modal__form__input error401__modal__form__error__input "
          : "error401__modal__form__message error401__modal__form__input";
      },
  
      async formSubmit() {
        await formShema
          .validate(
            {
              name: this.name,
              phone: this.phone,
              email: this.email,
              message: this.message,
            },
            { abortEarly: false }
          )
          .then(async () => {
            try {
              this.successForm = true;
              this.toggleRadio = false;
              this.disabled = true;

              this.name = "";
              this.phone = "";
              this.email = "";
              this.message = "";
              this.phoneError = false;
              this.emailError = false;
              this.nameError = false;
              this.messageError = false;
            } catch (err) {
              //
            }
          })
          .catch((e) => {
            e.errors.includes("name required")
              ? (this.nameError = true)
              : (this.nameError = false);
  
            e.errors.includes("phone required") ||
            e.errors.includes("phone matches")
              ? (this.phoneError = true)
              : (this.phoneError = false);
  
            e.errors.includes("email required") ||
            e.errors.includes("email incorect")
              ? (this.emailError = true)
              : (this.emailError = false);
  
            e.errors.includes("message required")
              ? (this.messageError = true)
              : (this.messageError = false);
            this.successForm = false;
          });
      },
    },
  });
  </script> 
    
<style lang="scss" scoped>
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
  
  .fixed-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .error401__modal {
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 90px;
    transform: translate(-50%, 10px);
    background: $color-skills;
    padding: 47px 22px 23px 22px;
    width: 562px;
    border-radius: 24px;
  
    @media (max-width: 585px) {
      max-width: 288px;
      padding: 15px 22px 15px 22px;      
      transform: translate(-50%, 0%);
    }
  
    &__btn_close {
      position: absolute;
      top: 12px;
      right: 12px;
      border: none;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .error401__modal__radio__container{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        @media (max-width: 585px){
          flex-direction: column;
        }
    }
    .error401__modal__radio__text{
      font-family: 'Inter-Regular';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: $color-blue;
  }

    .error401__modal__radio__btn{
      width: 100%;
      border: none;
      border-bottom: 1px solid $color-blue;
      box-sizing: border-box;
      padding: 8px 0 8px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
  }
  .error401__modal__radio__btn:first-child{
    cursor: pointer;
  }
  .error401__modal__radio__btn_active{
    border-bottom: 1px solid $color-hover;
    cursor: pointer;
  }
  .error401__modal__radio__btn_active .error401__modal__radio__text{
    color: $color-hover;
  }

  .error401__modal__form {
    display: flex;
    flex-direction: column;
  
    &__title {
      font-family: "Inter-Medium";
      font-size: 1rem;
      line-height: 1.5rem;
      color: $color-dark-E343D;
      margin-bottom: 5px;
    }
  }
  
  .error401__modal__form__input__wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .error401__modal__form__input {
    padding: 12px 12px 12px 16px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    border-radius: 8px;
    border: 2px solid $color-skills;
    outline: none;
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    color: $color-blue;
    background-color: $color-bg;
  
    &__select {
      background-color: $color-bg;
    }
  
    &__icon {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 12px;
      transition: opacity 0.1s linear;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  
  .error401__modal__form__input:-webkit-autofill,
  .error401__modal__form__input:-webkit-autofill:hover,
  .error401__modal__form__input:-webkit-autofill:focus,
  .error401__modal__form__input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #ffffff inset !important;
  }
  
  .error401__modal__form__input__wrapper .error401__modal__form__input:-webkit-autofill {
    -webkit-text-fill-color: $color-form-text !important;
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    color: $color-form-text;
  }
  
  .error401__modal__form__input__wrapper .error401__modal__form__input:-webkit-autofill:focus {
    -webkit-text-fill-color: $color-form-text !important;
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    color: $color-form-text;
  }
  .error401__modal__form__input:hover {
    border: 2px solid $color-hover;
  }
  .error401__modal__form__input:focus {
    border: 2px solid $color-form-text;
    color: $color-form-text;
  }
  .error401__modal__form__input:active {
    border: 2px solid $color-hover;
  }
  .error401__modal__form__input:focus + .error401__modal__form__input__icon svg path {
    fill: $color-form-text;
  }
  .error401__modal__form__input:active + .error401__modal__form__input__icon svg path {
    fill: $color-hover;
  }
  
  .error401__modal__form__input:-webkit-autofill {
    -webkit-text-fill-color: $color-hover !important;
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
  }
  
  .error401__modal__form__input::placeholder {
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    color: $color-blue;
  }
  
  .error401__modal__form__input:focus::placeholder {
    color: $color-form-text;
  }
  .error401__modal__form__input:active::placeholder {
    color: $color-blue;
  }
  
  .error401__modal__form__error {
    &__input {
      outline: 2px solid $color-primary-red;
    }
  
    &__input:focus {
      outline: none;
      outline-style: solid;
      outline-width: 2px;
    }
  }
  
  .error401__modal__form__wrapper {
    display: flex;
    justify-content: space-between;
  
    @media (max-width: 585px) {
      flex-direction: column;
    }
  }
  
  .error401__modal__form__wrap {
    display: flex;
    flex-direction: column;
    width: 250px;
  
    @media (max-width: 585px) {
        max-width: 244px;    
      }
  }
  
  .error401__modal__form__message {
    margin-bottom: 8px;
    appearance: none;
    resize: none;
  }
  
  .error401__modal__form__message::-webkit-scrollbar {
    display: none;
    height: 200px;
  }
  
  .error401__modal__form__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    @media (max-width: 585px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  .error401__modal__form__error {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  
    &__icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  
    &__text {
      font-family: "Inter-Regular";
      font-size: 1rem;
      line-height: 1.5rem;
      font-style: normal;
      font-weight: 500;
      color: $color-primary-red;
    }
  }
  
  .error401__modal__form__btn_submit {
    padding: 6px 15px;
  
    @media (max-width: 585px) {
      width: 100%;
    }
  }
  
  .error401__modal__form__btn_submit:hover {
    background: $color-hover;
  }
  
  .error401__modal__form__btn_submit:active {
    background: $color-form-text;
  }
  
  .error401__modal__form__btn_submit:disabled {
    background: $color-blue;
  }
  
  .error401__modal__text {
    font-family: "Inter-Regular";
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $color-form-text;
    width: 346px;

    @media (max-width: 585px){
      width: 100%;
      margin-bottom: 16px;
    }
  }
  
.error401__modal__footer{
  display: flex;
  justify-content: space-between;

  @media (max-width: 585px){
    flex-direction: column;
  }
}  

.error401__modal__info{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.error401__modal__info__title {
  font-family: 'Gramatika-Medium';
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
  color: $color-dark-E343D;
  width: 345px;
  margin-bottom: 24px;

  @media (max-width: 585px){
    width: 100%;
  }
}
.error401__modal__info__text {
  font-family: 'Inter-Regular';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: $color-dark-E343D;
}
  </style> 
  