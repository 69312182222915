<template>
    <div>
      <HeadersComponent />
      <Error404Component/>
      <FooterComponent hiddenBtn="true" class="error404__footer"/>
    </div>
  </template>
  
  <script lang="ts">
  import HeadersComponent from "@/components/headers/Headers.vue";
  import Error404Component from "@/components/error404/Error404Component.vue";
  import FooterComponent from "@/components/footer/Footer.vue";

  export default {
    name: "Error404Page",
    components: {
      HeadersComponent,
      Error404Component,
      FooterComponent,
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .error404__footer{
    margin-top: 0px;
  }
  </style>