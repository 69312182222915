<template>
  <div class="freeLearnDescription">
    <FreeLearnDescription />
    <FreeLearnForm title="Пройдите бесплатный курс" />
    <FreeCourseAnswers />
    <StudyGuide />

    <div class="article-wraper">
      <PopularArticles
        title="Еще больше интересного в блоге"
      />
      <ArticleSubscribe
        :btnFontSize="btnFontSize"
        :btnLineHeight="btnLineHeight"
        class="article-subcribe"
      />
      <ChooseCourse
        :btnFontSize="btnFontSize"
        :btnLineHeight="btnLineHeight"
        class="choose-course"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FreeLearnDescription from "./freeLearnDescription/FreeLearnDescription.vue";
import FreeLearnForm from "./freeLearnForm/FreeLearnForm.vue";
import FreeCourseAnswers from "./freeCourseAnswers/FreeCourseAnswers.vue";
import StudyGuide from "./studyGuide/StudyGuide.vue";
import PopularArticles from "@/components/blog/articleComponent/popularArticles/PopularArticles.vue";
import ArticleSubscribe from "@/components/blog/articleComponent/articleSubscribe/ArticleSubscribe.vue";
import ChooseCourse from "@/components/blog/articleComponent/chooseCourse/ChooseCourse.vue";

interface IData {
  btnFontSize: undefined | number;
  btnLineHeight: undefined | number;
}

export default defineComponent({
  name: "FreeLearnComponent",
  components: {
    FreeLearnDescription,
    FreeLearnForm,
    FreeCourseAnswers,
    StudyGuide,
    PopularArticles,
    ArticleSubscribe,
    ChooseCourse,
  },

  data(): IData {
    return {
      btnFontSize: undefined,
      btnLineHeight: undefined,
    };
  },
  created() {
    window.addEventListener("resize", this.getResize);
  },

  mounted() {
    if (innerWidth <= 576) {
      this.btnFontSize = 16;
      this.btnLineHeight = 24;
    }
  },

  methods: {
    getResize() {
      if (innerWidth <= 576) {
        this.btnFontSize = 16;
        this.btnLineHeight = 24;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.freeLearnDescription {
  max-width: 1440px;
  box-sizing: border-box;
  margin: 0 auto;
}

.article-wraper {
  padding: 0 80px;
  box-sizing: border-box;

  @media (max-width: 991px) {
    padding: 0 60px;
  }

  @media (max-width: 576px) {
    padding: 0 16px;
  }
}

.article-subcribe {
  @media (max-width: 991px) {
    margin-top: 32px;
  }

  @media (max-width: 576px) {
    margin-top: 24px;
  }
}

.choose-course {
  margin-top: 224px;

  @media (max-width: 991px) {
    margin-top: 91px;
  }

  @media (max-width: 576px) {
    margin-top: 48px;
  }
}
</style>
6 