<template>
    <section class="courses__tags">
        <div class="courses__tags__container">
            <p class="courses__tags__subtitle">Все курсы</p>
            <ul class="courses__tags__list">
                <li class="courses__tags__item">
                    <router-link class="courses__tags__link" to="/courses#all-courses">Курсы</router-link>
                </li>
                <li class="courses__tags__item">
                    <router-link class="courses__tags__link" to="/courses#form-consultation">Поможем с выбором</router-link>
                </li>
                <li class="courses__tags__item">
                    <router-link class="courses__tags__link" to="/courses#corporate">Корпоративным клиентам</router-link>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    name: 'CoursesTagsComponent',
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

.courses__tags {
    position: fixed;
    top: 73px;
    z-index: 10;
    width: 100%;
    background-color: $color-bg;

    @media (max-width: 991px) {
        top: 52px;
    }

    @media (max-width: 576px) {
        top: 44px;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        max-width: 1280px;
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 15px;

        @media (max-width: 991px) {
            padding-left: 60px;
            padding-right: 0;
        }

        @media (max-width: 576px) {
            padding-left: 16px;
            top: 44px;
        }
    }

    &__subtitle {
        font-family: 'Gramatika-Medium';
        font-size: 1rem;
        line-height: 1.5rem;
        margin-top: -4px;
        color: $color-blue;
        white-space: nowrap;
        margin-right: 120px;

        @media (max-width: 576px) {
            margin-right: 24px;
        }
    }

    &__list {
        overflow-x: scroll !important;
        overflow-y: hidden;
        display: flex;
        gap: 16px;
        flex-direction: row;
        flex-wrap: nowrap;

        @media (max-width: 576px) {
            gap: 0;
            padding-left: 24px;
        }
    }

    &__link {
        font-family: 'Inter-Medium';
        font-size: 1rem;
        line-height: 1.5rem;
        color: $color-form-text;
        text-decoration: none;
        padding: 8px 16px;
        white-space: nowrap;
        cursor: pointer;
    }

    &__item:hover,
    &__item:hover &__link {
        color: $color-hover;
        border-bottom: 2px solid $color-hover;
    }

    &__item:active,
    &__item:active &__link {
        color: $color-form-text;
        border-bottom: 2px solid $color-form-text;
    }

    &__item {
        list-style-type: none;

    }

    &__link:not(:first-child) {
        margin-left: -1px;
        border-left: 1px solid black;
    }

}
</style>