
<template>
  <section class="free-learn__consultaion__container">
    <h2 class="free-learn__consultaion__title">{{ title }}</h2>
    <div class="free-learn__form__wrapper">
      <div class="free-learn__form__block">
        <form class="form">
          <label class="free-learn__form__label" for="author-name"
            >Как Вас зовут?</label
          >
          <div class="free-learn__form__input__wrapper">
            <input
              type="text"
              :class="getInputClass('name')"
              name="name"
              v-model="name"
              id="author-name"
              placeholder="Имя"
            />
            <span class="free-learn__form__input__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="25"
                viewBox="0 0 22 25"
                fill="none"
              >
                <path
                  d="M11.0047 13.3977C14.4333 13.3977 17.236 10.595 17.236 7.16636C17.236 3.73777 14.4333 0.935059 11.0047 0.935059C7.57609 0.935059 4.77344 3.73777 4.77344 7.16636C4.77344 10.595 7.57615 13.3977 11.0047 13.3977Z"
                  fill="#8698B2"
                />
                <path
                  d="M21.6948 18.3771C21.5315 17.9689 21.3139 17.588 21.069 17.2342C19.8173 15.3839 17.8853 14.1594 15.7085 13.8601C15.4364 13.8329 15.137 13.8872 14.9193 14.0505C13.7765 14.8941 12.416 15.3294 11.001 15.3294C9.58595 15.3294 8.22543 14.8941 7.08257 14.0505C6.86486 13.8872 6.56554 13.8056 6.29345 13.8601C4.11658 14.1594 2.15741 15.3839 0.932931 17.2342C0.688035 17.588 0.470327 17.9962 0.307098 18.3771C0.225483 18.5404 0.252671 18.7308 0.334285 18.8941C0.551993 19.2751 0.824077 19.656 1.06897 19.9826C1.44991 20.4996 1.85809 20.9621 2.32069 21.3975C2.70163 21.7784 3.13699 22.1322 3.57241 22.4859C5.72205 24.0914 8.3071 24.9349 10.9738 24.9349C13.6404 24.9349 16.2255 24.0914 18.3751 22.4859C18.8105 22.1594 19.2458 21.7784 19.6268 21.3975C20.0622 20.9621 20.4976 20.4995 20.8786 19.9826C21.1506 19.6288 21.3956 19.2751 21.6132 18.8941C21.7492 18.7308 21.7764 18.5403 21.6948 18.3771Z"
                  fill="#8698B2"
                />
              </svg>
            </span>
          </div>

          <div class="free-learn__form__error" v-if="nameError">
            <span class="free-learn__form__icon__error">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
                  fill="#CC6666"
                />
              </svg>
            </span>
            <p class="free-learn__form__text__error">
              Поле заполнено некорректно
            </p>
          </div>

          <label class="free-learn__form__label" for="user-phone"
            >Телефон</label
          >
          <div class="free-learn__form__input__wrapper">
            <input
              type="tel"
              :class="getInputClass('phone')"
              name="phone"
              v-model="phone"
              id="user-phone"
              placeholder="Телефон"
            />
            <span class="free-learn__form__input__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M23.345 17.6136L19.9958 14.2643C18.7996 13.0681 16.7661 13.5467 16.2876 15.1016C15.9288 16.1782 14.7326 16.7763 13.6561 16.537C11.2637 15.9389 8.03407 12.8289 7.43598 10.317C7.07713 9.24035 7.79483 8.04418 8.87138 7.68538C10.4264 7.20691 10.9049 5.17343 9.7087 3.97726L6.35943 0.627988C5.4025 -0.209329 3.96709 -0.209329 3.12978 0.627988L0.857056 2.90071C-1.41566 5.29305 1.09629 11.6327 6.71828 17.2547C12.3403 22.8767 18.68 25.5083 21.0723 23.116L23.345 20.8432C24.1824 19.8863 24.1824 18.4509 23.345 17.6136Z"
                  fill="#8698B2"
                />
              </svg>
            </span>
          </div>

          <div class="free-learn__form__error" v-if="phoneError">
            <span class="free-learn__form__icon__error">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
                  fill="#CC6666"
                />
              </svg>
            </span>
            <p class="free-learn__form__text__error">
              Поле заполнено некорректно
            </p>
          </div>

          <label class="free-learn__form__label" for="user-email">E-mail</label>
          <div class="free-learn__form__input__wrapper">
            <input
              type="email"
              :class="getInputClass('email')"
              name="email"
              v-model="email"
              id="user-email"
              placeholder="example@mail.ru"
            />
            <span class="free-learn__form__input__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
              >
                <path
                  d="M14.0057 11.7031C13.4086 12.1012 12.7151 12.3116 12 12.3116C11.285 12.3116 10.5914 12.1012 9.99431 11.7031L0.159797 5.14655C0.105234 5.11017 0.0520781 5.07225 0 5.03325V15.7769C0 17.0086 0.999609 17.9862 2.20936 17.9862H21.7906C23.0224 17.9862 24 16.9866 24 15.7769V5.0332C23.9478 5.0723 23.8945 5.11031 23.8398 5.14673L14.0057 11.7031Z"
                  fill="#8698B2"
                />
                <path
                  d="M0.939844 3.97697L10.7744 10.5336C11.1466 10.7818 11.5733 10.9058 12 10.9058C12.4267 10.9058 12.8534 10.7817 13.2256 10.5336L23.0602 3.97697C23.6487 3.58486 24 2.92861 24 2.22033C24 1.00247 23.0092 0.0117188 21.7914 0.0117188H2.20861C0.990797 0.0117656 0 1.00252 0 2.2215C0 2.92861 0.351375 3.58486 0.939844 3.97697Z"
                  fill="#8698B2"
                />
              </svg>
            </span>
          </div>

          <div class="free-learn__form__error" v-if="emailError">
            <span class="free-learn__form__icon__error">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
                  fill="#CC6666"
                />
              </svg>
            </span>
            <p class="free-learn__form__text__error">
              Поле заполнено некорректно
            </p>
          </div>

          <div class="form-privacy-policy">
            <p class="form-privacy-policy-text">
              Нажимая кнопку “Заказать консультацию” вы соглашаетесь с политикой
              <router-link
                to="/privacy-policy-prof"
                class="form-privacy-policy-link"
                target="_blank"
                >обработки персональных данных</router-link
              >
            </p>
          </div>
          <BtnCopmonentBgGradient
            class="free-learn__form__btn"
            title="Получить бесплатный доступ"
            methods="getFreelearn"
            fontSize="16"
            lineHeight="24"
            @getFreelearn="formSubmit()"
          />
        </form>
        <div class="free-learn__form__description">
          <img
            src="@/assets/icons/freeLearn/free-learn-form.svg"
            alt="icon"
            class="free-learn__form__description__img"
          />
          <p class="free-learn__form__description__text">
            Для прохождения бесплатного курса не требуется специализированного
            оборудования, достаточно компьютера и устойчивого
            интернет-соединения
          </p>
        </div>
      </div>

      <p v-if="successForm" class="form__success__text">
        Запрос отправлен, наш менеджер скоро свяжется с Вами
      </p>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BtnCopmonentBgGradient from "../../../components/btnCopmonent/BtnCopmonentBgGradient.vue";
import * as yup from "yup";
import { v4 as uuid } from "uuid";

interface IData {
  name: string;
  phone: string;
  email: string;
  nameError: boolean;
  phoneError: boolean;
  emailError: boolean;
  successForm: boolean;
}

const formShema = yup.object({
  name: yup.string().required("name required"),
  phone: yup
    .string()
    .required("phone required")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^([\+]?[7|8][\s-(]?[9][0-9]{2}[\s-)]?)?([\d]{3})[\s-]?([\d]{2})[\s-]?([\d]{2})$/g,
      "phone matches"
    ),
  email: yup.string().required("email required").email("email incorect"),
});

export default defineComponent({
  name: "FreeLearnForm",
  components: {
    BtnCopmonentBgGradient,
  },
  props: { title: String },
  data(): IData {
    return {
      name: "",
      phone: "",
      email: "",
      nameError: false,
      phoneError: false,
      emailError: false,
      successForm: false,
    };
  },
  methods: {
    async formSubmit() {
      await formShema
        .validate(
          { name: this.name, phone: this.phone, email: this.email },
          { abortEarly: false }
        )
        .then(async () => {
          try {
            this.successForm = true;
            this.name = "";
            this.phone = "";
            this.email = "";
            this.phoneError = false;
            this.emailError = false;
            this.nameError = false;
          } catch (err) {
            //
          }
        })
        .catch((e) => {
          e.errors.includes("name required")
            ? (this.nameError = true)
            : (this.nameError = false);

          e.errors.includes("phone required") ||
          e.errors.includes("phone matches")
            ? (this.phoneError = true)
            : (this.phoneError = false);

          e.errors.includes("email required") ||
          e.errors.includes("email incorect")
            ? (this.emailError = true)
            : (this.emailError = false);

          this.successForm = false;
        });
    },
    keyId(index: number): string {
      return index + uuid();
    },
    getInputClass: function (atrr: string): string {
      let classInput = "free-learn__form__input";
      if (atrr == "phone" && this.phoneError) {
        classInput = "free-learn__form__input free-learn__form__input__error";
      }

      if (atrr == "name" && this.nameError) {
        classInput = "free-learn__form__input free-learn__form__input__error";
      }

      if (atrr == "email" && this.emailError) {
        classInput = "free-learn__form__input free-learn__form__input__error";
      }

      return classInput;
    },
  },
});
</script>

<style lang="scss" scoped>
.free-learn__consultaion__container {
  box-sizing: border-box;
  max-width: 1440px;
  padding: 100px 80px 0 80px;
  background-color: #2e343d;
  display: flex;
  gap: 48px;
  flex-direction: column;

  @media (max-width: 991px) {
    padding: 60px 60px 0 60px;
    gap: 32px;
  }

  @media (max-width: 576px) {
    padding: 36px 16px 0px 16px;
    gap: 24px;
  }
}

.free-learn__consultaion__title {
  font-family: "Gramatika-Medium";
  font-size: 3rem;
  font-weight: 400;
  line-height: 4.5rem;
  color: $color-dark-E343D;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 631px;

  @media (max-width: 991px) {
    font-size: 2.25rem;
    line-height: 3.375rem;
    max-width: 473px;
  }

  @media (max-width: 576px) {
    font-size: 1.75rem;
    line-height: 2.625rem;
  }
}

.free-learn__form__wrapper {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.free-learn__form__block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 80px;

  @media (max-width: 991px) {
    gap: 24px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 39%;
  padding: 24px;

  @media (max-width: 1280px) {
    width: 46%;
    padding: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 576px) {
    padding: 24px 0px;
  }
}

.free-learn__form__input__wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  @media (max-width: 576px) {
    width: 100%;
  }
}

.free-learn__form__input__wrapper:nth-last-child(2) {
  margin-bottom: 30px;

  @media (max-width: 576px) {
    margin-bottom: 48px;
  }
}

.free-learn__form__label {
  display: block;
  font-family: "Inter-Regular";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  color: $color-text-blue;
  margin-bottom: 9px;
}

.free-learn__form__input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 48px;
  background-color: #434c59;
  border: none;
  outline: none;
  border-radius: 8px;
  color: $color-text-blue;
  padding-left: 16px;
}

.free-learn__form__input__error {
  border: 2px solid $color-primary-red;
}

.free-learn__form__input__error:focus {
  border: none;
}

.free-learn__form__input:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-color: $color-text-blue;
}

.free-learn__form__input::placeholder {
  color: $color-text-input;
  font-family: "Inter-Regular";
  font-size: 1rem;
  line-height: 1.5rem;
}

.free-learn__form__input:focus::placeholder {
  color: $color-text-blue;
}

.free-learn__form__input__icon {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 11px;
  transition: opacity 0.1s linear;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.free-learn__form__input:-webkit-autofill,
.free-learn__form__input:-webkit-autofill:hover,
.free-learn__form__input:-webkit-autofill:focus,
.free-learn__form__input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #545f6f inset !important;
}

.free-learn__form__input__wrapper .free-learn__form__input:-webkit-autofill {
  -webkit-text-fill-color: #c1dcff !important;
  font-family: "Inter-Regular";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  opacity: 0.5;
}

.free-learn__form__input__wrapper
  .free-learn__form__input:-webkit-autofill:focus {
  -webkit-text-fill-color: #c1dcff !important;
  font-family: "Inter-Regular";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  opacity: 1;
}

.free-learn__form__input:focus + .free-learn__form__input__icon svg path {
  fill: $color-blue;
}

.free-learn__form__input:focus + .free-learn__form__input__icon svg path {
  fill: $color-blue;
}

.free-learn__form__input:focus + .free-learn__form__input__icon svg path {
  fill: $color-blue;
}

.free-learn__form__btn {
  box-sizing: border-box;
  width: 268px;
  padding: 8px 12px;
}

.free-learn__form__description {
  display: flex;
  flex-direction: column;
  width: 52%;
  gap: 48px;

  @media (max-width: 991px) {
    width: 47%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.free-learn__form__description__img {
  width: 160px;
  height: 160px;

  @media (max-width: 991px) {
    align-self: center;
  }

  @media (max-width: 576px) {
    align-self: flex-start;
  }
}

.free-learn__form__description__text {
  color: $color-text;
  font-family: Inter-Medium;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;

  @media (max-width: 991px) {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  @media (max-width: 576px) {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.875rem;
  }
}

.free-learn__form__error {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.free-learn__form__icon__error {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.free-learn__form__text__error {
  font-family: "Inter-Regular";
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 500;
  color: $color-primary-red;
}

.form__success__text {
  margin-top: 30px;
  font-family: "Inter-Regular";
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 500;
  color: $color-text;
}

.form-privacy-policy {
  margin-bottom: 32px;
}

.form-privacy-policy-text,
.form-privacy-policy-link {
  color: $color-blue;
  font-family: Inter-Regular;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
</style>