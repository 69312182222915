<template>
  <buttton @click="edit()" class="btn__component__bg__gradient"
    ><span :style="{ fontSize: getFontSize, lineHeight: getLineHeight }"
      >{{ title }}
    </span></buttton
  >
</template>

<script  lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BtnCopmonentBgGradient",
  props: {
    title: String,
    methods: String || undefined,
    fontSize: String || undefined,
    lineHeight: String || undefined,
  },
  methods: {
    edit(): void {
      switch (this.methods) {
        case "formSubmit":
          this.$emit("formSubmit");
          break;
        case "formSubmitLogin":
          this.$emit("formSubmitLogin");
          break;
        case "formSubmitPay":
          this.$emit("formSubmitPay");
          break;
        case "formSubmiPayConsultation":
          this.$emit("formSubmiPayConsultation");
          break;
        case "formSubmitsubscribe":
          this.$emit("formSubmitsubscribe");
          break;
        case "linkToCourse":
          this.$emit("linkToCourse");
          break;
        case "showModalCourse":
          this.$emit("showModalCourse");
          break;
        case "getFreelearn":
          this.$emit("getFreelearn");
          break;
        case "getEducationMaterial1":
          this.$emit("getEducationMaterial1");
          break;
        case "getEducationMaterial2":
          this.$emit("getEducationMaterial2");
          break;
      }
    },
  },
  computed: {
    getFontSize(): string {
      return this.fontSize + "px";
    },
    getLineHeight(): string {
      return this.lineHeight + "px";
    },
  },
});
</script>

<style lang="scss" scoped>
.btn__component__bg__gradient {
  box-sizing: border-box;
  background: linear-gradient(135deg, #7277ff 0%, #ff5a5a 100%) border-box;
  padding: 6px 0px;
  border: 2px solid transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;
  user-select: none;
  cursor: pointer;

  @media (max-width: 576px) {
    padding: 4px 0px;
  }

  span {
    font-family: "Inter-Medium";
    font-size: 1rem;
    line-height: 1.5rem;
    color: $color-text;

    @media (max-width: 576px) {
      font-family: "Inter-Regular";
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
    }
  }
}

.btn__component__bg__gradient:hover {
  background: $color-hover;
}

.btn__component__bg__gradient:active {
  background: $color-form-text;
}

.btn__component__bg__gradient:disabled {
  background: $color-blue;
}
</style>