<template>
  <Loader :color="color" :size="size" class="loader"></Loader>
</template>

<script>
import Loader from "vue-spinner/src/FadeLoader.vue";

export default {
  name: "LoaderComponent",
  components: {
    Loader,
  },
  data() {
    return {
      color: "#7277FF",
      size: "24",
    };
  },
};
</script>

<style lang="scss" scoped>
.loader{
    margin: 150px auto;
    width: 50px;
    height: 200px;
}
</style>