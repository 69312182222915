<template>
    <section class="center__about">
        <div class="center__about__container">
            <h2 class="center__about__title">О нас</h2>
            <p class="center__about__text center__about__text_up">Фреон — инновационный проект от компании «Верконт-сервис».</p>
            <p class="center__about__text center__about__text_down center__about__text_desktop">
                Наша цель — дать возможность каждому получить актуальную рабочую профессию в области климатического и холодильного оборудования и пройти полноценную практику не выходя из дома.</p>
            <BtnCopmonentArrow
                class="center__about__btn"
                title="Начать учиться бесплатно"
                width_arrow="25"
                methods="linkToFreeLearn"
                @linkToFreeLearn="linkToFreeLearn()"
            />
        </div>
        <div class="center__about__container_phone">
            <p class="center__about__text center__about__text_down">
                Наша цель — дать возможность каждому получить актуальную рабочую профессию в области климатического и холодильного оборудования и пройти полноценную практику не выходя из дома.</p>
            <BtnCopmonentArrow
                class="center__about__btn_phone"
                title="Начать учиться бесплатно"
                width_arrow="25"
                methods="linkToFreeLearn"
                @linkToFreeLearn="linkToFreeLearn()"
            />
        </div>
    </section>
</template>

<script>
import BtnCopmonentArrow from "@/components/btnCopmonent/BtnCopmonentArrow.vue";

export default {
  name: 'CenterAboutComponent',
  components: {
    BtnCopmonentArrow,
  },
  methods: {
    linkToFreeLearn() {
      this.$router.push({ name: "free-learn" });
    },
  },
};
</script>

<style lang="scss" scoped>
.center__about {
    margin: 72px auto 0px auto;
    box-sizing: border-box;
    max-width: 1440px;
  
    @media (max-width: 991px) {
      margin-top: 44px;
    }
}

.center__about__container{
    background-image: url('@/assets/images/center/about-background.png');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;

    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 122px;
  
    @media (max-width: 991px) {
      margin-top: 44px;
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 50px;
    }
  
    @media (max-width: 576px) {
      background-image: url('@/assets/images/center/about-background-phone.png');
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 200px;
    }

}
.center__about__title {
    padding-top: 102px;
    font-family: 'Gramatika-Medium';
    font-size: 4rem;
    font-weight: 400;
    line-height: 6rem;
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
    width: 175px;
    margin-bottom: 23px;

    @media (max-width: 991px){
        font-size: 2rem;
        line-height: 3rem;
        width: 90px;
        padding-top: 35px;
        margin-bottom: 16px;
    }

    @media (max-width: 576px){
        font-size: 1.75rem;
        line-height: 2.625rem;
        padding-top: 15px;
        margin-bottom: 8px;
    }
}
.center__about__text {
    font-family: 'Inter-Medium';
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.875rem;
    color: $color-dark-E343D;

    @media (max-width: 991px){
        font-size: 1rem;
        line-height: 1.875rem;
    }

    @media (max-width: 576px){
        line-height: 1.5rem;
    }

    &_up{
        margin-bottom: 52px;
        width: 527px;

        @media (max-width: 991px){
            width: 274px;
            margin-bottom: 32px;
        }

        @media (max-width: 576px){
            width: 210px;
        }
    }

    &_down{
        margin-left: 113px;
        margin-bottom: 32px;
        max-width: 460px;

        @media (max-width: 991px){
            margin-left: 0px;
            max-width: 345px;
        }

        @media (max-width: 576px){
            margin-top: 17px;
            margin-bottom: 18px;
            max-width: 100%;
        }
    }
}
.center__about__btn,
.center__about__btn_phone {
    margin-left: 113px;
    background-color: transparent;
    font-size: 1.25rem;
    line-height: 1.875rem;

    @media (max-width: 991px){
        margin-left: 0px;
    }

    @media (max-width: 576px){
        font-size: 1rem;
        line-height: 1.5rem;    
    }
}

.center__about__text_desktop,
.center__about__btn{
    @media (max-width: 576px){
        visibility: hidden;
        height: 0px;
    }
}

.center__about__container_phone{
    @media (min-width: 577px){
        display: none;
    }

    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;

}
</style>