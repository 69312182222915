<template>
  <section class="main__presentation">
    <div class="main__slider" :style="getBgUrl()">
      <div class="main__slider__content">
        <h1 class="main__slider__title">{{ dataSkills[index].title }}</h1>
        <h2 class="main__slider__text">{{ dataSkills[index].description }}</h2>
        <div class="main__slider__arrow__wraper">
          <button
            class="main__slider__arrow main__slider__arrow__left"
            @click="prev()"
          >
            <span class="main__slider__arrow__left__icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M19 24L11 16L19 8"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>
          <button
            class="main__slider__arrow main__slider__arrow__right"
            @click="next"
          >
            <span class="main__slider__arrow__right__icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M13 24L21 16L13 8"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
        <BtnCopmonentBgGradient
          class="main__slider__btn"
          title="Выбрать курс"
          methods="linkToCourse"
          @linkToCourse="linkToCourse()"
        />
      </div>

      <div :class="changeBg()">
        <button
          :class="[index == i ? itcActiveClass : itcClass]"
          data-slide-to="0"
          v-for="(item, i) in dataSkills"
          :key="item.img"
        ></button>
      </div>
    </div>
  </section>
</template>

<script lang="ts" >
import { defineComponent } from "vue";
import BtnCopmonentBgGradient from "../../btnCopmonent/BtnCopmonentBgGradient.vue";
import store from "@/store";

export default defineComponent({
  name: "MainPresentationComponent",
  components: {
    BtnCopmonentBgGradient,
  },
  data() {
    return {
      dataSkills: [
        {
          title: "Откройте новую реальность обучения!",
          description:
            "Станьте мастером в ремонте и обслуживании климатического и холодильного оборудования с нашими VR-уроками, созданными с максимальным приближением к реальности.",
          img: "slide1",
        },
        {
          title: "Рабочие профессии с VR-технологиями!",
          description:
            "Наша передовая цифровая технология интегрирована в процесс образования, экономит время и снижает затраты на обучение. Присоединяйтесь к нам и станьте экспертом в своей области!",
          img: "slide2",
        },
        {
          title: "Обучение рабочим профессиям с нуля",
          description:
            "Бесплатные уроки по ремонту и обслуживанию климатического и холодильного оборудования. Начни учиться уже сегодня!",
          img: "slide3",
        },
      ],
      index: 0,
      itcClass: "itc-slider-indicator",
      itcActiveClass: "itc-slider-indicator itc-slider-indicator_active",
      checkScrollBg: false,
    };
  },

  methods: {
    next() {
      this.index >= this.dataSkills.length - 1
        ? (this.index = 0)
        : (this.index += 1);
    },
    prev() {
      this.index === 0
        ? (this.index = this.dataSkills.length - 1)
        : (this.index -= 1);
    },
    getBgUrl() {
      return {
        backgroundImage: `url(${require("@/assets/images/slider/" +
          this.dataSkills[this.index].img +
          ".jpg")})`,
      };
    },

    getUrl() {
      return require("@/assets/images/slider/" +
        this.dataSkills[this.index].img +
        ".jpg");
    },
    changeBg() {
      return store.getters.getCheckScrollBg
        ? "itc-slider itc-slider__bg"
        : "itc-slider";
    },
    linkToCourse(): void {
      this.$router.push({ name: "courses" });
    },
  },
});
</script>

<style lang="scss" scoped>
.main__presentation {
  max-width: 1440px;
  height: 694px;
  display: flex;
  justify-content: space-between;
  margin: 88px auto 0 auto;

  @media (max-width: 991px) {
    margin-top: 76px;
    height: 348px;
  }

  @media (max-width: 576px) {
    margin-top: 80px;
    height: 500px;
  }

  @media (max-width: 480px) {
    height: 550px;
  }

  @media (max-width: 390px) {
    height: 570px;
  }

  @media (max-width: 360px) {
    height: 672px;
  }
}

.main__slider {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-size: cover;
  background-position: center;
  padding: 208px 0 0 188px;
  transition: background-image ease-out 1s;

  @media (max-width: 991px) {
    padding: 30px 0 0 143px;
  }

  @media (max-width: 991px) {
    padding: 30px 0 0 143px;
  }

  @media (max-width: 640px) {
    padding: 30px 0 0 100px;
  }

  @media (max-width: 576px) {
    padding: 31px 16px 0 16px;
  }
}

.main__slider::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: black;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.58) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  @media (max-width: 991px) {
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.main__slider__arrow__wraper {
  @media (max-width: 576px) {
    position: absolute;
    bottom: 127px;
    display: flex;
    justify-content: space-between;
    margin: 0 21%;
    width: 50%;
  }
}

.main__slider__arrow {
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 991px) {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 576px) {
    position: relative;
    width: 48px;
    height: 48px;
  }
}

.main__slider__arrow:hover {
  background: rgba(255, 255, 255, 0.4);
}

.main__slider__arrow__left {
  top: 348px;
  left: 76px;

  @media (max-width: 991px) {
    top: 142px;
    left: 60px;
  }

  @media (max-width: 640px) {
    left: 40px;
  }

  @media (max-width: 576px) {
    top: 0;
    left: 0;
  }
}

.main__slider__arrow__right {
  top: 348px;
  right: 84px;

  @media (max-width: 991px) {
    top: 142px;
    right: 60px;
  }

  @media (max-width: 640px) {
    right: 40px;
  }

  @media (max-width: 576px) {
    top: 0;
    left: 0;
  }
}

.main__slider__arrow__left__icons {
  width: 30px;
  height: 30px;
}

.main__slider__arrow__right__icons {
  width: 30px;
  height: 30px;
}

.main__slider__content {
  display: flex;
  flex-direction: column;
  width: 524px;

  @media (max-width: 991px) {
    width: 382px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
}

.main__slider__title {
  color: $color-text;
  font-family: "Gramatika-Medium";
  font-size: 3rem;
  font-weight: 400;
  line-height: 4.5rem;
  width: 100%;

  @media (max-width: 991px) {
    font-size: 2rem;
    line-height: 3rem;
  }

  @media (max-width: 576px) {
    text-align: center;
  }
}

.main__slider__text {
  margin-top: 28px;
  font-family: "Inter-Regular";
  color: $color-text;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 100px;

  @media (max-width: 991px) {
    margin-top: 15px;
    font-size: 1rem;
    line-height: 1.5rem;
    min-height: 140px;
  }

  @media (max-width: 576px) {
    margin-top: 32px;
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-align: center;
  }
}

.main__slider__btn {
  width: 131px;

  @media (max-width: 576px) {
    position: absolute;
    bottom: 54px;
    box-sizing: border-box;
    width: 291px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.itc-slider {
  position: absolute;
  right: 0;
  bottom: -10px;
  display: flex;
  padding: 25px 80px 25px 24px;
  background: $color-bg;
  border-top-left-radius: 25px;

  @media (max-width: 991px) {
    padding: 16px 60px 13px 18px;
    border-top-left-radius: 15px;
    height: 13px;
    bottom: 0px;
  }

  @media (max-width: 767px) {
    padding: 16px 30px 13px 18px;
  }

  @media (max-width: 576px) {
    padding: 23px 15px 13px 18px;
    border-top-left-radius: 15px;
    height: 5px;
  }
}

.itc-slider__bg {
  background: $color-dark-E343D;
}

.itc-slider::after {
  content: "";
  position: absolute;
  left: -23px;
  bottom: 8px;
  width: 25px;
  height: 25px;
  background-image: url("@/assets/icons/button-angle-white.svg");
  transform: rotate(90deg);

  @media (max-width: 991px) {
    left: -23px;
    bottom: -1px;
  }
}

.itc-slider__bg::after {
  content: "";
  position: absolute;
  z-index: 10;
  left: -23px;
  bottom: 8px;
  width: 25px;
  height: 25px;
  background-image: url("@/assets/icons/button-angle.svg");
  transform: rotate(90deg);

  @media (max-width: 991px) {
    left: -23px;
    bottom: -1px;
  }
}

.itc-slider-indicator {
  width: 80px;
  height: 4px;
  margin-right: 16px;
  border: none;
  background-color: #c1dcff;

  @media (max-width: 991px) {
    width: 72px;
    margin-right: 8px;
  }

  @media (max-width: 576px) {
    width: 40px;
    margin-right: 8px;
  }
}

.itc-slider-indicator:nth-last-child(1) {
  margin-right: 0;
}

.itc-slider-indicator_active {
  background: $color-gradient;
}
</style>