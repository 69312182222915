<template>
  <section class="courses__tryfree">
    <h1 class="courses__tryfree__title">
      Дистанционные курсы с практикой в VR
    </h1>
    <div>
      <p class="courses__tryfree__text">
        Практическая часть включает использование математического и
        имитационного моделирования климатических систем с помощью технологий
        машинного интеллекта и виртуальной реальности.
      </p>
      <BtnCopmonentArrow
        class="courses__tryfree__btn_arrow"
        title="Попробовать бесплатно"
        width_arrow="40"
        methods="linkToFreeLearn"
        @linkToFreeLearn="linkToFreeLearn()"
      />
    </div>
  </section>
</template>

<script>
import BtnCopmonentArrow from "@/components/btnCopmonent/BtnCopmonentArrow.vue";

export default {
  name: "CoursesTryFreeComponent",
  components: {
    BtnCopmonentArrow,
  },
  methods: {
    linkToFreeLearn() {
      this.$router.push({ name: "free-learn" });
    },
  },
};
</script>

<style lang="scss" scoped>
.courses__tryfree {
  box-sizing: border-box;
  max-width: 1440px;
  padding-left: 80px;
  padding-right: 80px;
  margin: 176px auto 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (max-width: 576px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  &__title {
    font-family: "Gramatika-Medium";
    font-size: 4rem;
    font-weight: 400;
    line-height: 6rem;
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
    max-width: 710px;

    @media (max-width: 991px) {
      align-self: flex-start;
      font-size: 2rem;
      line-height: 3rem;
      max-width: 100%;
      margin-bottom: 35px;
    }
    @media (max-width: 576px) {
      margin-bottom: 13px;
    }
  }

  &__text {
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    color: $color-dark-E343D;
    max-width: 530px;
    margin-bottom: 48px;
    margin-top: 19px;

    @media (max-width: 991px) {
      max-width: 100%;
      margin-bottom: 24px;
    }
  }

  &__btn_arrow {
    font-family: "Gramatika-Medium";
    font-size: 1.25rem;
    line-height: 1.875rem;
    background-color: $color-bg;
  }
}
</style>