<template>
  <section class="course">
    <div v-if="isLoading">
      <LoaderComponent class="course-loading" />
    </div>
    <div v-else-if="isError">
      <ErrorComponent :error="isError" class="course-error" />
    </div>
    <div v-if="data">
      <CourseNavigation />
      <DescriptionCourse :data="data[0]" />
      <SkillsCourse :data="data[0]?.description" />
      <LearnProcess />
      <ProgramCourse :data="data[0]" />
      <PriceCourse :data="data[0]" />
      <MainFormConsultation
        class="form__consultation"
        title="Не можете определиться с выбором?"
      />
      <ReviewsCourse :reviews="data[0].reviews" />
      <RecommendCourse />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoaderComponent from "@/components/loader/Loader.vue";
import ErrorComponent from "@/components/error/Error.vue";
import CourseNavigation from "./courseNavigation/courseNavigation.vue";
import DescriptionCourse from "./descriptionCourse/DescriptionCourse.vue";
import SkillsCourse from "./skillsCourse/SkillsCourse.vue";
import LearnProcess from "./learnProcessCourse/LearnProcessCourse.vue";
import ProgramCourse from "./programCourse/ProgramCourse.vue";
import PriceCourse from "./priceCourse/PriceCourse.vue";
import MainFormConsultation from "../main/mainFormConsultationComponent/MainFormConsultation.vue";
import ReviewsCourse from "./reviewsCourse/ReviewsCourse.vue";
import RecommendCourse from "./recommendCourse/RecommendCourse.vue";
import ICourse from "@/interface/courses/ICourse";
import http from "@/api/http";

interface IData {
  isLoading: boolean;
  isError: string;
  data: ICourse | undefined;
}

export default defineComponent({
  name: "LearnProcessCourse",
  components: {
    CourseNavigation,
    DescriptionCourse,
    SkillsCourse,
    LearnProcess,
    ProgramCourse,
    PriceCourse,
    MainFormConsultation,
    ReviewsCourse,
    RecommendCourse,
    LoaderComponent,
    ErrorComponent,
  },
  data(): IData {
    return {
      data: undefined,
      isLoading: false,
      isError: "",
    };
  },
  async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const { id } = this.$route.params;
      let url = `courses/${id}/course`;

      try {
        this.isLoading = true;
        this.isError = "";
        this.data = await http.get(url).then((response) => {
          return response.data.data;
        });
      } catch (err: any) {
        this.isError = err.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler() {
        this.getData();
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.course {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 80px;
  box-sizing: border-box;

  @media (max-width: 991px) {
    padding: 0 60px;
  }

  @media (max-width: 576px) {
    padding: 0 16px;
  }
}

.form__consultation {
  padding: 0;
  margin-top: 148px;

  @media (max-width: 991px) {
    margin-top: 64px;
  }
}

.course-loading {
  margin-top: 300px;
}

.course-error {
  margin-top: 150px;
}
</style>