<template>
  <div
    :class="open ? 'faq open' : 'faq'"
    @click="$emit('toggleOpen', index, $event)"
  >
    <div class="faq__question">
      <p class="faq__question__num">{{ faq.num }}</p>
      <p class="faq__question__text">{{ faq.question }}</p>
      <img
        src="@/assets/icons/arrow_normal_faq.svg"
        alt="arrow"
        class="faq__question__arrow"
      />
    </div>
    <div class="faq__answer">
      <p class="faq__answer__text">{{ faq.answer }}</p>
      <BtnCopmonent
        class="faq__answer__btn"
        title="Выбрать курс"
        methods="linkToCourse"
        @linkToCourse="linkToCourse()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BtnCopmonent from "../../../btnCopmonent/BtnCopmonent.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainFaqComponentList",
  props: ["faq", "index", "open"],
  components: {
    BtnCopmonent,
  },
  methods: {
    linkToCourse(): void {
      this.$router.push({ name: "courses" });
    },
  },
});
</script>

<style lang="scss" scoped>
.faq {
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: 23px;

  @media (max-width: 991px) {
    flex-direction: column;
    margin-bottom: 16px;
  }
}

.faq__question {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 29px);

  @media (max-width: 991px) {
    width: 100%;
    position: static;
  }
}

.faq__question__num {
  margin-right: 28px;
  font-family: "Inter-Medium";
  font-size: 1rem;
  font-weight: 600;
  background-image: $color-gradient;
  background-clip: text;
  color: transparent;
}

.faq__question__text {
  color: $color-dark-E343D;
  font-family: "Inter-Medium";
  font-size: 1rem;
  width: 100%;
}
.faq__question__arrow {
  box-sizing: border-box;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid $color-dark-E343D;
  width: 32px;
  height: 32px;
  transition: all 0.6s ease-out;
  cursor: pointer;
}

.faq.open .faq__question__arrow {
  background-color: $color-text-blue;
  border: none;
  transform: translateX(20px);

  @media (max-width: 991px) {
    transform: rotate(90deg);
  }
}

.faq__answer {
  background-color: $color-questions;
  border-radius: 16px;
  padding: 24px;
  position: absolute;
  left: 51%;
  opacity: 0;
  transition: all 0.6s ease-out;

  @media (max-width: 991px) {
    position: static;
    overflow-y: hidden;
    max-height: 0px;
    padding: 0 16px;
    border-radius: 12px;
  }

  /*для кнопки*/
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
}

.faq.open .faq__answer {
  z-index: 2;
  opacity: 1;
  max-height: 1000px;

  @media (max-width: 991px) {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 8px;
  }
}

.faq__answer__text {
  color: $color-dark-E343D;
  font-family: "Inter-Medium";
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 24px;

  @media (max-width: 991px) {
    margin-bottom: 16px;
  }
}

.faq__answer__btn {
  display: inline-block;
  position: relative;
  z-index: 100;
  background: linear-gradient($color-questions, $color-questions) padding-box,
    linear-gradient(135deg, #7277ff 0%, #ff5a5a 100%) border-box;
  position: relative;
  z-index: 100;
  cursor: pointer;

  @media (max-width: 480px) {
    text-align: center;
    padding: 7px;
  }
}

.faq__answer__btn:hover {
  background: linear-gradient($color-questions, $color-questions) padding-box,
    linear-gradient(135deg, #7277ff 0%, #ff5a5a 100%) border-box;
}
</style>