<template>
    <div class="pdf">
        <embed src="
        educational-material1.pdf" type="application/pdf" width="100%" height="100%">


    </div>
</template>

<script lang="ts" >
export default {
    name: 'EducationalMaterial1',
};
</script>

<style lang="scss" scoped>
.pdf {
    width: 100%;
    height: 100vh;
}
</style>