<template>
  <div class="header">
    <div class="header__wraper">
      <div class="header__logo">
        <LogoComponent />
      </div>
      <div
        :class="
          $store.state.closeClickPageMenuHeader
            ? 'header__nav-bar'
            : 'header__nav-bar header__nav-bar-active'
        "
      >
        <nav class="header__list">
          <router-link to="/courses" class="header__item"
            >Все курсы</router-link
          >
          <router-link to="/free-learn" class="header__item"
            >Учиться бесплатно</router-link
          >
          <router-link to="/blog" class="header__item">Блог</router-link>
          <router-link to="/center" class="header__item">О центре</router-link>
          <router-link to="/contact" class="header__item"
            >Контакты</router-link
          >
        </nav>
        <BtnComponent class="header__btn" title="Вход" methods="login" />
      </div>
      <div
        :class="
          $store.state.closeClickPageMenuHeader
            ? 'header__menu'
            : 'header__menu header__menu-close'
        "
        @click="showMenu($event)"
      >
        <div></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BtnComponent from "../btnCopmonent/BtnCopmonent.vue";
import LogoComponent from "../logoComponent/LogoComponent.vue";
import store from "@/store";

export default defineComponent({
  name: "HeadersComponent",
  components: {
    BtnComponent,
    LogoComponent,
  },
  created() {
    this.checkSize();
    window.addEventListener("resize", this.checkSize);
  },
  methods: {
    showMenu(e: Event): void {
      const el = e.target as HTMLDivElement;

      if (
        el.classList.contains("header__menu") ||
        el.parentElement?.classList.contains("header__menu")
      ) {
        store.commit(
          "setCloseClickPageMenuHeader",
          !store.getters.getCloseClickPageMenuHeader
        );
      }
    },
    checkSize() {
      if (innerWidth > 991) {
        store.commit("setCloseClickPageMenuHeader", true);
      }
    },
  },
});
</script>

<style lang="scss" scoped >
.header {
  position: fixed;
  top: 0;
  z-index: 9999;
  background: $color-bg;
  width: 100%;
}

.header__wraper {
  display: flex;
  justify-content: space-between;
  color: $color-dark-E343D;
  max-width: 1280px;
  margin: 0 auto;
  padding: 16px 80px;
  align-items: center;

  @media (max-width: 1280px) {
    padding: 16px 60px;
  }

  @media (max-width: 991px) {
    padding: 12px 60px;
  }

  @media (max-width: 576px) {
    padding: 8px 16px;
  }
}

.header__logo {
  margin-right: 94px;

  @media (max-width: 1280px) {
    margin-right: 40px;
  }

  @media (max-width: 991px) {
    margin-right: 0;
    width: 93px;
    height: 28px;
  }
}

.header__nav-bar {
  width: 1064px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $color-bg;

  @media (max-width: 991px) {
    display: none;
    position: absolute;
    box-sizing: border-box;
    top: 50px;
    right: 0;
    width: 100%;
    padding: 8px 60px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }

  @media (max-width: 567px) {
    padding: 18px 16px 31px 12px;
    top: 44px;
  }
}

.header__nav-bar-active {
  display: flex;
  align-items: flex-start;
}

.header__list {
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.header__item {
  font-family: "Gramatika-Medium";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-decoration: none;
  margin-right: 64px;
  color: $color-dark-E343D;

  &:visited {
    color: $color-dark-E343D;
  }

  &:hover {
    color: $color-hover;
  }

  &.router-link-exact-active {
    color: $color-hover;
  }

  &.router-link-active {
    color: $color-hover;
  }

  @media (max-width: 1280px) {
    margin-right: 50px;
  }

  @media (max-width: 991px) {
    margin-right: 32px;
  }

  @media (max-width: 767px) {
    margin-right: 0;
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 12px;
  }
}

.header__item:nth-last-child(1) {
  margin-right: 0;

  @media (max-width: 991px) {
    margin: 0;
  }
}

.header__btn {
  @media (max-width: 767px) {
    margin-top: 39px;
  }

  @media (max-width: 576px) {
    margin-top: 39px;
    width: 100%;
  }
}

.header__menu {
  width: 24px;
  height: 24px;
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

.header__menu:after,
.header__menu:before,
.header__menu div {
  background-color: $color-dark-E343D;
  border-radius: 3px;
  content: "";
  display: block;
  height: 2px;
  margin: 6px 0;
  transition: all 0.2s ease-in-out;
}

.header__menu-close:after,
.header__menu-close:before,
.header__menu-close div {
  background-color: $color-dark-E343D;
  border-radius: 3px;
  content: "";
  display: block;
  height: 2px;
  margin: 5px 0;
  transition: all 0.2s ease-in-out;
}

.header__menu-close:before {
  transform: translateY(8px) rotate(135deg);
}

.header__menu-close:after {
  transform: translateY(-6px) rotate(-135deg);
}

.header__menu-close div {
  transform: scale(0);
}
</style>