<template>
  <div class="subscribe">
    <h3 class="subscribe-title">
      Подпишитесь на рассылку, чтобы быть в курсе последних новостей и не
      упустить самые выгодные предложения.
    </h3>
    <div class="form__wrapper">
      <form class="form">
        <label class="form__label" for="emal">E-mail</label>
        <div class="form__input__wrapper">
          <input
            type="email"
            :class="getInputClassEmail"
            name="emal"
            v-model="email"
            id="emal"
            placeholder="myemail@email.com"
            @keypress.enter="formSubmit($event)"
          />
          <span class="form__input__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="19"
              viewBox="0 0 24 19"
              fill="none"
            >
              <path
                d="M14.0057 12.6797C13.4086 13.0777 12.7151 13.2881 12 13.2881C11.285 13.2881 10.5914 13.0777 9.99431 12.6797L0.159797 6.12311C0.105234 6.08674 0.0520781 6.04881 0 6.00981V16.7534C0 17.9852 0.999609 18.9628 2.20936 18.9628H21.7906C23.0224 18.9628 24 17.9632 24 16.7534V6.00977C23.9478 6.04886 23.8945 6.08688 23.8398 6.1233L14.0057 12.6797Z"
                fill="#A8BFDC"
              />
              <path
                d="M0.939844 4.95353L10.7744 11.5101C11.1466 11.7583 11.5733 11.8824 12 11.8824C12.4267 11.8824 12.8534 11.7583 13.2256 11.5101L23.0602 4.95353C23.6487 4.56142 24 3.90517 24 3.19689C24 1.97903 23.0092 0.988281 21.7914 0.988281H2.20861C0.990797 0.988328 0 1.97908 0 3.19806C0 3.90517 0.351375 4.56142 0.939844 4.95353Z"
                fill="#A8BFDC"
              />
            </svg>
          </span>

          <div class="form__error" v-if="emailError">
            <span class="form__error__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM12 2C6.47 2 2 6.5 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
                  fill="#CC6666"
                />
              </svg>
            </span>
            <p class="form__error__text">Поле заполнено некорректно</p>
          </div>
          <div class="form__success" v-if="successForm">
            <p class="form__success__text">Вы подписались на рассылку</p>
          </div>
        </div>
        <BtnCopmonentBgGradient
          class="form__btn"
          title="Подписаться"
          methods="formSubmitsubscribe"
          @formSubmitsubscribe="formSubmit($event)"
          :fontSize="btnFontSize"
          :lineHeight="btnLineHeight"
        />
      </form>
      <span class="form-text">
        Нажимая кнопку “Подписаться” вы соглашаетесь с
        <router-link
          to="/privacy-policy-prof"
          class="form-text-link"
          target="_blank"
          >политикой обработки персональных данных</router-link
        >
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BtnCopmonentBgGradient from "@/components/btnCopmonent/BtnCopmonentBgGradient.vue";
import * as yup from "yup";

const formShema = yup.object({
  email: yup.string().required("email required").email("email incorect"),
});

interface IData {
  email: string;
  emailError: boolean;
  successForm: boolean;
}

export default defineComponent({
  name: "ArticleSubscribe",
  components: {
    BtnCopmonentBgGradient,
  },
  data(): IData {
    return {
      email: "",
      emailError: false,
      successForm: false,
    };
  },
  props: {
    btnFontSize: Number,
    btnLineHeight: Number,
  },
  methods: {
    async formSubmit(event: Event) {
      if (event !== undefined) {
        event.preventDefault();
      }

      await formShema
        .validate(
          {
            email: this.email,
          },
          { abortEarly: false }
        )
        .then(async () => {
          try {
            this.successForm = true;
            this.email = "";
            this.emailError = false;
          } catch (err) {
            //
          }
        })
        .catch((e) => {
          e.errors.includes("email required") ||
          e.errors.includes("email incorect")
            ? (this.emailError = true)
            : (this.emailError = false);

          this.successForm = false;
        })
        .finally(() => {
          setTimeout(() => (this.successForm = false), 5000);
        });
    },
  },
  computed: {
    getInputClassEmail: function (): string {
      return this.emailError ? "form__input form__input__error" : "form__input";
    },
  },
});
</script>

<style lang="scss" scoped>
.subscribe {
  margin-top: 16px;
  padding: 24px;
  background-color: $color-card-foto;
  border-radius: 24px;
  gap: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    padding: 16px;
  }

  &-title {
    max-width: 575px;
    background: $color-gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Inter-Medium;
    font-size: 1.25rem;
    line-height: 1.875rem;

    @media (max-width: 991px) {
      max-width: 100%;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
.form {
  display: grid;
  grid-template-rows: 24px minmax(48px, auto);
  grid-template-columns: minmax(300px, 416px) 1fr;
  column-gap: 16px;
  row-gap: 8px;
  grid-template-areas:
    "label label"
    "input  btn";

  @media (max-width: 991px) {
    width: 100%;
    grid-template-columns: minmax(270px, 100%) minmax(140px, 177px);
  }

  @media (max-width: 576px) {
    grid-template-columns: minmax(248px, 100%);
    grid-template-areas:
      "label label"
      "input  input"
      "btn  btn";
  }

  &__wrapper {
    width: 100%;
  }

  &__label {
    grid-area: label;
    color: $color-dark-E343D;
    font-family: Inter-Regular;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__input__wrapper {
    grid-area: input;
    position: relative;
  }

  &__input {
    width: 100%;
    padding: 12px 16px;
    box-sizing: border-box;
    font-family: Inter-Regular;
    font-size: 1rem;
    line-height: 1.5rem;
    border: none;
    color: $color-blue;
    border-radius: 8px;
    outline: none;
    box-sizing: border-box;

    &::placeholder {
      color: $color-blue;
    }

    &:hover {
      outline-style: solid;
      outline-width: 2px;
      outline-color: $color-hover;
    }

    &:hover + .form__input__icon svg path {
      fill: $color-hover;
    }

    &:focus {
      outline-style: solid;
      outline-width: 2px;
      outline-color: $color-form-text;
      color: $color-form-text;
    }

    &:focus + .form__input__icon svg path {
      fill: $color-form-text;
    }

    &:active {
      outline-style: solid;
      outline-width: 2px;
      outline-color: $color-hover;
    }

    &:active + .form__input__icon svg path {
      fill: $color-hover;
    }

    &:focus::placeholder {
      color: $color-form-text;
    }

    &:active::placeholder {
      color: $color-text-blue;
    }

    &__error {
      border: 2px solid $color-primary-red;
    }

    &__error:focus {
      border: none;
      outline-style: solid;
      outline-width: 2px;
      outline-color: $color-text-blue;
    }

    &__icon {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 24px;
      height: 18x;

      &:hover svg path {
        fill: $color-hover;
      }
    }
  }

  &__btn {
    grid-area: btn;
    padding: 8px 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    height: 48px;

    @media (max-width: 576px) {
      margin-top: 8px;
    }
  }

  &-text {
    display: block;
    margin-top: 20px;
    color: $color-dark-E343D;
    font-family: Inter-Regular;
    font-size: 0.75rem;
    line-height: 1.125rem;

    &-link {
      color: $color-dark-E343D;
      font-family: Inter-Regular;
      font-size: 0.75rem;
      line-height: 1.125rem;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &__icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    &__text {
      font-family: "Inter-Regular";
      font-size: 1rem;
      line-height: 1.5rem;
      font-style: normal;
      font-weight: 500;
      color: $color-primary-red;
    }
  }

  &__success {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &__text {
      font-family: "Inter-Regular";
      font-size: 1rem;
      line-height: 1.5rem;
      color: $color-dark-E343D;
    }
  }
}
</style>