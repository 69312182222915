import { render, staticRenderFns } from "./CenterPage.vue?vue&type=template&id=036d375b&scoped=true"
import script from "./CenterPage.vue?vue&type=script&lang=ts"
export * from "./CenterPage.vue?vue&type=script&lang=ts"
import style0 from "./CenterPage.vue?vue&type=style&index=0&id=036d375b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036d375b",
  null
  
)

export default component.exports