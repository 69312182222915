<template>
  <section class="contacts__map__container">
    <yandex-map
    class="contacts__map"
      :coords="[55.703773, 37.654673]"
      zoom="17"
      :controls="['fullscreenControl']"
      map-type="map"
    >
      <ymap-marker
        marker-type="placemark"
        :coords="[55.703773, 37.654673]"
        hint-content="Автозаводская улица, 17к3"
        :balloon="{ body: 'г. Москва, ул. Автозаводская, дом 17, корп. 3, офис 11'}"
        :icon="{ color: 'black'}"
      ></ymap-marker>
    </yandex-map>
  </section>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContactsMapComponent",
  components: { yandexMap, ymapMarker },
  
});
</script>

<style lang="scss" scoped>
.contacts__map__container{
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 63px;
  padding-left: 80px;
  padding-right: 80px;

  @media (max-width: 991px){
      margin-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
  }
}

.contacts__map{
  width: 100%; 
  height: 482px;
}

.contacts__map__container > .contacts__map {
  border-radius: 24px;
  overflow: hidden;

  @media (max-width: 991px){
    border-radius: 0px;
  }
  }
</style>