<template>
  <div class="blog__item" :style="url" @click="linkToCourse(data.id)">
    <div class="blog__item__tags">
      <p
        class="blog__item__tags__item"
        v-if="data.tags[0] !== undefined"
        @click.stop="changeCategory(data.tags[0].id)"
      >
        {{ data.tags[0].name }}
      </p>
      <p
        class="blog__item__tags__item"
        v-if="data.tags[1] !== undefined"
        @click.stop="changeHashTag(data.tags[1].id)"
      >
        {{ data.tags[1].name }}
      </p>
    </div>
    <h3 class="blog__item__title">
      {{ data.name }}
    </h3>
    <p class="blog__item__description">{{ data.preview }}</p>
    <div class="blog__item__footer">
      <div class="blog__item__footer__icons">
        <div class="blog__item__footer__icons__wraper">
          <span class="blog__item__footer__icons__wraper__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.744 22.8888C12.336 23.0371 11.664 23.0371 11.256 22.8888C7.776 21.6652 0 16.5607 0 7.90899C0 4.08989 2.988 1 6.672 1C8.856 1 10.788 2.08764 12 3.76854C12.6165 2.91063 13.4196 2.21337 14.3448 1.7326C15.27 1.25184 16.2917 1.00094 17.328 1C21.012 1 24 4.08989 24 7.90899C24 16.5607 16.224 21.6652 12.744 22.8888Z"
                fill="#E5607A"
              />
            </svg>
          </span>
          <p class="blog__item__footer__icons__wraper__text">
            {{ data.likes || 0 }}
          </p>
        </div>
        <div class="blog__item__footer__icons__wraper">
          <span class="blog__item__footer__icons__wraper__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.5632 11.8275C22.8444 12.1491 23 12.5668 23 13C23 13.4332 22.8444 13.8509 22.5632 14.1725C20.7829 16.15 16.7305 20 12 20C7.26947 20 3.21714 16.15 1.43683 14.1725C1.15565 13.8509 1 13.4332 1 13C1 12.5668 1.15565 12.1491 1.43683 11.8275C3.21714 9.85 7.26947 6 12 6C16.7305 6 20.7829 9.85 22.5632 11.8275ZM12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17ZM14 13C14 14.1046 13.1046 15 12 15C10.8954 15 10 14.1046 10 13C10 11.8954 10.8954 11 12 11C13.1046 11 14 11.8954 14 13Z"
                fill="#7277FF"
              />
            </svg>
          </span>
          <p class="blog__item__footer__icons__wraper__text">
            {{ data.views || 0 }}
          </p>
        </div>
      </div>
      <p class="blog__item__footer__date">{{ data.date_begin }}</p>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import { v4 as uuid } from "uuid";
import IBlog from "@/interface/blog/IBlog";

interface IData {
  data: IBlog;
  url: string;
}

export default defineComponent({
  name: "BlogItem",
  props: ["item"],
  data(): IData {
    return {
      data: this.item,
      url: "",
    };
  },
  methods: {
    keyId(index: number): string {
      return index + uuid();
    },
    changeCategory(id: number) {
      this.$emit("change-сategory", id);
    },
    changeHashTag(id: number) {
      this.$emit("change-hash-tag", id);
    },
    linkToCourse(id: number) {
      this.$router.push({ path: `/blog/article/${id}` });
    },
    getUrl() {
      if (innerWidth > 991) {
        this.url = `backgroundImage: url("https://fsi0622.1t.ws${this.data.preview_img}")`;
      } else {
        this.url = `backgroundImage: url("${this.data.avatar}")`;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.getUrl);
    this.getUrl();
  },
});
</script>

<style lang="scss" scoped>
.blog__item {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  background-image: url("@/assets/images/skills/skill.jpg");
  border-radius: 24px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
  background-size: 30% 100%;
  background-position: right;
  padding: 32px;
  font-family: "Inter-Regular";
  line-height: 1.5rem;
  transition: all 0.9s;
  overflow: hidden;

  @media (max-width: 1280px) {
    background-size: 35% 100%;
  }

  @media (max-width: 1180px) {
    background-size: 40% 100%;
  }

  @media (max-width: 991px) {
    background-size: 100% 100%;
  }

  &:hover {
    background-size: (30% * 1.1) (100% * 1.2);

    @media (max-width: 1280px) {
      background-size: 35 * 1.1 110% * 1.1;
    }

    @media (max-width: 1180px) {
      background-size: 40 * 1.1 110% * 1.1;
    }

    @media (max-width: 991px) {
      background-size: 100% * 1.2 100% * 1.2;
    }

    &::before {
      @media (min-width: 992px) {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-color: rgb(245, 250, 255);
        border-radius: 24px;
        mask-image: linear-gradient(
          to right,
          rgba(245, 250, 255, 1) 72%,
          rgba(239, 239, 240, 0) 95%
        );
      }
    }
  }

  &:hover .blog__item__title {
    background: $color-gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 70%;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: $color-card-foto;
    border-radius: 24px;
    mask-image: linear-gradient(
      to right,
      rgba(239, 239, 240, 1) 72%,
      rgba(239, 239, 240, 0) 72%
    );

    @media (max-width: 991px) {
      border-radius: 16px;
      mask-image: linear-gradient(
        to right,
        rgba(239, 239, 240, 0.87) 71.56%,
        rgba(239, 239, 240, 0) 100%
      );
    }

    @media (max-width: 576px) {
      padding: 16px;
      mask-image: none;
    }
  }
}

.blog__item__tags {
  display: flex;
  gap: 40px;

  &__item {
    line-height: 1.5rem;
    cursor: pointer;
    user-select: none;
  }
}

.blog__item__title {
  color: $color-dark-E343D;
  margin-top: 24px;
  font-family: "Gramatika-Medium";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  max-width: 70%;

  @media (max-width: 991px) {
    max-width: 70%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.blog__item__description {
  color: $color-dark-E343D;
  margin-top: 20px;
  font-size: 1.25rem;
  line-height: 1.875rem;
  max-width: 70%;
  min-height: 120px;

  @media (max-width: 991px) {
    max-width: 70%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    display: none;
  }
}

.blog__item__footer {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 70%;

  @media (max-width: 991px) {
    max-width: 68%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &__icons {
    display: flex;
    gap: 40px;

    @media (max-width: 576px) {
      gap: 10px;
    }

    &__wraper {
      display: flex;
      gap: 10px;
    }
  }
}
</style>