<template>
  <div class="study-guide">
    <div class="study-guide__header">
      <h2 class="study-guide__title">Учебные пособия бесплатно</h2>
      <p class="study-guide__subtitle">
        Получите бесплатные материалы и начните свой путь к профессиональному
        росту и успеху в сфере монтажа и сервисного обслуживания климатического
        оборудования
      </p>
    </div>
    <div class="study-guide__list">
      <div class="study-guide__item">
        <img
          src="../../../assets/images/free-learn/study-guide1.png"
          alt="study-guide"
          class="study-guide__item__img"
        />
        <div class="study-guide__item__content">
          <p class="study-guide__item_-content__text">
            Экологические аспекты, безопасная эксплуатация, сервис и
            обслуживание холодильного оборудования и систем кондиционирования
            воздуха
          </p>
          <BtnCopmonentBgGradient
            class="study-guide__item__content__btn"
            title="Получить учебное пособие"
            methods="getEducationMaterial1"
            fontSize="16"
            lineHeight="24"
            @getEducationMaterial1="getEducationMaterial1()"
          />
        </div>
      </div>
      <div class="study-guide__item">
        <img
          src="../../../assets/images/free-learn/study-guide2.png"
          alt="study-guide"
          class="study-guide__item__img"
        />
        <div class="study-guide__item__content">
          <p class="study-guide__item_-content__text">
            Монтаж систем вентиляции и кондиционирования воздуха
          </p>
          <BtnCopmonentBgGradient
            class="study-guide__item__content__btn"
            title="Получить учебное пособие"
            methods="getEducationMaterial2"
            fontSize="16"
            lineHeight="24"
            @getEducationMaterial2="getEducationMaterial2()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BtnCopmonentBgGradient from "../../../components/btnCopmonent/BtnCopmonentBgGradient.vue";

export default defineComponent({
  name: "StudyGuide",
  components: {
    BtnCopmonentBgGradient,
  },
  data() {
    return {};
  },
  methods: {
    getEducationMaterial1() {
      this.$router.push({ name: "educational-material1" });
    },
    getEducationMaterial2() {
      this.$router.push({ name: "educational-material2" });
    },
  },
});
</script>

<style lang="scss" scoped>
.study-guide {
  display: flex;
  gap: 48px;
  padding: 148px 80px 0 80px;
  flex-direction: column;

  @media (max-width: 991px) {
    padding: 60px 60px 0 60px;
  }

  @media (max-width: 991px) {
    padding: 48px 16px 0 16px;
  }
}

.study-guide__header {
  display: flex;
  gap: 64px;
  align-items: center;

  @media (max-width: 1440px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
}

.study-guide__title {
  font-family: Gramatika-Medium;
  font-size: 3rem;
  font-weight: 400;
  line-height: 4.5rem;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 657px;
  margin-top: -20px;
  flex-shrink: 0;

  @media (max-width: 991px) {
    font-size: 2.25rem;
    line-height: 3.375rem;
    max-width: 493px;
  }

  @media (max-width: 576px) {
    font-size: 1.75rem;
    line-height: 2.625rem;
    align-self: center;
    text-align: center;
  }
}

.study-guide__subtitle {
  color: $color-dark-E343D;
  font-family: Inter-Regular;
  font-size: 1.25rem;
  line-height: 1.875rem;
  max-width: 538px;
  flex-shrink: 2;

  @media (max-width: 1440px) {
    max-width: 100%;
  }
}

.study-guide__list {
  display: flex;
  gap: 24px;
  justify-content: space-between;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }
}

.study-guide__item {
  display: flex;
  gap: 24px;
  padding: 24px;
  border-radius: 24px;
  background: $color-questions;
  box-sizing: border-box;
  max-width: 620px;

  @media (max-width: 1280px) {
    max-width: 100%;
    width: 100%;
  }

  @media (max-width: 691px) {
    flex-direction: column;
  }
}

.study-guide__item__img {
  width: 200px;
  height: 273px;
}

.study-guide__item__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 991px) {
    gap: 24px;
  }
}

.study-guide__item_-content__text {
  color: $color-dark-E343D;
  font-family: Gramatika-Medium;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  margin-top: -6px;

  @media (max-width: 991px) {
    font-size: 1.25rem;
    line-height: 1.875rem;
    gap: 24px;
  }
}

.study-guide__item__content__btn {
  width: 252px;
  padding: 8px 16px;

  @media (max-width: 991px) {
    width: 240px;
    padding: 8px 10px;
  }
}
</style>