<template>
  <section class="main__faq">
    <h2 class="main__faq__title">Часто задаваемые вопросы</h2>
    <MainFaqComponentList 
    v-for="(faq, i) in faqs" 
    :faq="faq" 
    :index="i" 
    :key="i" 
    :open="faq.open"
    @toggleOpen="toggleOpen" />
  </section>
</template>

<script>
import { questions } from './MainFaqData/MainFaqData';
import MainFaqComponentList from './MainFaqComponentList/MainFaqComponentList.vue'

export default {
  name: 'MainFaqComponet',
  components: {
    MainFaqComponentList
  },
  data() {
    return {
      faqs: []
    };
  },
  created() {
    this.faqs = questions;
  },

  methods: {
    toggleOpen: function (index, event) {
      let target = event.target;
      this.faqs = this.faqs.map((faq, i) => {
        if (target.classList.contains('faq__question__arrow')) {
          if (index === i) {
            faq.open = !faq.open;
          } else {
            faq.open = false;
          }
        }
        return faq;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.main__faq {
  display: flex;
  margin: 164px auto 0 auto;
  max-width: 1440px;
  flex-direction: column;
  padding: 0 80px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    margin-top: 114px;
    padding: 0 60px;

  }

  @media (max-width: 576px) {
    margin-top: 80px;
    padding: 0 16px;
  }
}

.main__faq__title {
  color: $color-dark-E343D;
  font-family: 'Gramatika-Medium';
  font-weight: 500;
  font-size: 3rem;
  padding-bottom: 24px;

  @media (max-width: 991px) {
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
  }

  @media (max-width: 768px) {
    padding-bottom: 54px;
  }

  @media (max-width: 576px) {
    padding-bottom: 38px;
  }
}
</style>