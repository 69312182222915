<template>
  <div class="blog__tags__container">
    <div class="blog__tags__mask blog__tags__mask__left"></div>
    <div class="blog__tags">
      <span class="blog__tag__hidden"></span>
      <button
        :class="getTagActive(i)"
        v-for="(tag, i) in tags"
        :key="keyId(i)"
        @click="setTagActive(i, tag.id)"
      >
        {{ tag.name }}({{ tag.count }})
      </button>
      <span class="blog__tag__hidden"></span>
    </div>
    <div class="blog__tags__mask blog__tags__mask__right"></div>
   </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import { v4 as uuid } from "uuid";
import ITags from "@/interface/blog/ITags";

interface IData {
  active: number;
  tags: ITags[];
}

export default defineComponent({
  name: "BlogTag",
  props: ["propTags", "activeTags"],
  data(): IData {
    return {
      active: +this.activeTags,
      tags: this.propTags,
    };
  },
  methods: {
    keyId(index: number): string {
      return index + uuid();
    },
    getTagActive(index: number): string {
      return this.active === index
        ? "blog__tags__item blog__tags__item__active"
        : "blog__tags__item";
    },
    setTagActive(index: number, id: number): void {
      this.active = index;
      return this.$emit("tagsCategory", id , index);
    },
  },
  watch: {
    activeTags: function (newVal) {
      this.active = newVal;
    },
  },
});
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.blog__tags__container {
  margin-top: 48px;
  position: relative;
}

.blog__tags__mask {
  position: absolute;
  top: 0;

  @media (max-width: 991px) {
    width: 60px;
    height: 46px;
    z-index: 10;
  }

  &__left {
    left: 0 - 60px;
    background: linear-gradient(
      to right,
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    @media (max-width: 576px) {
      width: 16px;
      left: 0 - 16px;
    }
  }

  &__right {
    background: linear-gradient(
      to left,
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    right: 0 - 60px;

    @media (max-width: 576px) {
      width: 16px;
      right: 0 - 16px;
    }
  }
}

.blog__tag__hidden {
  display: none;

  @media (max-width: 991px) {
    display: block;
    min-width: 46px;
  }

  @media (max-width: 576px) {
    min-width: 0;
  }
}

.blog__tags {
  position: relative;
  z-index: 0;
  display: flex;
  gap: 16px;
  overflow-x: scroll !important;
  width: 100%;

  @media (max-width: 991px) {
    left: 0 - 60px;
    width: calc(100% + 120px);
  }

  @media (max-width: 576px) {
    left: 0 - 16px;
    width: calc(100% + 32px);
  }

  &__item {
    height: 62px;
    padding: 16px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 2px solid $color-form-fon;
    background: $color-form-fon;
    color: $color-text;
    font-family: "Gramatika-Medium";
    font-size: 1.25rem;
    line-height: 1.875rem;
    flex-shrink: 0;
    cursor: pointer;
    user-select: none;

    @media (max-width: 1280px) {
      padding: 8px 20px;
      font-size: 1rem;
      line-height: 1.5rem;
      height: 46px;
      border-radius: 8px;
    }

    &:hover {
      box-sizing: border-box;
      background: linear-gradient($color-form-fon, $color-form-fon) padding-box,
        $color-gradient border-box;
      border: 2px solid transparent;
      padding: 16px 32px;

      @media (max-width: 1280px) {
        padding: 8px 20px;
      }
    }
  }

  &__item__active {
    color: $color-dark-E343D;
    background: $color-bg;
    border: 2px solid $color-dark-E343D;

    &:hover {
      background: linear-gradient($color-bg, $color-bg) padding-box,
        $color-gradient border-box;
      border: 2px solid transparent;
      padding: 16px 32px;

      @media (max-width: 1280px) {
        padding: 8px 20px;
      }
    }
  }
}
</style>