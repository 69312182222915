<template>
  <div>
    <HeadersComponent/>
    <section class="section-courses">
      <div class="grid-system-courses">
        <div class="section-courses-item">
          <h2 class="section-courses-item__title">Физические лица</h2>
          <div class="section-courses-item__price">50 000 руб.</div>
          <div class="section-courses-item__access">1 доступ</div>
        </div>
        <div class="section-courses-item">
          <h2 class="section-courses-item__title">Корпоративные клиенты</h2>
          <div class="section-courses-item__price">100 000 руб.</div>
          <div class="section-courses-item__access">3 доступа</div>
        </div>
      </div>
    </section>
    <FooterComponent/>
  </div>
</template>

<script lang="ts">

import {defineComponent} from 'vue'
import HeadersComponent from '../components/headers/Headers.vue';
import FooterComponent from '../components/footer/Footer.vue'

export default defineComponent({
  name: 'RequirementsPage',
  components: {
    HeadersComponent,
    FooterComponent
  },


});
</script>

<style lang="scss" scoped>

.section-courses{
  max-width: 1280px;
  margin: 88px auto 0 auto;
  position: relative;
  color: #000;
  padding: 0 80px;
  @media(max-width: 767.98px){
    padding: 0 15px;
  }
}
.grid-system-courses{
  display: grid;
  grid-template-columns: repeat(2, 416px);
  justify-content: center;
  gap: 20px 16px;
  @media (min-width: 768px) and (max-width: 1023.98px) {
    grid-template-columns: repeat(2, 288px);
  }
  @media (max-width: 767.98px){
    grid-template-columns: 1fr;
  }
}
.section-courses-item{
  border-radius: 24px;
  background: #EFEFF0;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &__title{
    color: #2E343D;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__price{
    background-image: linear-gradient(135deg, #7277FF 0%, #FF5A5A 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: 'Gramatika-Medium';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
  }

}
</style>