<template>
  <div>
    <HeadersComponent />
    <CourseComponent />
    <FooterComponent class="footer-mt" />
  </div>
</template>

<script>
import HeadersComponent from "../components/headers/Headers.vue";
import CourseComponent from "../components/course/CourseComponent.vue";
import FooterComponent from "../components/footer/Footer.vue";

export default {
  name: "CoursePage",
  components: {
    HeadersComponent,
    CourseComponent,
    FooterComponent,
  },
};
</script>

<style lang="scss" scoped>
.footer-mt {
  margin-top: 124px;

  @media (max-width: 991px) {
    margin-top: 96px;
  }

  @media (max-width: 576px) {
    margin-top: 88px;
  }
}
</style>