<template>
    <section class="center__platform">
        <h2 class="center__platform__title">Как устроена платформа «Фреон»</h2>
        <div class="center__platform__wrap">
            <div class="center__platform__container">
                <div class="center__platform__card">            
                    <p class="center__platform__subtitle"><span>/01</span>Круглосуточный доступ к личному кабинету</p>
                    <p class="center__platform__text">Смотрите обучающее видео и изучайте теорию онлайн в любое время, без отрыва от производства</p>
                </div>
                <div class="center__platform__card">
                    <p class="center__platform__subtitle"><span>/02</span>Поддержка кураторов</p>
                    <p class="center__platform__text">При возникновении вопросов кураторы курса предоставят профессиональную консультацию</p>
                </div>
            </div>

            <div class="center__platform__container">
                <div class="center__platform__card">
                    <p class="center__platform__subtitle"><span>/03</span>Проверочные задания и тесты</p>
                    <p class="center__platform__text">Задания проверяются сразу после их выполнения, а результат учитывается в общий рейтинг обучающегося</p>
                </div>
                <div class="center__platform__card">
                    <p class="center__platform__subtitle"><span>/04</span>Практические занятия прямо из дома</p>
                    <p class="center__platform__text">Проходите практику в VR, тренажёр максимально приближён к реальным условиям работы</p>
                </div>
            </div>
        </div>
        <BtnCopmonentArrow
        class="center__platform__btn_arrow"
        title="Начать учиться бесплатно"
        width_arrow="25"
        methods="linkToFreeLearn"
        @linkToFreeLearn="linkToFreeLearn()"
      />
    </section>
</template>

<script>
import BtnCopmonentArrow from "@/components/btnCopmonent/BtnCopmonentArrow.vue";

export default {
    name: 'CenterPlatformComponent',
    components: {
    BtnCopmonentArrow,
  },
  methods: {
    linkToFreeLearn() {
      this.$router.push({ name: "free-learn" });
    },
  },
};
</script>

<style lang="scss" scoped>
.center__platform {
    margin: 138px auto 0 auto;
    box-sizing: border-box;
    max-width: 1440px;
    padding-left: 80px;
    padding-right: 80px;
  
    @media (max-width: 991px) {
      margin-top: 113px;
      padding-left: 60px;
      padding-right: 60px;
    }
  
    @media (max-width: 576px) {
      margin-top: 50px;
      padding-left: 16px;
      padding-right: 16px;
    }
}
.center__platform__title {
    font-family: 'Gramatika-Medium';
    font-size: 3rem;
    font-weight: 400;
    line-height: 4.5rem;
    color: $color-dark-E343D;
    margin-bottom: 54px;

    @media (max-width: 991px){
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 32px;
    }

    @media (max-width: 576px){
        font-size: 1.75rem;
        line-height: 2.625rem;
    }
}
.center__platform__wrap {
    display: flex;
    gap: 16px;
    margin-bottom: 5px;

    @media (max-width: 991px){
        flex-direction: column;
        gap: 0px;
        margin-bottom: 18px;
    }
}

.center__platform__container{
    width: 50%;

    @media (max-width: 991px){
        width: 100%;
    }
}
.center__platform__card {
    position: relative;
    margin-bottom: 44px;

    @media (max-width: 991px){
        margin-bottom: 13px;
    }
}
.center__platform__subtitle {
    margin-bottom: 12px;
    font-family: 'Gramatika-Medium';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    color: $color-dark-E343D;
    display: flex;

    span{
        font-family: "Inter-Medium";
        font-size: 1rem;
        font-weight: 600;
        background-image: $color-gradient;
        background-clip: text;
        color: transparent;
        margin-right: 24px;
        width: 28px;
        margin-bottom: -7px;
    }

    @media (max-width: 991px){
        align-items: center;     
    }

    @media (max-width: 576px){
        font-size: 1.25rem;
        line-height: 1.875rem;
    }
}
.center__platform__text {
    margin-left: 52px;
    font-family: 'Inter-Regular';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: $color-dark-E343D;
    
    @media (max-width: 576px){
        margin-left: 0px;
    }
}

.center__platform__btn_arrow{
    background-color: transparent;
    font-size: 1.25rem;
    line-height: 1.875rem;

    @media (max-width: 576px){
        font-size: 1rem;
        line-height: 1.5rem;    
    }
}
</style>