<template>
    <section class="main__skills ">
        <h2 class="main__main__skills__title">Обучаясь у нас, вы научитесь</h2>
        <div class="main__skills-wrapper">

            <div class="main__skills-elem" v-for="skill in dataSkills" :key="skill.description">
                <div class="main__skills-elem__block">
                    <p class="main__skills-elem__text">
                        {{ skill.description }}
                    </p>
                </div>
                <div class="main__skills__image-box" :style="getBgImage(skill.img)"></div>
            </div>

        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'


export default defineComponent({
    name: 'Mainmain__skillsComponent',
    data() {
        return {
            dataSkills: [
                {
                    description: 'монтировать климатическое оборудование (VRF-системы, сплит-системы, чиллеры)',
                    img: 'skill',
                },
                {
                    description: 'проводить пусконаладочные работы систем кондиционирования и вентиляции различной сложности',
                    img: 'skill3',
                },
                {
                    description: 'снимать рабочие параметры, после установки климатического оборудования',
                    img: 'skill1',
                },
                {
                    description: 'рассчитывать количество и проводить заправку систем хладагентом',
                    img: 'skill4',
                },
                {
                    description: 'проводить диагностику и определять причины неполадок климатического оборудования',
                    img: 'skill2',
                },
                {
                    description: 'правильно резать, вальцевать, проводить гибку и пайку трубы различных материалов',
                    img: 'skill5',
                },
            ],
            index: 0,

        };
    },
    methods: {
        getBgImage(img: string) {
            return { backgroundImage: `url(${require('@/assets/images/skills/' + img + '.jpg')})` }
        }
    },
});
</script>
<style lang="scss" scoped>
.main__skills {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    margin-top: 130px;
    max-width: 1280px;
    padding: 0 80px;

    @media (max-width: 991px) {
        margin-top: 100px;
        padding: 0 60px;

    }

    @media (max-width: 576px) {
        margin-top: 80px;
        padding: 0 16px;
    }

}


.main__skills-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    column-gap: 16px;
    row-gap: 16px;
    margin-top: 32px;

    @media (max-width: 991px) {
        margin-top: 55px;
    }

    @media (max-width: 576px) {
        margin-top: 24px;
    }

}

.main__main__skills__title {
    font-family: "Gramatika-Medium";
    font-size: 3rem;
    line-height: 4.5rem;
    font-weight: 400;
    color: $color-dark-E343D;

    @media (max-width: 991px) {
        font-size: 2rem;
        line-height: 3rem;
    }

}

.main__skills-elem {
    position: relative;
    display: flex;
    width: 24%;

    @media (max-width: 991px) {
        width: 100%;
        min-height: 96px;
    }

    @media (max-width: 767px) {
        min-height: 130px;
        width: 100%;
    }
}

.main__skills-elem:hover {
    @media (min-width: 992px) {
        width: 48%;
    }
}

.main__skills-elem__block {
    width: 100%;
    min-height: 200px;
    background-color: $color-skills;
    border-radius: 24px;

    @media (max-width: 991px) {
        width: 64%;
        border-radius: 24px 0 0 24px;
        min-height: 96px;

    }

    @media (max-width: 576px) {
        width: 100%;
        height: 100%;
        border-radius: 24px;
    }
}

.main__skills-elem:nth-child(even) .main__skills-elem__block {
    @media (max-width: 991px) {
        order: 1;
        border-radius: 0 24px 24px 0;
    }

    @media (max-width: 576px) {
        order: 1;
        border-radius: 24px;
    }
}


.main__skills-elem__text {
    position: relative;
    z-index: 2;
    width: 90%;
    padding: 16px 16px 0;
    font-family: "Inter-Regular";
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin: 0;
    color: $color-dark-E343D;
    word-wrap: break-word;

    @media (max-width: 991px) {
        padding: 0 8px;
    }

    @media (max-width: 576px) {
        padding: 16px;

    }
}

.main__skills__image-box {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    border-radius: 0 24px 24px 0;
    opacity: 0;
    transition: all 1s ease-out;


    @media (max-width: 991px) {
        width: 36%;
        opacity: 1;
    }

    @media (max-width: 576px) {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 24px;
    }
}

.main__skills-elem:nth-child(even) .main__skills__image-box {
    @media (max-width: 991px) {
        border-radius: 24px 0 0 24px;
    }

    @media (max-width: 576px) {
        order: 1;
        border-radius: 24px;
    }
}

.main__skills__image-box::before {
    @media (max-width: 576px) {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        border-radius: 24px;
        background-color: rgb(255, 255, 255);
        mask-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(239, 239, 239, 0.40) 100%);
    }
}



.main__skills-elem:hover {
    @media (min-width: 992px) {
        .main__skills__image-box {
            width: 100%;
            min-height: 200px;
            opacity: 1;
        }

        .main__skills-elem__block {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
</style>
