<template>
  <div @click="closeClickToPageMenuHeader($event)">
    <HeadersComponent />
    <MainPresentationComponent />
    <MainAdvantageComponet />
    <MainAboutComponent />
    <MainSkillsComponent />
    <MainFaqComponet />
    <MainFormConsultation title="Остались вопросы?" />
    <MainReviewsComponent />
    <FooterComponent />
  </div>
</template> 

<script lang="ts">
import HeadersComponent from "../components/headers/Headers.vue";
import MainPresentationComponent from "../components/main/mainPresentationComponent/MainPresentationComponent.vue";
import MainAdvantageComponet from "../components/main/mainAdvantageComponet/MainAdvantageComponet.vue";
import MainAboutComponent from "../components/main/mainAboutComponent/MainAboutComponent.vue";
import MainSkillsComponent from "../components/main/mainSkillsComponent/MainSkillsComponent.vue";
import MainFaqComponet from "../components/main/mainFaqComponent/MainFaqComponent.vue";
import MainFormConsultation from "../components/main/mainFormConsultationComponent/MainFormConsultation.vue";
import MainReviewsComponent from "../components/main/mainReviewsComponent/MainReviewsComponent.vue";
import FooterComponent from "../components/footer/Footer.vue";
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "MainPage",
  components: {
    HeadersComponent,
    MainPresentationComponent,
    MainAdvantageComponet,
    MainAboutComponent,
    MainSkillsComponent,
    MainFaqComponet,
    MainFormConsultation,
    MainReviewsComponent,
    FooterComponent,
  },

  mounted() {
    this.checkScrollPosition();
    window.addEventListener("scroll", this.checkScrollPosition);
  },
  methods: {
    checkScrollPosition() {
      if (innerWidth > 991) {
        window.scrollY > 500 ? store.commit("setCheckScrollBg") : null;
      } else if (innerWidth > 576) {
        window.scrollY > 350 ? store.commit("setCheckScrollBg") : null;
      } else if (innerWidth <= 576) {
        window.scrollY > 380 ? store.commit("setCheckScrollBg") : null;
      }
    },
    closeClickToPageMenuHeader(event: MouseEvent): void {
      let target = event.target as HTMLDivElement;
      if (
        target.closest(".header__nav-bar") === null &&
        target.closest(".header__menu") === null &&
        store.getters.getCloseClickPageMenuHeader === false
      ) {
        store.commit("setCloseClickPageMenuHeader", true);
      }
    },
  },
  metaInfo: {
    title: "Обучение специалистов климатического бизнеса дистанционно в VR",

    meta: [
      {
        name: "keywords",
        content:
          "обучение, курсы, дистанционно, учебный курс, учебный центр, обучение с нуля, обучение рабочим специальностям, рабочие профессии, профессиональная переподготовка, повышения квалификации",
      },
      {
        name: "description",
        content:
          "Профессиональная подготовка специалистов холодильного оборудования, систем вентиляции и кондиционирования, VRF-систем и другие рабочие специальности. Бесплатные уроки. УЦ Верконт Сервис",
      },
    ],
  },
});
</script>

<style lang="scss" scoped></style>