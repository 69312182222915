<template>
  <section class="blog-article">
    <div v-if="isLoadingArticle">
      <LoaderComponent class="blog-article__loader" />
    </div>
    <div v-else-if="isErrorArticle">
      <ErrorComponent :error="isErrorArticle" class="blog-article__error" />
    </div>
    <div v-else-if="dataArticle !== undefined">
      <ArticleBreadcrumbt
        :text="dataArticle.name"
        :category="dataArticle.tags[0].name || undefined"
        :categoryId="dataArticle.tags[0].id || undefined"
      />
      <ArticleHeader :data="dataArticle" />
      <div
        class="blog-article__content"
        v-html="this.dataArticle?.content"
      ></div>
      <div class="blog-article__wraper__img">
        <img class="blog-article__img" :src="getUrl" alt="img" />
      </div>
      <ArticleShareMenu :data="dataArticle" />
    </div>

    <PopularArticles title="Пользуются популярностью" />
    <ArticleSubscribe
      :btnFontSize="btnFontSize"
      :btnLineHeight="btnLineHeight"
    />
    <ChooseCourse :btnFontSize="btnFontSize" :btnLineHeight="btnLineHeight" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoaderComponent from "@/components/loader/Loader.vue";
import ErrorComponent from "@/components/error/Error.vue";
import ArticleBreadcrumbt from "./articleBreadcrumbt/ArticleBreadcrumbt.vue";
import ArticleHeader from "./articleHeader/ArticleHeader.vue";
import ArticleShareMenu from "./articleShareMenu/ArticleShareMenu.vue";
import PopularArticles from "./popularArticles/PopularArticles.vue";
import ArticleSubscribe from "./articleSubscribe/ArticleSubscribe.vue";
import ChooseCourse from "./chooseCourse/ChooseCourse.vue";
import http from "@/api/http";
import IBlog from "@/interface/blog/IBlog";

interface IData {
  isWidthOK: boolean;
  btnFontSize: undefined | number;
  btnLineHeight: undefined | number;
  dataArticle: IBlog | undefined;
  isLoadingArticle: boolean;
  isErrorArticle: string;
  isShowArticle: boolean;
}

export default defineComponent({
  name: "ArticleComponent",
  components: {
    LoaderComponent,
    ErrorComponent,
    ArticleBreadcrumbt,
    ArticleHeader,
    ArticleShareMenu,
    PopularArticles,
    ArticleSubscribe,
    ChooseCourse,
  },
  data(): IData {
    return {
      isWidthOK: false,
      btnFontSize: undefined,
      btnLineHeight: undefined,
      dataArticle: undefined,
      isLoadingArticle: false,
      isErrorArticle: "",
      isShowArticle: false,
    };
  },
  created() {
    window.addEventListener("resize", this.getResize);
  },
  mounted() {
    this.getArticle();
    this.showArticle();
  },
  methods: {
    async getArticle() {
      const { id } = this.$route.params;

      let url = `blog/${id}/publication`;

      try {
        this.isLoadingArticle = true;
        this.isErrorArticle = "";
        this.dataArticle = await http.get(url).then((response) => {
          return response.data.data;
        });
      } catch (err: any) {
        this.isErrorArticle = err.message;
      } finally {
        this.isLoadingArticle = false;
      }
    },

    getResize() {
      if (innerWidth <= 576) {
        this.btnFontSize = 16;
        this.btnLineHeight = 24;
      }
    },

    async showArticle() {
      const { id } = this.$route.params;
      let url = `blog/${id}/publication/view`;

      try {
        if (this.isShowArticle) this.dataArticle = await http.post(url);
      } catch (err: any) {
        console.log(err);
      }
      this.isShowArticle = true;
    },
  },
  computed: {
    getUrl: function (): string {
      return "https://fsi0622.1t.ws" + this.dataArticle?.preview_img;
    },
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler() {
        this.getArticle();
        this.showArticle();
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.blog-article {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 80px;
  box-sizing: border-box;

  @media (max-width: 991px) {
    padding: 0 60px;
  }

  @media (max-width: 576px) {
    padding: 0 16px;
  }

  &__loader {
    margin-top: 100px;
  }
  &__error {
    margin-top: 100px;
    min-height: 50px;
  }
}

.blog-article__content {
  padding: 48px 150px 0 150px;
  color: $color-dark-E343D;
  font-family: "Inter-Regular";
  font-size: 1.25rem;
  line-height: 1.875rem;

  &-text {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  @media (max-width: 991px) {
    padding: 32px 0 0 0;
  }

  @media (max-width: 576px) {
    padding: 8px 0 0 0;
  }
}

.blog-article__wraper__img {
  margin: 0 auto;
  padding-top: 20px;
  max-width: 734px;
  max-height: 491px;
  height: 491px;
  box-sizing: border-box;

  @media (max-width: 991px) {
    padding: 20px 0 0 0;
    max-width: 649px;
    height: 431px;
    box-sizing: border-box;
  }

  @media (max-width: 576px) {
    max-width: 576px;
    max-height: 376px;
    box-sizing: border-box;
  }

  @media (max-width: 391px) {
    max-height: 276px;
    box-sizing: border-box;
  }
}

.blog-article__img {
  width: 100%;
  height: 100%;
}
</style>