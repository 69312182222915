<template>
  <div class="blog__section">
    <h1 class="blog__title">Информационный Блог</h1>
    <div class="blog__search">
      <input
        type="text"
        class="blog__search__input"
        placeholder="Найти теги"
        v-model="search"
        @keypress.enter="getSeacrh()"
      />
      <span class="blog__search__icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.9969 9.6C22.9969 14.9019 18.6988 19.2 13.3969 19.2C8.09494 19.2 3.79688 14.9019 3.79688 9.6C3.79688 4.29807 8.09494 0 13.3969 0C18.6988 0 22.9969 4.29807 22.9969 9.6ZM14.1139 3.5366C13.5441 3.47987 12.9688 3.55039 12.4296 3.74306C11.8904 3.93573 11.4007 4.24574 10.9959 4.65071C10.6054 5.04131 10.6056 5.67448 10.9962 6.06493C11.3868 6.45537 12.0199 6.45525 12.4104 6.06465C12.6058 5.86913 12.8422 5.71946 13.1026 5.62644C13.3629 5.53342 13.6406 5.49937 13.9157 5.52676C14.1908 5.55415 14.4564 5.6423 14.6933 5.78482C14.9302 5.92735 15.1324 6.1207 15.2855 6.35092C15.5913 6.81084 16.212 6.93581 16.6719 6.63004C17.1318 6.32428 17.2568 5.70357 16.951 5.24365C16.634 4.76681 16.215 4.36632 15.7244 4.07111C15.2338 3.7759 14.6837 3.59334 14.1139 3.5366Z"
            fill="#8698B2"
          />
          <path
            d="M2 22.0338L8 16.0332"
            stroke="#8698B2"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
    <BlogTag
      :propTags="tags"
      v-bind:activeTags="activeTag"
      @tagsCategory="fillterCategory"
    />
    <div v-if="isLoading">
      <LoaderComponent />
    </div>
    <div v-else-if="isError">
      <ErrorComponent :error="isError" />
    </div>
    <div v-else-if="dataCategory.length > 0">
      <template v-for="item in dataArrPaganation">
        <BlogTop
          :item="item"
          v-if="item.id == dataCategory[0].id"
          :key="item.id"
          @change-сategory="setCategory"
          @change-hash-tag="setHashTag"
        />
        <BlogItem
          :item="item"
          v-else
          :key="item.id"
          @change-сategory="setCategory"
          @change-hash-tag="setHashTag"
        />
      </template>
    </div>
    <div v-else>
      <p class="blog__text">В данной категории нет новостей</p>
    </div>
    <div v-if="dataArrPaganation.length > 0">
      <PaginationComponent
        :total="totalPage"
        @page-change="getPages"
        :page="page"
      />
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../api/http";
import { defineComponent } from "vue";
import BlogTop from "./blogTop/BlogTop.vue";
import BlogItem from "./blogItem/BlogItem.vue";
import BlogTag from "./blogTag/BlogTag.vue";
import LoaderComponent from "../loader/Loader.vue";
import ErrorComponent from "@/components/error/Error.vue";
import IBlog from "@/interface/blog/IBlog";
import ITags from "@/interface/blog/ITags";
import PaginationComponent from "@/components/paginationComponent/PaginationComponent.vue";

interface IData {
  search: string;
  isLoading: boolean;
  topItemBlog: null | IBlog;
  itemsBlog: null | IBlog[];
  isError: string;
  activeTag: number;
  dataArr: IBlog[];
  dataCategory: IBlog[];
  dataArrPaganation: IBlog[];
  page: number;
  totalPage: number;
  perPage: number;
  tags: ITags[];
  sss: number[];
}

export default defineComponent({
  name: "BlogComponent",
  components: {
    BlogTag,
    BlogTop,
    BlogItem,
    LoaderComponent,
    ErrorComponent,
    PaginationComponent,
  },
  data(): IData {
    return {
      search: "",
      isLoading: false,
      topItemBlog: null,
      itemsBlog: null,
      isError: "",
      activeTag: 0,
      dataArr: [],
      dataCategory: [],
      dataArrPaganation: [],
      page: 1,
      totalPage: 0,
      perPage: 4,
      tags: [
        { name: "Все", id: 0, count: 0 },
        { name: "База знаний", id: 23, count: 0 },
        { name: "Акции", id: 26, count: 0 },
        { name: "Новости", id: 9, count: 0 },
        { name: "Мероприятия", id: 3, count: 0 },
      ],
      sss: [],
    };
  },
  async mounted() {
    await this.getData();
    if (this.$route.query.category) {
      this.setCategory(+this.$route.query.category);
    }
    if (this.$route.query.hash_tag) {
      this.setHashTag(+this.$route.query.hash_tag);
    }
  },
  methods: {
    async getData(url = "blog/all", params?: { search_query: string }) {
      try {
        this.isLoading = true;
        this.isError = "";
        this.page = 1;
        this.clearTagsCount();
        const data = await http.get(url, { params }).then((response) => {
          return response.data.data;
        });
        
        this.dataSort(data);
        this.tagsCount();
        this.dataCategory = this.dataArr;
        this.getTotalPage();
        this.getPages(1);
      } catch (err: any) {
        this.isError = err.message;
      } finally {
        this.isLoading = false;
      }
    },

    getPages(page: number) {
      this.dataArrPaganation = [];
      this.page = page;
      const startIndex = this.perPage * (page - 1);
      const endIndex =
        startIndex + this.perPage - 1 <= this.dataCategory.length - 1
          ? startIndex + this.perPage - 1
          : this.dataCategory.length - 1;

      for (let i = startIndex; i <= endIndex; i++) {
        this.dataArrPaganation.push(this.dataCategory[i]);
      }
    },

    async getSeacrh() {
      if (this.search !== "") {
        this.getData("blog/publication/search", {
          search_query: this.search,
        });
      } else {
        this.getData();
      }
      this.search = "";

      this.activeTag = 0;
    },

    dataSort(data: IBlog[]) {
      this.dataArr = data.sort(
        (a: IBlog, b: IBlog) =>
          (b.likes !== null ? b.likes : 0) +
          (b.views !== null ? b.views : 0) -
          ((a.likes !== null ? a.likes : 0) + (a.views !== null ? a.views : 0))
      );
    },

    tagsCount() {
      this.dataArr?.forEach((item: IBlog) => {
        if (item.tags[0] !== null && item.tags[0].type === "category") {
          this.tags.filter((tagPage) => {
            if (tagPage.id === item.tags[0].id) {
              tagPage.count++;
            }
          });
        }
        this.tags[0].count++;
      });
    },

    clearTagsCount() {
      this.tags.forEach((tag) => (tag.count = 0));
    },

    fillterCategory(id: number, index?: number) {
      index ? (this.activeTag = index) : null;
      this.page = 1;
      this.dataCategory =
        id == 0
          ? this.dataArr
          : this.dataArr?.filter((item) => item.tags[0].id === id);
      this.getPages(this.page);
      this.getTotalPage();
    },

    fillterHashTag(id: number) {
      this.page = 1;
      this.activeTag = 0;
      this.dataCategory =
        id == 0
          ? this.dataArr
          : this.dataArr?.filter((item) => item.tags[1].id === id);
      this.getPages(this.page);
      this.getTotalPage();
    },

    getTotalPage() {
      this.totalPage = Math.ceil(this.dataCategory.length / this.perPage);
    },

    setCategory(id: number) {
      this.tags.filter((tagPage, i) => {
        if (tagPage.id === id) {
          this.activeTag = i;
        }
      });
      this.fillterCategory(id);
    },
    setHashTag(id: number) {
      this.fillterHashTag(id);
    },
  },
});
</script>

<style lang="scss" scoped>
.blog__section {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 80px;
  box-sizing: border-box;
  min-height: 900px;

  @media (max-width: 991px) {
    min-height: 600px;
    padding: 0 60px;
  }

  @media (max-width: 576px) {
    padding: 0 16px;
    min-height: 400px;
  }
}

.blog__title {
  margin-top: 152px;
  font-family: "Gramatika-Medium";
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5rem;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 540px;

  @media (max-width: 991px) {
    margin-top: 84px;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media (max-width: 576px) {
    margin-top: 76px;
    font-size: 1.75rem;
    line-height: 2.625rem;
  }
}

.blog__search {
  margin-top: 32px;
  position: relative;

  &__input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 16px;
    border: 1px solid $color-text-input;
    background: $color-card-foto;
    border-radius: 8px;
    color: $color-text-input;
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: start;
    position: relative;
    display: flex;
    outline: none;

    &:hover {
      border-color: $color-hover;
    }

    &:active {
      border-color: $color-hover;
    }

    &:focus {
      border-color: $color-form-text;
      color: $color-dark-E343D;
    }
  }

  &__icon {
    position: absolute;
    top: 12px;
    right: 16px;
    width: 24px;
    height: 24px;
  }
}

.blog__text {
  margin-top: 84px;
  font-size: 1.5rem;
  line-height: 2rem;
  color: $color-dark-E343D;
  height: 200px;

  @media (max-width: 991px) {
    margin-top: 30px;
  }
}
.blog__pagination {
  display: flex;
  justify-content: center;
  gap: 16px;
  list-style-type: none;
  margin-top: 32px;
}
</style>





   