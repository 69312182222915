<template>
  <div class="breadcrumb">
    <ul class="breadcrumb__list">
      <li>
        <router-link to="/" class="breadcrumb__item">Главная</router-link>
      </li>
      <li>
        <router-link to="/blog" class="breadcrumb__item"
          >Информационный Блог</router-link
        >
      </li>
      <li>
        <router-link :to="toBlogGategory" class="breadcrumb__item">{{
          getCategory
        }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ArticleBreadcrumbt",
  props: {
    text: String,
    category: String,
    categoryId: Number,
  },
  computed: {
    getCategory: function (): string {
      let textCategory = "";
      if (this.category?.includes("#")) {
        textCategory = this.category.slice(1);
      }
      return textCategory;
    },
    toBlogGategory: function (): string {
      return "/blog?category=" + this.categoryId;
    },
  },
});
</script>

<style lang="scss" scoped>
.breadcrumb {
  margin-top: 80px;

  &__list {
    list-style-type: none;
    display: flex;
    font-family: "Gramatika-Medium";
    line-height: 1.875rem;
    font-size: 1.25rem;
    align-items: center;
    flex-wrap: wrap;
  }

  li:not(:first-child) .breadcrumb__item {
    &::before {
      padding: 0 5px;
      color: $color-text-input;
      content: "/";
    }

    @media (max-width: 991px) {
      &::before {
        content: "";
        position: absolute;
        padding: 0;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 20px;
        height: 20px;
        background-image: url("@/assets/icons/blog/prev.svg");
        background-repeat: no-repeat;
      }
    }
  }

  &__item {
    position: relative;
    text-decoration: none;
    color: $color-text-input;

    @media (max-width: 991px) {
      padding-left: 36px;
    }
  }

  @media (max-width: 991px) {
    li:not(:nth-child(2)) {
      display: none;
    }
  }
}
</style>