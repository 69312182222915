export const leftReviews = [
  {
    name: "Евгений М.",
    img: "rewiew-icon-user.svg",
    text: "Впервые выбрал для себя учебный курс, в котором используют VR. Могу сказать, что довольно интересно было изучать ремонт и обслуживание систем кондиционирования. Сразу странно и непривычно, но потом, даже без использования очков, имея только ПК с каждым последующим шагом я все больше погружался в понимание устройства СКВ. Хорошо собран материал, лекции на доступном языке. При большой занятости на основном месте работы такой формат обучения очень удобен",
  },
  {
    name: "Александр П.",
    img: "rewiew-icon-user.svg",
    text: "Обслуживание вентиляций требует не мало знаний, и, честно говоря, скептически был настроен на программу с применением VR-технологий. Но на практике все оказалось проще, чем я думал. Лекции подготовлены таким образом, что можно использовать только компьютер и не покупать VR очки. Цифровая симуляция позволила погрузиться в процесс ремонта сполна. По окончании курса сразу получил заказ и переживал, что на реальном объекте не справлюсь. Но картинка из обучающей тренировки всегда была перед глазами. И это благодаря иммерсивному обучению. Удивительно, как можно перенести все провода из вентиляционных установок в виртуальный мир.",
  },
  {
    name: "Петр С.",
    img: "rewiew-icon-user.svg",
    text: "VR и сфера климатического оборудования кажутся несовместимыми. Но курс по ремонту СКВ в учебном центре доказал обратное. Я регулярно прохожу обучение. Повышаю квалификацию и для меня важно не просто теория, но и практика. Часто она вообще отсутствует, а тут она в моем доступе круглосуточно. То есть нет необходимости подстраиваться под набор групп и ждать пока откроют новый поток, не нужно тратиться на поездку (в моем городе нет подобных предложений). Я это могу делать сам у себя дома в любое время. Просто включив компьютер. Мне понравился образовательный процесс.",
  },
];
export const rightReviews = [
  {
    name: "Антон М.",
    img: "rewiew-icon-user.svg",
    text: "VR технологии не оставили в стороне даже такие специальности как ремонтник СКВ. Хотелось пройти курс именно в таком формате, т.к. это экономит время и транспортные расходы. Живу в Краснодаре, и времени ехать в другой регион нет. Такой курс мне позволил изучить популярное направление по ремонту систем вентиляции, не выходя из дома. Какие-то работы приходилось отрабатывать несколько раз, чтобы на практике не допустить ошибок. В целом я доволен. Планирую пройти еще обучение по другим направлениям.",
  },
  {
    name: "Платон В.",
    img: "rewiew-icon-user.svg",
    date: "28.06.2023",
    text: "Опыта работы с кондиционерами нет. Знал, что это и для чего. Как устроены не имел ни малейшего представления. Но из-за перспектив в этой сфере решил пройти обучение. Думал меня ждут скучные лекции, однотипные видео из ютуб и прочих серверов. По факту был приятно удивлен. Материал курса прописан очень просто. Не приходилось дополнительно искать информацию для понимания обслуживания СКВ. VR технологии обеспечивают хороший просмотр установок изнутри. Для новичка в этой области плюсом то, что можно отработать все виртуально, а уже потом не допуская ошибок работать на реальном объекте.",
  },
  {
    name: "Арсен А.",
    img: "rewiew-icon-user.svg",
    text: "Прошел курс. Пока однозначно мнение не сложилось. Все очень интересно, но непривычно. Большой плюс, что есть видео, преподаватель и отзывчивая поддержка, которая давала мне консультацию по всем моим вопросам. Еще, я, как человек с малым опытом работы проходил задания по несколько раз. Это предусмотрено системой и для меня оказалось ценным.",
  },
];
