<template>
  <section :class="changeBg()">
    <h2 class="main__advantage__title">Преимущества VR-обучения</h2>
    <div class="advant">
      <div class="advant__wrap advant__wrap_upper">
        <template v-for="(item, index) in dataAdvantage">
          <template v-if="item.length <= 14">
            <p class="advant__text" :key="keyId(index)">{{ item }}</p>
          </template>
          <template v-else>
            <p class="advant__text advant__text_inline" :key="keyId(index)">
              {{ item }}
            </p>
          </template>
        </template>

        <template v-for="(item, index) in dataAdvantage">
          <template v-if="item.length <= 14">
            <p class="advant__text" :key="keyId(index)">{{ item }}</p>
          </template>
          <template v-else>
            <p class="advant__text advant__text_inline" :key="keyId(index)">
              {{ item }}
            </p>
          </template>
        </template>
      </div>

      <div class="advant__wrap advant__wrap_lower">
        <template v-for="(item, index) in dataAdvantage">
          <template v-if="item.length <= 14">
            <p class="advant__text" :key="keyId(index)">{{ item }}</p>
          </template>
          <template v-else>
            <p class="advant__text advant__text_inline" :key="keyId(index)">
              {{ item }}
            </p>
          </template>
        </template>

        <template v-for="(item, index) in dataAdvantage">
          <template v-if="item.length <= 14">
            <p class="advant__text" :key="keyId(index)">{{ item }}</p>
          </template>
          <template v-else>
            <p class="advant__text advant__text_inline" :key="keyId(index)">
              {{ item }}
            </p>
          </template>
        </template>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import store from "@/store";
import { defineComponent } from "vue";
import { v4 as uuid } from "uuid";

export default defineComponent({
  name: "MainAdvantageComponet",

  data() {
    return {
      dataAdvantage: [
        "Практика",
        "Наглядность",
        "Индивидуальное обучение",
        "Проверка знаний",
        "Безопасность",
        "Вовлеченность",
        "Экономичность",
        "Фокусировка",
        "Эффективность",
        "Реалистичность",
      ],
    };
  },

  methods: {
    changeBg(): string {
      return store.getters.getCheckScrollBg
        ? "main__advantage main__advantage__bg"
        : "main__advantage";
    },
    keyId(index: number): string {
      return index + uuid();
    },
  },
});
</script>

<style lang="scss" scoped>
@keyframes move-left {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-2553px + (-62px));
  }
}

@keyframes move-right {
  from {
    transform: translate(-2611px);
  }

  to {
    transform: translate(0);
  }
}

@keyframes move-left-991 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-1838px + (-27px));
  }
}

@keyframes move-right-991 {
  from {
    transform: translate(-1865px);
  }

  to {
    transform: translate(0);
  }
}

@keyframes move-right-390 {
  from {
    transform: translate(-2265px + (-26px));
  }

  to {
    transform: translate(0);
  }
}

.main__advantage {
  max-width: 1440px;
  background-color: $color-text;
  padding: 67px 0 93px 0;
  background: $color-bg;
  margin: 0 auto;

  @media (max-width: 991px) {
    padding: 110px 0 70px 0;
  }

  @media (max-width: 480px) {
    padding: 110px 0 100px 0;
  }
}

.main__advantage__bg {
  background: $color-dark-E343D;
}

.main__advantage__title {
  width: 360px;
  margin-left: 80px;
  font-size: 3rem;
  font-family: Gramatika-Medium;
  line-height: 4.5rem;
  background-image: linear-gradient(135deg, #7277ff 0%, #ff5a5a 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  @media (max-width: 1280px) {
    margin-left: 60px;
  }

  @media (max-width: 991px) {
    width: 234px;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 400;
    margin-left: 60px;
  }

  @media (max-width: 576px) {
    margin-left: 15px;
  }
}

.advant {
  margin-top: 58px;
  max-width: 1440px;
  overflow: hidden;

  @media (max-width: 991px) {
    margin-top: 54px;
  }

  @media (max-width: 576px) {
    margin-top: 38px;
  }
}

.advant__wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 64px;
  margin-left: 80px;
  animation: move-left linear 60s infinite;

  @media (max-width: 991px) {
    margin-left: 0;
    gap: 32px;
    animation: move-left-991 linear 60s infinite;
  }
}

.advant__wrap_upper {
  margin-bottom: 40px;
}

.advant__wrap_lower {
  margin-left: -253px;
  animation: move-right linear 60s infinite;

  @media (max-width: 991px) {
    margin-left: -149px;
    animation: move-right-991 linear 60s infinite;
  }
}

.advant__text {
  font-family: Gramatika-Medium;
  box-sizing: border-box;
  max-width: 230px;
  padding: 32px;
  background-color: $color-form-fon;
  color: $color-text;
  font-size: 1.25rem;
  font-style: normal;
  line-height: 1.875rem;
  border-radius: 16px;
  text-align: center;

  @media (max-width: 991px) {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 24px;
  }
}

.advant__text_inline {
  padding-top: 17px;
  padding-bottom: 17px;

  @media (max-width: 991px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
</style>