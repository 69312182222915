import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import MainPage from '../pages/MainPage.vue'
import LicensePage from '../pages/LicensePage.vue'
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage.vue'
import ConsentPersonalPage from '../pages/ConsentPersonalPage.vue'
import DiplomPage from '../pages/DiplomPage.vue'
import СontractPage from '../pages/СontractPage.vue'
import CoursesPage from '../pages/CoursesPage.vue'
import AuthPage from '../pages/AuthPage.vue'
import BlogPage from '../pages/BlogPage.vue'
import CenterPage from '../pages/CenterPage.vue'
import ContactsPage from '../pages/ContactsPage.vue'
import RequirementsPage from '../pages/RequirementsPage.vue'
import CoursesPricePage from "@/pages/CoursesPricePage.vue";
import ArticlePage from "@/pages/ArticlePage.vue";
import CoursePage from "@/pages/CoursePage.vue";
import FreeLearnPage from "@/pages/FreeLearnPage.vue";
import EducationalMaterial1 from "@/pages/EducationalMaterial1.vue";
import EducationalMaterial2 from "@/pages/EducationalMaterial2.vue";
import Error404Page from "@/pages/Error404Page.vue";
import Error401Page from "@/pages/Error401Page.vue";
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'main',
    component: MainPage
  },
  {
    path: '/license',
    name: 'license',
    component: LicensePage
  },
  {
    path: '/diploms',
    name: 'diploms',
    component: DiplomPage
  },
  {
    path: '/сontract',
    name: 'сontract',
    component: СontractPage,
  },
  {
    path: '/courses',
    name: 'courses',
    component: CoursesPage,
  },
  {
    path: '/course/:id',
    name: 'course',
    component: CoursePage,
  },
  {
    path: '/login',
    name: 'login',
    component: AuthPage,
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogPage,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactsPage,
  },
  {
    path: '/blog/article/:id',
    name: 'article',
    component: ArticlePage,
  },
  {
    path: '/center',
    name: 'center',
    component: CenterPage,
  },

  {
    path: '/requirements',
    name: 'requirements',
    component: RequirementsPage,
  },
  {
    path: '/tariffs',
    name: 'courses-price',
    component: CoursesPricePage,
  },
  {
    path: '/free-learn',
    name: 'free-learn',
    component: FreeLearnPage,
  },
  {
    path: '/consent-personal-prof',
    name: 'consent-personal',
    component: ConsentPersonalPage,
  },
  {
    path: '/privacy-policy-prof',
    name: 'privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/educational-material2',
    name: 'educational-material2',
    component: EducationalMaterial2,
  },
  {
    path: '/educational-material1',
    name: 'educational-material1',
    component: EducationalMaterial1,
  },
  {
    path: '*',
    name: 'error404',
    component: Error404Page,
  },
  {
    path: '/unauthorized',
    name: 'error401',
    component: Error401Page,
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name == "course" && to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 120 },
        behavior: 'smooth'
      };
    }

    if (to.name == "courses" && to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 100 },
        behavior: 'smooth'
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})

router.afterEach(() => {
  store.commit('setCloseClickPageMenuHeader', true)
})

export default router
