<template>
    <div class="authform">
        <div class="authform__bg"></div>
        <div :class="getAuthformContainer()">
            <h3 class="authform__title">Приветствуем!</h3>
            <h4 class="authform__subtitle">Введите данные логина и пароля для входа в личный кабинет</h4>
            <form class="authform__form">
                <label class="authform__label" for="authform-login">Логин</label>
                <div class="authform__input__wrapper">
                    <input type="text" :class="getInputClass('login')" name="login" v-model="login" id="authform-login"
                        placeholder="Введите логин" />
                    <span class="authform__input__icon authform__input__icon__login">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
                            <path d="M11.0047 12.4626C14.4333 12.4626 17.236 9.6599 17.236 6.2313C17.236 2.80271 14.4333 0 11.0047 0C7.57609 0 4.77344 2.80271 4.77344 6.2313C4.77344 9.6599 7.57615 12.4626 11.0047 12.4626Z" fill="#A8BFDC"/>
                            <path d="M21.6948 17.442C21.5315 17.0339 21.3139 16.6529 21.069 16.2992C19.8173 14.4488 17.8853 13.2243 15.7085 12.925C15.4364 12.8978 15.137 12.9522 14.9193 13.1155C13.7765 13.959 12.416 14.3944 11.001 14.3944C9.58595 14.3944 8.22543 13.959 7.08257 13.1155C6.86486 12.9522 6.56554 12.8706 6.29345 12.925C4.11658 13.2243 2.15741 14.4488 0.932931 16.2992C0.688035 16.6529 0.470327 17.0611 0.307098 17.442C0.225483 17.6053 0.252671 17.7958 0.334285 17.9591C0.551993 18.34 0.824077 18.721 1.06897 19.0475C1.44991 19.5645 1.85809 20.0271 2.32069 20.4624C2.70163 20.8434 3.13699 21.1971 3.57241 21.5509C5.72205 23.1564 8.3071 23.9998 10.9738 23.9998C13.6404 23.9998 16.2255 23.1563 18.3751 21.5509C18.8105 21.2244 19.2458 20.8434 19.6268 20.4624C20.0622 20.0271 20.4976 19.5645 20.8786 19.0475C21.1506 18.6937 21.3956 18.34 21.6132 17.9591C21.7492 17.7958 21.7764 17.6053 21.6948 17.442Z" fill="#A8BFDC"/>
                        </svg>
                    </span>
                </div>
                <div class="authform__error" v-if="loginError">
                    <span class="authform__icon__error">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM12 2C6.47 2 2 6.5 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#CC6666"/>
                          </svg>
                    </span>
                    <p class="authform__text__error">Поле заполнено некорректно</p>
                </div>

                <label class="authform__label" for="password">Пароль</label>
                <div class="authform__input__wrapper">
                    <input :type="showPasswod ? 'text' : 'password'" :class="getInputClass('password')" name="password"
                        v-model="password" id="password" placeholder="Пароль" />
                    <span :class="getIconPasswordClass()" @click="setShowPassword()"></span>
                </div>

                <div class="authform__error" v-if="passwordError">
                    <span class="authform__icon__error">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM12 2C6.47 2 2 6.5 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#CC6666"/>
                          </svg>
                    </span>
                    <p class="authform__text__error">Поле заполнено некорректно</p>
                </div>

                <div class="authform__submit">
                    <div class="authform__submit__checkbox">
                        <input type="checkbox" id="checkbox" v-model="checked" class="authform__submit__input">
                        <label for="checkbox" class="authform__submit__label">Запомнить меня</label>
                    </div>
                    <BtnCopmonentBgGradient class="authform__submit__btn" title="Войти" methods="formSubmitLogin"
                        @formSubmitLogin="formSubmit()" />
                </div>
            </form>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BtnCopmonentBgGradient from '../../components/btnCopmonent/BtnCopmonentBgGradient.vue';
import * as yup from "yup";

const formShema = yup.object({
    login: yup
        .string()
        .required("login required"),
    password: yup
        .string()
        .required("password required")
});

interface IData {
    login: string,
    password: string,
    loginError: boolean,
    passwordError: boolean,
    showPasswod: boolean,
    successForm: boolean,
    checked: boolean,
}

export default defineComponent({
    name: 'AuthForm',
    components: {
        BtnCopmonentBgGradient,
    },
    data(): IData {
        return {
            login: '',
            password: '',
            loginError: false,
            passwordError: false,
            showPasswod: false,
            successForm: false,
            checked: false,
        };
    },
    methods: {
        getInputClass: function (atrr: string): string {
            let classInput = 'authform__input';
            if (atrr == 'login' && this.loginError) {
                classInput = 'authform__input authform__input__error'
            }

            if (atrr == 'password' && this.passwordError) {
                classInput = 'authform__input authform__input__error'
            }

            return classInput;
        },
        getIconPasswordClass() {
            if (this.showPasswod) {
                return 'authform__input__icon authform__input__icon__password__hidden';
            } else {
                return 'authform__input__icon authform__input__icon__password';

            }
        },
        setShowPassword() {
            if (this.showPasswod) {
                this.showPasswod = !this.showPasswod;
            } else {
                this.showPasswod = !this.showPasswod;
            }
        },
        async formSubmit() {
            await formShema.validate({ login: this.login, password: this.password }, { abortEarly: false })
                .then(async () => {
                    try {
                        this.successForm = true;
                        this.login = '';
                        this.password = '';
                        this.loginError = false;
                        this.passwordError = false;
                    } catch (err) {
                        // 
                    }
                })
                .catch((e) => {
                    e.errors.includes('login required') ? this.loginError = true : this.loginError = false;

                    e.errors.includes('password required') ?
                        this.passwordError = true : this.passwordError = false;

                    this.successForm = false;
                });
        },
        getAuthformContainer() {
            let classInput = 'authform__container';
            if (this.loginError || this.passwordError) {
                classInput = 'authform__container authform__container__error'
            }

            return classInput;
        }

    },
});
</script>

<style lang="scss" scoped>
.authform {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex: 1 0 auto;
    max-width: 1440px;
    width: 100%;
    min-height: 872px;
}

.authform__bg {
    width: 100%;
    background-image: url('@/assets/images/auth/bg.jpg');
    filter: blur(5вpx);
    background-size: cover;
    background-repeat: no-repeat;
}

.authform__bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgb(46, 52, 61);
    mask-image: linear-gradient(to right, rgba(46, 52, 61, 0.30) 0%, rgba(46, 52, 61, 0.30) 100%);
    z-index: 11;
}

.authform__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 531px;
    height: 464px;
    border-radius: 24px;
    background: $color-skills;
    padding: 24px;
    align-items: center;
    z-index: 10;
    box-sizing: border-box;
    position: absolute;
    z-index: 12;
    top: 200px;
    left: 50%;
    transform: translate(-50%);


    @media (max-width: 576px) {
        width: 320px;
        height: 488px;
        padding: 48px 24px;
    }
}

.authform__container__error {
    height: 550px;
}

.authform__title {
    font-family: "Gramatika-Medium";
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.5rem;
    background-image: linear-gradient(135deg, #7277FF 0%, #FF5A5A 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 18px;

    @media (max-width: 576px) {
        font-size: 2rem;
        line-height: 3rem;
    }
}

.authform__subtitle {
    color: $color-form-text;
    font-family: 'Inter-Regular';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    width: 414px;
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: 576px) {
        width: 272px;
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

.authform__form {
    display: flex;
    flex-direction: column;
    font-family: 'Inter-Regular';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    width: 100%;

}

.authform__label {
    color: $color-dark-E343D;
    margin-bottom: 8px;
}

.authform__input__wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
}

.authform__input__wrapper:nth-last-child(2) {
    margin-bottom: 40px;
}

.authform__input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    border-radius: 8px;
    color: $color-text-blue;
    padding-left: 16px;
}
.authform__input:hover {
    outline-style: solid;
    outline-width: 2px;
    outline-color: $color-hover;
}
.authform__input:focus {
    outline-style: solid;
    outline-width: 2px;
    outline-color: $color-form-text;
    color: $color-form-text;
}
.authform__input:active {
    outline-style: solid;
    outline-width: 2px;
    outline-color: $color-hover ;
}
.authform__input::placeholder {
    color: $color-text-blue;
}

.authform__input:focus::placeholder {
    color: $color-form-text;
}
.authform__input:active::placeholder {
    color: $color-text-blue;
}
.authform__input__error {
    border: 2px solid $color-primary-red;
}

.authform__input__error:focus {
    border: none;
    outline-style: solid;
    outline-width: 2px;
    outline-color: $color-text-blue;
}

.authform__input__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    top: 11px;
    transition: opacity .1s linear;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.authform__input:focus+.authform__input__icon__login svg path {
    fill: $color-form-text;
}

.authform__input:active+.authform__input__icon__login svg path {
    fill: $color-hover;
}

.authform__input__icon__password {
    background-image: url("@/assets/icons/authform/show_password.svg");
}

.authform__input:focus+.authform__input__icon__password {
    background-image: url("@/assets/icons/authform/show_password_focus.svg");
}

.authform__input:active+.authform__input__icon__password {
    background-image: url("@/assets/icons/authform/show_password_click.svg");
}

.authform__input__icon__password__hidden {
    background-image: url("@/assets/icons/authform/hidden_password.svg");
}

.authform__input:focus+.authform__input__icon__password__hidden {
    background-image: url("@/assets/icons/authform/hidden_password_focus.svg");
}

.authform__input:active+.authform__input__icon__password__hidden {
    background-image: url("@/assets/icons/authform/hidden_password_click.svg");
}

.authform__input:-webkit-autofill,
.authform__input:-webkit-autofill:hover,
.authform__input:-webkit-autofill:focus,
.authform__input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $color-bg inset !important;
}

.authform__input__wrapper .authform__input:-webkit-autofill {
    -webkit-text-fill-color: $color-text-blue !important;
}

.authform__input__wrapper .authform__input:-webkit-autofill:focus {
    -webkit-text-fill-color: $color-text-blue !important;
}

.authform__submit {
    display: flex;
    justify-content: space-between;
}

.authform__submit__checkbox {
    display: flex;
    align-items: center;
    align-self: baseline;

}

.authform__submit__input {
    display: none;
}

.authform__submit__label {
    font-family: "Inter-Regular";
    display: flex;
    align-items: center;
    user-select: none;
}

.authform__submit__input+*::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid $color-hover;
    margin: 0px 11px 2px 3px;
}

.authform__submit__input:checked+*::before {
    content: "✓";
    color: white;
    text-align: center;
    line-height: 1;
    background: $color-hover;
    border-color: $color-hover;
}

.authform__submit__btn {
    width: 80px;
}

.authform__error {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.authform__icon__error {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.authform__text__error {
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    font-style: normal;
    font-weight: 500;
    color: $color-primary-red;    
}

</style>