import { render, staticRenderFns } from "./ArticleHeader.vue?vue&type=template&id=719fc1bd&scoped=true&v-if=data.length%20%3E%200"
import script from "./ArticleHeader.vue?vue&type=script&lang=ts"
export * from "./ArticleHeader.vue?vue&type=script&lang=ts"
import style0 from "./ArticleHeader.vue?vue&type=style&index=0&id=719fc1bd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "719fc1bd",
  null
  
)

export default component.exports