<template>
    <section class="center__mission">
        <h2 class="center__mission__title">Наша миссия</h2>
        <div class="center__mission__wrap">
            <p class="center__mission__text-medium">Вдохновлять, развивать и сопровождать людей на профессиональном пути, обеспечивая высококачественное обучение, основанное на инновациях и персональном подходе. Мы стремимся создать образовательное сообщество, где каждый студент чувствует себя поддержанным, мотивированным и готовым к достижению новых высот.</p>
            <div class="center__mission__container">
                <p class="center__mission__text">Сделать дистанционное обучение максимально эффективным и приближённым к реальным условиям работы.</p>
                <p class="center__mission__text">Применять инновационные методики в образовании для подготовки высококвалифицированных специалистов.</p>
                <p class="center__mission__text">Предоставить доступное образование, через создание качественных инновационных учебных программ.</p>
                <p class="center__mission__text">Быть мостом к знаниям, развитию и профессиональному росту для каждого, кто решит пройти этот путь с нами.</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'CenterMissionComponent',
};
</script>

<style lang="scss" scoped>
.center__mission {
    margin: 140px auto 0 auto;
    box-sizing: border-box;
    max-width: 1440px;
    padding-left: 80px;
    padding-right: 80px;
  
    @media (max-width: 991px) {
      margin-top: 120px;
      padding-left: 60px;
      padding-right: 60px;
    }
  
    @media (max-width: 576px) {
      margin-top: 55px;
      padding-left: 16px;
      padding-right: 16px;
    }

}
.center__mission__title {
    font-family: 'Gramatika-Medium';
    font-size: 3rem;
    font-weight: 400;
    line-height: 4.5rem;
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
    max-width: 310px;
    margin-bottom: 25px;

    @media (max-width: 991px){
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 18px;
    }

    @media (max-width: 576px){
        font-size: 1.75rem;
        line-height: 2.625rem;
    }
}
.center__mission__wrap {
    display: flex;

    @media (max-width: 991px){
        flex-direction: column;
    }
}
.center__mission__text-medium {
    display: inline-block;
    align-items: center;
    font-family: 'Inter-Medium';
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.875rem;
    color: $color-dark-E343D;
    width: 41%;
    padding: 20px 25px 20px 0px;
    
    @media (max-width: 991px){
        width: 100%;
        padding-right: 0px;
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
@media (min-width: 992px){
    .center__mission__text-medium:hover {
        background: linear-gradient(110deg, $color-dark-E343D 65%, #7277FF 70%, #FF5A5A 75%, $color-dark-E343D 80%);
        background-size: 200% auto;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: textflow 2s linear infinite;
    }
    @keyframes textflow {
        from {background-position: 200% center}         
        to {background-position: 0% center}
      }
}

@media (max-width: 991px){
    .center__mission__text-medium {
        background: linear-gradient(110deg, $color-dark-E343D 65%, #7277FF 70%, #FF5A5A 75%, $color-dark-E343D 80%);
        background-size: 200% auto;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: textflow 2s linear infinite;
    }
    @keyframes textflow {
        from {background-position: 200% center}         
        to {background-position: 0% center}
      }
}
.center__mission__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 0px 20px 44px;
    width: 61%;
    border-left: 3px solid transparent;
    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(180deg, #7277FF 0%, #FF5A5A 100%) border-box;


    @media (max-width: 991px){
        border-left: none;
        padding: 0px;
        width: 100%;
        gap: 20px;
    }
}

.center__mission__text {
    font-family: 'Inter-Regular';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: $color-dark-E343D;

    @media (max-width: 991px){
        padding-left: 8px;
        border-left: 3px solid transparent;
        background: linear-gradient(#fff, #fff) padding-box, linear-gradient(180deg, #7277FF 0%, #FF5A5A 100%) border-box;
    }
}
</style>