<template>
  <div class="description">
    <div class="description__header">
      <h1 class="description__header__title">
        <span class="description__header__title__color"> Бесплатные </span>уроки
        и полезные материалы
      </h1>
      <p class="description__header__content">
        Мы верим, что такая возможность поможет вам принять взвешенное решение
        об обучении на нашей платформе
      </p>
    </div>
    <div class="description__content">
      <div class="description__subtitle">
        <p class="description__subtitle__text">
          Не упустите шанс познакомиться с нашими курсами бесплатно и
          попробовать практические задания в виртуальной реальности
        </p>
      </div>
      <div class="description__item">
        <img
          src="../../../assets/icons/freeLearn/free-learn-header1.svg"
          alt="icon"
          class="description__item__icon"
        />
        <p class="description__item__text">
          Оцените возможности и преимущества дистанционного обучения с практикой
          в виртуальной реальности
        </p>
      </div>
      <div class="description__item">
        <img
          src="../../../assets/icons/freeLearn/free-learn-header2.svg"
          alt="icon"
          class="description__item__icon"
        />
        <p class="description__item__text">
          Узнайте каким образом виртуальная реальность применяется в обучении
          специалистов монтажа и сервисного обслуживания климатического
          оборудования
        </p>
      </div>
      <div class="description__item">
        <img
          src="../../../assets/icons/freeLearn/free-learn-header3.svg"
          alt="icon"
          class="description__item__icon"
        />
        <p class="description__item__text">
          Получите предварительное представление о содержании курсов, качестве
          образовательных материалов и методики обучения
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FreeLearnDescription",
};
</script>

<style lang="scss" scoped>
.description {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 120px 80px 48px 80px;

  @media (max-width: 991px) {
    padding: 92px 60px 60px 60px;
    gap: 37px;
  }

  @media (max-width: 576px) {
    padding: 71px 16px 48px 16px;
  }
}

.description__header {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: 576px) {
    gap: 21px;
  }
}

.description__header__title {
  font-family: Gramatika-Medium;
  font-size: 4rem;
  font-weight: 400;
  line-height: 6rem;
  color: $color-dark-E343D;
  max-width: 670px;
  flex-shrink: 0;

  @media (max-width: 991px) {
    font-size: 3rem;
    line-height: 4.5rem;
  }

  @media (max-width: 576px) {
    font-size: 2rem;
    line-height: 3rem;
  }

  &__color {
    background: $color-gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.description__header__content {
  max-width: 304px;
  color: $color-dark-E343D;
  font-family: Inter-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 30px;
  margin-right: 8px;

  @media (max-width: 1280px) {
    margin: 0;
    max-width: 564px;
  }
}

.description__content {
  display: flex;
  gap: 16px;
  justify-content: space-between;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
    gap: 22px;
  }
}

.description__subtitle {
  width: 306px;
  flex-shrink: 0;
  margin-right: 6px;

  @media (max-width: 1280px) {
    width: 48%;
    margin-right: 0;
    flex-shrink: 1;
  }

  @media (max-width: 692px) {
    width: 100%;
  }
}

.description__subtitle__text {
  color: $color-dark-E343D;
  font-family: Inter-Medium;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;

  @media (max-width: 1280px) {
    max-width: 312px;

    @media (max-width: 692px) {
      max-width: 100%;
    }
  }
}

.description__item {
  width: 23.2%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px 16px 16px 32px;
  box-sizing: border-box;
  border-left: 4px solid $color-blue;

  @media (max-width: 1280px) {
    width: 48%;
  }

  @media (max-width: 692px) {
    width: 100%;
  }

  @media (max-width: 576px) {
    padding: 16px;
  }
}

.description__item__icon {
  width: 100px;
  height: 100px;
}
.description__item__text {
  color: $color-dark-E343D;
  font-family: Inter-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
}
</style>