<template>
  <div class="choose-course">
    <div class="choose-course__wrapper">
      <div class="choose-course__content">
        <p class="choose-course__text">
          Не упустите возможность обучиться в уникальной виртуальной среде,
          моделирующей реалистичные условия работы и ремонта оборудования.
        </p>
        <BtnCopmonentBgGradient
          class="choose-course__btn"
          title="Выбрать курс"
          methods="linkToCourse"
          @linkToCourse="linkToCourse()"
          :fontSize="btnFontSize"
          :lineHeight="btnLineHeight"
        />
      </div>
      <div class="choose-course__wrapper-img">
        <img
          class="choose-course__img"
          src="@/assets/images/blog/img.png"
          alt="img"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BtnCopmonentBgGradient from "@/components/btnCopmonent/BtnCopmonentBgGradient.vue";

export default defineComponent({
  name: "ChooseCourse",
  components: {
    BtnCopmonentBgGradient,
  },
  props: {
    btnFontSize: Number,
    btnLineHeight: Number,
  },
  methods: {
    linkToCourse(): void {
      this.$router.push({ name: "courses" });
    },
  },
});
</script>

<style lang="scss" scoped>
.choose-course {
  margin-top: 196px;
  position: relative;
  left: -80px;
  width: calc(100% + 160px);

  @media (max-width: 1280px) {
    margin-top: 108px;
  }

  @media (max-width: 991px) {
    left: -60px;
    width: calc(100% + 120px);
  }

  @media (max-width: 576px) {
    left: -16px;
    width: calc(100% + 32px);
  }

  &__wrapper {
    background-color: $color-questions;
    display: flex;
    padding: 40px 80px;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 5px;

    @media (max-width: 991px) {
      padding: 20px 60px;
    }

    @media (max-width: 576px) {
      padding: 20px 16px;
      flex-direction: column;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 671px;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__text {
    font-family: Gramatika-Medium;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    max-width: 524px;
    flex-shrink: 1;

    @media (max-width: 991px) {
      font-size: 1rem;
      line-height: 1.5rem;
      max-width: 306px;
    }

    @media (max-width: 576px) {
      max-width: 100%;
      width: 100%;
    }
  }

  &__btn {
    height: 40px;
    padding: 8px 8px;
    width: 131px;
    flex-shrink: 0;
  }

  &__wrapper-img {
    width: 476px;
    height: 90px;

    @media (max-width: 1280px) {
      width: 316px;
    }

    @media (max-width: 776px) {
      width: 236px;
      flex-shrink: 0;
    }

    @media (max-width: 576px) {
      width: 123px;
      height: 80px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &__img {
    position: absolute;
    width: 476px;
    height: 311px;
    bottom: 0;

    @media (max-width: 1280px) {
      width: 354px;
      height: 231px;
    }

    @media (max-width: 776px) {
      width: 280px;
      height: 186px;
    }

    @media (max-width: 576px) {
      width: 123px;
      height: 80px;
    }
  }
}
</style>