<template>
  <section class="center__history">
    <div class="center__history__container">
      <h2 class="center__history__title">История учебного центра</h2>
      <p class="center__history__text">
        10-летний опыт работы, позволяет нам реализовывать масштабные
        образовательные проекты, включая международные. Мы стремимся к
        саморазвитию, обновляем свои знания, активно внедряем новейшие
        технологии и постоянно развиваем наш бизнес.
      </p>

      <div class="timeline">
        <CenterHistoryComponentItem
          v-for="(data, i) in datas"
          :data="data"
          :index="i"
          :key="i"
          :open="data.open"
          @toggleOpen="toggleOpen"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CenterHistoryComponentItem from "./CenterHistoryComponentItem.vue";
export default {
  name: "CenterHistoryComponent",
  components: {
    CenterHistoryComponentItem,
  },

  data() {
    return {
      datas: [
        {
          id: 0,
          year: "2017",
          status:
            "Мы принимаем активное участие в процессе открытия учебных центров, где обучают сотрудников региональных центров передового опыта для стран, входящих в RON A5.",
          open: true,
        },
        {
          id: 1,
          year: "2019",
          status:
            "Эксперты учебного центра «Верконт Сервис» разработали учебные пособия, чтобы улучшить теоретическую подготовку обучающихся. Мы стремимся предоставить качественные материалы, которые помогут более глубоко усвоить учебную программу.",
          open: false,
        },
        {
          id: 2,
          year: "2022",
          status:
            "Мы участники Проекта «Арктика» - создание регионального учебного центра для обучения экологически безопасным методам работы с природными хладагентами, ОРВ и Ф-газами, а также технологиям, которые не наносят вред озоновому слою и климату Земли.",
          open: false,
        },
        {
          id: 3,
          year: "2023",
          status:
            "Проект «Фреон» – создание инновационного учебного центра для курсов дистанционного повышения квалификации с применением технологий виртуальной реальности.",
          open: false,
        },
      ],
      index: 0,
      timerId: null,
    };
  },

  methods: {
    toggleOpen: function (index) {
      this.index = index;
      this.datas = this.datas.map((data, i) => {
        if (index === i) {
          data.open = !data.open;
        } else {
          data.open = false;
        }
        return data;
      });
    },

    nextYear: function () {
      setInterval(() => {
        this.index++;
        if (this.index > 3) {
          this.index = 0;
        }
        this.toggleOpen(this.index)
      }, 5000);
    },
  },
  mounted() {
    this.timerId = this.nextYear();
  },
};
</script>

<style lang="scss" scoped>
.center__history {
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 1440px;
  background-color: $color-dark-E343D;
}
.center__history__container {
  padding: 30px 80px 158px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 991px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (max-width: 576px) {
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.center__history__container::before{
  position: absolute;
  content: '';
  top: 0px;
  left: 0px;
  width: 60px;
  height: 100%;

  @media (max-width: 768px){
      background: linear-gradient(to right,  #21252bb8 0%, transparent 100%);    
  }

  @media (max-width: 576px){
      width: 16px;
  }
}

.center__history__container::after{
  position: absolute;
  content: '';
  top: 0px;
  right: 0px;
  width: 60px;
  height: 100%;

  @media (max-width: 768px){
      background: linear-gradient(to left,  #21252bb8 0%, transparent 100%);    
  }

  @media (max-width: 576px){
      width: 16px;
  }
}


.center__history__title {
  font-family: "Gramatika-Medium";
  font-size: 3rem;
  font-weight: 400;
  line-height: 4.5rem;
  margin-bottom: 43px;
  color: $color-text;

  @media (max-width: 991px) {
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 38px;
  }
  @media (max-width: 576px) {
    font-size: 1.75rem;
    line-height: 2.625rem;
  }
}
.center__history__text {
  font-family: "Inter-Medium";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;
  color: $color-text;
  margin-bottom: 37px;
  text-align: center;
  max-width: 849px;

  @media (max-width: 991px) {
    font-size: 1.25rem;
    line-height: 1.875rem;
    max-width: 570px;
  }
  @media (max-width: 576px) {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 32px;
  }
}

.timeline {
  display: flex;
  justify-content: space-between;
  padding: 0;
  text-align: center;
  position: relative;
  width: 100%;
}

.timeline::before {
  position: absolute;
  content: "";
  width: calc(100% + 160px);
  height: 5px;
  background: $color-hover;
  top: 88px;
  left: -80px;

  @media (max-width: 991px) {
    width: calc(100% + 120px);
    left: -60px;
  }

  @media (max-width: 576px) {
    width: calc(100% + 32px);
    left: -16px;
    top: 72px;
  }
}
</style>