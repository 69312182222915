<template>
    <div :class="open ? 'swiper-slide open' : 'swiper-slide'" @click="$emit('toggleOpen', index, $event)">
        <div class="timestamp"><span class="date">{{data.year}}</span></div>
        <div class="status">{{data.status}}</div>
	</div>
</template>

<script>
export default {
    name: 'CenterHistoryComponentItem',

    props: ['data', 'index', 'open']
};
</script>

<style lang="scss" scoped>
.swiper-slide{
    position: relative;
    width: 25%;
}
.timestamp {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    position: relative;
}

.timestamp:before {
    content: '';
    width: 21px;
    height: 21px;
    background-color: $color-hover;
    border-radius: 50%;
    position: absolute;
    top: 81px;
    cursor: pointer;

    @media (max-width: 576px){
        top: 64px;
    }
}
.timestamp:hover:before,
.open .timestamp:before{
    width: 40px;
    height: 40px;
    top: 70px;

    @media (max-width: 576px){
        width: 30px;
        height: 30px;
        top: 59px;    
    }
}
.open .timestamp:after{
    position: absolute;
    content: "";
    width: 3px;
    height: 35px;
    background: #7277FF;
    top: 102px;

    @media (max-width: 576px){
        top: 78px;
    }
}

.date{
    font-family: "Gramatika-Medium";
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
    width: 74px;
    user-select: none;

    @media (max-width: 576px){
        font-size: 1.5rem;
        font-weight: 400;
        width: 56px;
    }
}

.timestamp:hover .date,
.open .date{
    font-size: 3rem;
    line-height: 4.5rem;
    width: 112px;
    margin-top: -16px;

    @media (max-width: 576px){
        font-size: 2rem;
        line-height: 3rem;
        width: 74px;
        margin-top: -6px;
    }
}


.status {
    font-family: "Inter-Regular";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;  
    color: $color-text;  
    border-top: 3px solid $color-hover;
    position: absolute;
    padding-top: 6px;
    top: 136px;
    display: none;

    @media (max-width: 991px){
        font-size: 1rem;
        line-height: 1.5rem;    
    }

    @media (max-width: 576px){
        top: 111px;
    }
}
.open .status{
    display: block;
}
.swiper-slide:first-child .status{
    width: 483px;

    @media (max-width: 576px){
        max-width: 268px;
    }
}
.swiper-slide:nth-child(2) .status{
    left: -105px;
    width: 685px;

    @media (max-width: 991px){
        width: 460px;
    }
    @media (max-width: 576px){
        max-width: 268px;
        left: -68px;
    }

}
.swiper-slide:nth-child(3) .status{
    right: -195px;
    width: 721px;

    @media (max-width: 991px){
        width: 460px;
        right: -132px;
    }
    @media (max-width: 576px){
        max-width: 268px;
        right: -65px;
    }

}

.swiper-slide:last-child .status{
    right: 0px;
    width: 460px;
    @media (max-width: 576px){
        max-width: 268px;
    }

}

</style>