<template>
    <section class="center__partners">
        <h2 class="center__partners__title">Партнеры</h2>

        <div class="center__partners__container">
          <div class="center__partners__wrap center__partners__wrap_upper">
            <template v-for="(item, index) in dataPartnersUp">
              <template v-if="item.length <= 10">
                <img class="center__partners__img" :src="getImg(item)" :key="keyId(index)"/>
              </template>
              <template v-else>
                <img class="center__partners__img" :src="getImg(item)" :key="keyId(index)"/>
              </template>
            </template>
    
            <template v-for="(item, index) in dataPartnersUp">
              <template v-if="item.length <= 10">
                <img class="center__partners__img" :src="getImg(item)" :key="keyId(index)"/>
              </template>
              <template v-else>
                <img class="center__partners__img" :src="getImg(item)" :key="keyId(index)"/>
              </template>
            </template>
          </div>
    
          <div class="center__partners__wrap center__partners__wrap_lower">
            <template v-for="(item, index) in dataPartnersDown">
              <template v-if="item.length <= 10">
                <img class="center__partners__img" :src="getImg(item)" :key="keyId(index)"/>
              </template>
              <template v-else>
                <img class="center__partners__img" :src="getImg(item)" :key="keyId(index)"/>
              </template>
            </template>
    
            <template v-for="(item, index) in dataPartnersDown">
              <template v-if="item.length <= 10">
                <img class="center__partners__img" :src="getImg(item)" :key="keyId(index)"/>
              </template>
              <template v-else>
                <img class="center__partners__img" :src="getImg(item)" :key="keyId(index)"/>
              </template>
            </template>
          </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { v4 as uuid } from "uuid";

export default defineComponent({
    name: 'CenterPartnersComponent',
    data() {
    return {
      dataPartnersUp: [
        "informteh.png",
        "sauermann.png",
        "remak.png",
        "panasonic.png",
        "unido.png",
        "clivet.png",
        "daikin.png",
        "rusalumtrub.png",
        "euroklimat.png" ,
        "samsung.png",
      ],
      dataPartnersDown: [
        "mitsubish.png",
        "adv.png",
        "aristone.png",
        "rusenergo.png",
        "cherbrooke.png",
        "camfil.png",
        "bacs.png",
        "greenstandart.png",
        "flaktwood.png",
        "testo.png",
      ],
    };
  },
  methods: {
    keyId(index: number): string {
      return index + uuid();
    },

    getImg(item: string) {
            return require('../../../assets/images/center/' + item);
        },

  },

});
</script>

<style lang="scss" scoped>
  @keyframes move-left {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-2553px + (-62px));
    }
  }
  
  @keyframes move-right {
    from {
      transform: translate(-2611px);
    }
  
    to {
      transform: translate(0);
    }
  }
  
  @keyframes move-left-991 {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-1838px + (-27px));
    }
  }
  
  @keyframes move-right-991 {
    from {
      transform: translate(-1865px);
    }
  
    to {
      transform: translate(0);
    }
  }
  
  @keyframes move-right-390 {
    from {
      transform: translate(-2265px + (-26px));
    }
  
    to {
      transform: translate(0);
    }
  }

  .center__partners{
    margin: 138px auto 0 auto;
    box-sizing: border-box;
    max-width: 1440px;

    @media (max-width: 991px){
      margin-top: 114px;
    }

    @media (max-width: 576px){
      margin-top: 52px;
    }
}
.center__partners__title{
    font-family: 'Gramatika-Medium';
    font-size: 3rem;
    font-weight: 400;
    line-height: 4.5rem;
    background-image: $color-gradient;
    background-clip: text;
    color: transparent;
    max-width: 231px;
    margin-bottom: 56px;
    padding-left: 80px;
    
    @media (max-width: 991px) {
        padding-left: 60px;
        font-size: 2rem;
        line-height: 3rem;    
        margin-bottom: 32px;
      }
    
    @media (max-width: 576px) {
        padding-left: 16px;
        font-size: 1.75rem;
        line-height: 2.625rem;
    }
  }

  .center__partners__container {
    overflow: hidden;
  }
  
  .center__partners__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 64px;
    margin-left: 80px;
    animation: move-left linear 40s infinite;
  
    @media (max-width: 991px) {
      margin-left: 0;
      animation: move-left-991 linear 40s infinite;
    }
  }
  
  .center__partners__wrap_upper {
    margin-bottom: 48px;

    @media (max-width: 991px){
      margin-bottom: 40px;
    }
  }
  
  .center__partners__wrap_lower {
    margin-left: -253px;
    animation: move-right linear 40s infinite;
  
    @media (max-width: 991px) {
      margin-left: -149px;
      animation: move-right-991 linear 40s infinite;
    }
  }
  
  .center__partners__img {
    filter: grayscale(1);
    height: 80px;

    @media (max-width: 991px){
      height: 48px;
    }
  }
  
</style>