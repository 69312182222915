<template>
  <section :class="changeBgSection">
    <div class="main__info__header">
      <h2 class="main__info__title">Об учебном центре</h2>
      <h3 class="main__info__description">
        Наша команда экспертов обучает специалистов уже более 10 лет, делая
        акцент на доступности высококачественного образования. Новый формат
        виртуальной среды обучения позволяет развивать практические навыки в
        любом месте и удобное время.
      </h3>
    </div>
    <div class="main__info__container__video">
      <BtnCopmonentArrow
        :class="changeBg"
        title="Начать учиться бесплатно"
        :width_arrow="widthArrow"
        :margin_right="marginRight"
        methods="linkToFreeLearn"
        @linkToFreeLearn="linkToFreeLearn()"
      />
      <div class="main__info__video__wraper">
        <video
          @click="playVideo"
          class="main__info__video"
          controls
          playsinline
          poster="@/assets/images/video-ab-delete.jpg"
          allowfullscreen
          frameborder="0"
        >
          <source src="@/assets/video/video.mp4" type="video/mp4;" />
        </video>
        <img
          class="main__info__video__play"
          :style="hiddenButton"
          :src="iconVideo"
          @mouseover="mouseover($event)"
          @mouseout="mouseout($event)"
          alt="icon play video"
          @click="playVideoBtn()"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import BtnCopmonentArrow from "../../btnCopmonent/BtnCopmonentArrow.vue";
import store from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainAboutComponent",
  components: {
    BtnCopmonentArrow,
  },
  data() {
    return {
      checkScrollBg: false,
      play: false,
      showButton: false,
    };
  },
  methods: {
    playVideoBtn() {
      this.play = !this.play;
      let video = document.querySelector(
        ".main__info__video"
      ) as HTMLVideoElement;
      this.play ? video.play() : video.pause();
    },
    playVideo() {
      this.play = !this.play;
    },
    mouseover(event: MouseEvent) {
      let target = event.target as HTMLImageElement;
      if (this.play) {
        target.classList.add("main__info__video__play__show");
        target.classList.remove("main__info__video__play__hidden");
      }
    },
    mouseout(event: MouseEvent) {
      let target = event.target as HTMLImageElement;
      if (!this.play) {
        target.classList.remove("main__info__video__play__show");
      } else {
        target.classList.remove("main__info__video__play__show");
        target.classList.add("main__info__video__play__hidden");
      }
    },
    linkToFreeLearn() {
      this.$router.push({ name: "free-learn" });
    },
  },
  computed: {
    changeBg(): string {
      return store.getters.getCheckScrollBg
        ? "main__info__btn  main__info__btn__bg"
        : "main__info__btn";
    },
    changeBgSection(): string {
      return store.getters.getCheckScrollBg
        ? "main__info  main__info__bg"
        : "main__info";
    },
    widthArrow(): string {
      return innerWidth <= 991 ? "22" : "40";
    },

    marginRight(): string {
      return innerWidth <= 991 ? "12" : "24";
    },
    iconVideo(): string {
      return this.play
        ? require("@/assets/icons/stop.svg")
        : require("@/assets/icons/play.svg");
    },
    hiddenButton(): string {
      return innerWidth > 991 ? " display: absolute" : "display: none";
    },
  },
});
</script>

<style lang="scss" scoped>
.main__info {
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
  padding: 62px 80px 0 80px;
  display: flex;
  flex-direction: column;
  background: $color-bg;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;

  @media (max-width: 991px) {
    padding: 62px 60px 0 60px;
  }

  @media (max-width: 576px) {
    padding: 62px 16px 0 16px;
  }
}

.main__info__bg {
  background: $color-dark-E343D;
}

.main__info__header {
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.main__info__title {
  color: $color-text;
  font-family: "Gramatika-Medium";
  font-size: 3rem;
  line-height: 4.5rem;
  font-weight: 400;
  margin-top: -20px;

  @media (max-width: 991px) {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.main__info__description {
  width: 355px;
  margin: 0 23px 0 0;
  font-family: "Inter-Regular";
  font-size: 1rem;
  line-height: 1.5rem;
  color: $color-text-blue;

  @media (max-width: 1280px) {
    margin: 0;
  }

  @media (max-width: 991px) {
    margin-top: 30px;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
  }

  @media (max-width: 576px) {
    margin-top: 32px;
  }
}

.main__info__container__video {
  position: relative;

  @media (max-width: 991px) {
    margin-top: 24px;
  }

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }
}

.main__info__video__wraper {
  position: relative;
}

.main__info__video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s ease-in-out;
  animation-duration: 3s;

  @media (max-width: 991px) {
    width: 42px;
    height: 42px;
  }

  @media (max-width: 576px) {
    width: 48px;
    height: 48px;
  }
}

.main__info__video__play__show {
  transition: 0.5s ease-in-out;
  opacity: 1;
}

.main__info__video__play__hidden {
  transition: 0.5s ease-in-out;
  opacity: 0;
}

.main__info__video {
  width: 100%;
  max-height: 610px;
  border-radius: 25px;
  background: $color-dark-E343D;

  @media (max-width: 768px) {
    max-height: 310px;
  }

  @media (max-width: 576px) {
    min-height: 140px;
    max-height: 299px;
    margin-top: 14px;
  }
}

.main__info__btn {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 62px 17px 52px;
  background: $color-bg;
  border-bottom-left-radius: 25px;
  font-size: 1.25rem;
  line-height: 1.875rem;

  @media (max-width: 1280px) {
    padding: 15px 40px 17px 51px;
  }

  @media (max-width: 991px) {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 3px 41px 8px 29px;
    border-bottom-left-radius: 15px;
  }

  @media (max-width: 991px) {
    padding: 3px 5px 8px 15px;
  }

  @media (max-width: 576px) {
    position: relative;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 8px 0;
    margin: 0 auto;
  }
}

.main__info__btn__bg {
  background: $color-dark-E343D;
}

.main__info__btn::after {
  content: "";
  position: absolute;
  left: -23px;
  top: -1px;
  width: 25px;
  height: 25px;
  background-image: url("@/assets/icons/button-angle-white.svg");

  @media (max-width: 576px) {
    display: none;
  }
}

.main__info__btn__bg::after {
  content: "";
  z-index: 100;
  position: absolute;
  left: -23px;
  top: -1px;
  width: 25px;
  height: 25px;
  background-image: url("@/assets/icons/button-angle.svg");

  @media (max-width: 576px) {
    display: none;
  }
}

.main__info__btn::before {
  content: "";
  position: absolute;
  right: -1px;
  bottom: -22px;
  width: 25px;
  height: 25px;
  background-image: url("@/assets/icons/button-angle-white.svg");

  @media (max-width: 576px) {
    display: none;
  }
}

.main__info__btn__bg::before {
  content: "";
  position: absolute;
  z-index: 100;
  right: -1px;
  bottom: -22px;
  width: 25px;
  height: 25px;
  background-image: url("@/assets/icons/button-angle.svg");

  @media (max-width: 576px) {
    display: none;
  }
}
</style>
