import { render, staticRenderFns } from "./ConsentPersonalPage.vue?vue&type=template&id=3cb7be40&scoped=true"
import script from "./ConsentPersonalPage.vue?vue&type=script&lang=ts"
export * from "./ConsentPersonalPage.vue?vue&type=script&lang=ts"
import style0 from "./ConsentPersonalPage.vue?vue&type=style&index=0&id=3cb7be40&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb7be40",
  null
  
)

export default component.exports