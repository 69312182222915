<template>
    <div class="main__review__card">
        <p class="main__review__card__text">{{review.text}}</p>
        <div class="main__review__card__wrap">
            <img :src="getImg(review.img)" alt="photo" class="main__review__card__img">
            <ul class="main__review__card__list">
                <li class="main__review__card__list__name">{{review.name}}</li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MainReviewsItem',
    props: ['review'],
    methods: {
        getImg(pic) {
            return require('@/assets/icons/' + pic);
        },
    },
    }
</script>

<style lang="scss" scoped>
.main__review__card{
    background-color: $color-questions;
    border-radius: 24px;
    box-sizing: border-box;
    max-width: 416px;
    padding: 24px;
    margin-bottom: 16px;
    font-family: 'Inter-Regular';
    font-size: 1rem;
    line-height: 1.5rem;
    color: $color-dark-E343D;
    
    @media (max-width: 768px) {
        max-width: 648px;
    }
}
.main__review__card__text{
    margin-bottom: 24px;
}
.main__review__card__wrap{
    display: flex;
    align-items: center;
}
.main__review__card__img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 24px;
}
.main__review__card__list{
    list-style: none;
}
.main__review__card__list__name{
    font-weight: 600;
}

</style> 