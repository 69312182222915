<template>
  <div class="program" id="program">
    <h2 class="program__title">Программа курса</h2>
    <div class="program__list">
      <div
        class="program__item"
        v-for="(item, index) in data?.modules"
        :key="index"
      >
        <div
          :class="
            index === activeIndex
              ? 'program__item__header program__item__header__active'
              : 'program__item__header'
          "
        >
          <p class="program__item__count">/{{ getCount(index) }}</p>
          <p class="program__item__title">{{ item.name }}</p>
          <button class="program__item__btn" v-if="index === activeIndex">
            <img
              src="@/assets/icons/course/arrow_active.svg"
              alt="icon"
              class="program__item__btn__icon"
              @click="setActive(-1)"
            />
          </button>
          <button class="program__item__btn" v-else>
            <img
              src="@/assets/icons/course/arrow.svg"
              alt="icon"
              class="program__item__btn__icon"
              @click="setActive(index)"
            />
          </button>
        </div>
        <div class="program__item__description" v-if="index === activeIndex">
          <p class="program__item__description__text">
            (Контент из поля “О модуле”) Описание модуля: зачем он нужен, в чем
            его ключевые особенности, количества часов теоретических занятий,
            видеоуроков, часов практики VR. Колличество символов от 200 до 400
          </p>
        </div>
        <div class="program__item__list" v-if="index === activeIndex">
          <div
            class="program__item__list__text"
            v-for="(itemThemes, i) in item.themes"
            :key="'A' + i"
          >
            {{ itemThemes.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProgramCourse",
  props: ["data"],

  data() {
    return {
      activeIndex: -1,
    };
  },
  methods: {
    getCount(index: number) {
      return index < 10 ? "0" + (index + 1) : index + 1;
    },
    setActive(index: number) {
      this.activeIndex = index;
    },
  },
});
</script>

<style lang="scss" scoped>
.program {
  margin-top: 148px;
  display: flex;
  padding: 0 0 100px 0;
  flex-direction: column;
  gap: 48px;

  @media (max-width: 991px) {
    margin-top: 64px;
    padding: 0 0 24px 0;
  }

  @media (max-width: 576px) {
    margin-top: 148px;
    padding: 0 0 16px 0;
  }
}

.program__title {
  color: $color-dark-E343D;
  font-family: Gramatika-Medium;
  font-size: 3rem;
  font-weight: 400;
  line-height: 4.5rem;

  @media (max-width: 991px) {
    font-size: 2.25rem;
    line-height: 3.375rem;
  }

  @media (max-width: 576px) {
    font-size: 1.75rem;
    line-height: 2.625rem;
  }
}

.program__list {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.program__item {
  display: flex;
  flex-direction: column;
}

.program__item__header {
  display: flex;
  gap: 24px;
  padding: 0 16px;
  align-items: center;
  border-bottom: 1px solid #545f6f;

  @media (max-width: 576px) {
    gap: 8px;
    padding: 8px;
  }

  &__active {
    border-bottom: 1px solid #fff;
    background: #efefef;
    border-radius: 16px 16px 0 0;
  }
}

.program__item__count {
  font-family: Inter-Regular;
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
}

.program__item__title {
  color: $color-dark-E343D;
  font-family: Gramatika-Medium;
  font-size: 2rem;
  line-height: 3rem;
  margin-top: -6px;
  flex-grow: 1;

  @media (max-width: 991px) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  @media (max-width: 576px) {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

.program__item__btn {
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background: none;

  &__active {
    background: inherit;
  }
}

.program__item__description {
  padding: 16px 0 16px 96px;
  background: #efefef;
  border-radius: 0 0 16px 16px;

  @media (max-width: 576px) {
    padding: 16px 8px;
  }
}

.program__item__description__text {
  max-width: 784px;
  color: $color-dark-E343D;
  font-family: Inter-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
}

.program__item__list {
  width: 100%;
  padding-left: 80px;
  box-sizing: border-box;

  @media (max-width: 576px) {
    padding-left: 0;
  }
}

.program__item__list__text {
  padding: 8px 16px;
  color: $color-form-fon;
  font-family: Gramatika-Medium;
  font-size: 1.25rem;
  line-height: 1.875rem;
  border-bottom: 1px solid $color-card-foto;

  @media (max-width: 576px) {
    padding: 8px;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>