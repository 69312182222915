<template>
  <div>
    <HeadersComponent />
    <FreeLearnComponent />
    <FooterComponent class="footer__blog" hiddenBtn="true" />
  </div>
</template>

<script lang="ts">
import HeadersComponent from "../components/headers/Headers.vue";
import FreeLearnComponent from "../components/freeLearn/FreeLearnComponent.vue";
import FooterComponent from "../components/footer/Footer.vue";

export default {
  name: "FreeLearnPage",
  components: {
    HeadersComponent,
    FreeLearnComponent,
    FooterComponent,
  },
  metaInfo: {
    title: "Бесплатные курсы обслуживания климатического оборудования",

    meta: [
      {
        name: "keywords",
        content:
          "бесплатные уроки, бесплатные материалы, виртуальная реальность в обучении, учебные пособия, практические задания, дистанционное обучение, пробные уроки",
      },
      {
        name: "description",
        content:
          "Пробные практические задания в виртуальной реальности | Бесплатные учебные пособия и материалы в сфере монтажа и сервисного обслуживания климатического оборудования",
      },
    ],
  },
};
</script>

<style lang="scss" scoped>
.footer__blog {
  margin-top: 0;
}
</style>