<template>
  <div class="pdf">
    <embed
      src="
        consent-personal-prof.pdf"
      type="application/pdf"
      width="100%"
      height="100%"
    />
  </div>
</template>

<script lang="ts" >
export default {
  name: "ConsentPersonalPage",
};
</script>

<style lang="scss" scoped>
.pdf {
  width: 100%;
  height: 100vh;
}
</style>