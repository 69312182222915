<template v-if="data.length > 0"  >
  <div class="article__header" :style="getBackgroundImg">
    <div class="article__header__tags">
      <router-link :to="toBlogGategory" class="article__header-tag">
        {{ data.tags[0]?.name }}</router-link
      >

      <router-link :to="toBlogHashTag" class="article__header-tag">
        {{ data.tags[1]?.name }}
      </router-link>
    </div>
    <h2 class="article__header__title">
      {{ data.name }}
    </h2>
    <div class="article__header__wraper">
      <div class="article__header__icons">
        <div class="article__header__icon">
          <img
            src="@/assets/icons/blog/like.svg"
            class="article__header__icon-img"
          />
          <p class="article__header__icon-count">
            {{ data.likes || 0 }}
          </p>
        </div>
        <div class="article__header__icon">
          <img
            src="@/assets/icons/blog/show.svg"
            class="article__header__icon-img"
          />
          <p class="article__header__icon-count">
            {{ data.views || 0 }}
          </p>
        </div>
      </div>
      <p class="article__header__date">{{ data.date_begin || "" }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import IBlog from "@/interface/blog/IBlog";

export default defineComponent({
  name: "ArticleHeader",
  props: {
    data: {
      type: Object as PropType<IBlog>,
    },
  },
  computed: {
    getBackgroundImg: function (): string {
      return "backgroundImage: url(" + this.data?.avatar + ")";
    },
    toBlogGategory: function (): string {
      return "/blog?category=" + this.data?.tags[0].id;
    },
    toBlogHashTag: function (): string {
      return "/blog?hash_tag=" + this.data?.tags[1].id;
    },
  },
});
</script>

<style lang="scss" scoped>
.article__header {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 58px;
  padding: 32px 0 32px 107px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  width: 100%;
  z-index: 0;
  min-height: 288px;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: black;
    border-radius: 24px;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7) 73.62%,
      rgba(0, 0, 0, 0.1) 100%
    );

    @media (max-width: 991px) {
      border-radius: 0;
    }

    @media (max-width: 576px) {
      border-radius: 0;
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.7) 100%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  @media (max-width: 991px) {
    padding: 16px 60px;
    border-radius: 0;
    left: -60px;
    box-sizing: initial;
    min-height: (172px - 32px);
    margin-top: 27px;
  }

  @media (max-width: 576px) {
    left: -16px;
    padding: 16px;
    min-height: (142px - 32px);
  }

  &__tags {
    display: flex;
    gap: 40px;

    @media (max-width: 576px) {
      justify-content: space-between;
    }
  }

  &-tag {
    color: $color-text;
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
    text-decoration: none;
  }

  &__title {
    color: $color-text;
    font-family: "Gramatika-Medium";
    font-size: 3rem;
    font-weight: 400;
    line-height: 4.5rem;
    width: 651px;
    margin-top: 16px;

    @media (max-width: 991px) {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 30px;
      width: 100%;
    }

    @media (max-width: 576px) {
      margin-top: 24px;
    }
  }

  &__wraper {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 80px;

    @media (max-width: 991px) {
      margin-top: 30px;
    }

    @media (max-width: 576px) {
      margin-top: 24px;
      gap: 0;
      justify-content: space-between;
    }
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 576px) {
      gap: 20px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__icon-img {
    width: 24px;
    height: 24px;
  }

  &__icon-count {
    color: $color-text;
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__date {
    color: $color-text;
    font-family: "Inter-Regular";
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>
