<template>
  <div class="skills" id="skills">
    <div class="skills__header">
      <h2 class="skills__title">Навыки полученные практикой в VR</h2>
      <div
        class="skills__animation__container"
        ref="container"
        :style="getStyleAnimatedBacground"
      >
        <img
          ref="img"
          src="@/assets/icons/course/monitor_icon.svg"
          alt=""
          class="skills__animation__img"
          :style="getStyleAnimatedRight"
        />
      </div>
    </div>
    <div class="skills__list">
      <div class="skills__item" v-for="(item, i) in data?.vr_skills" :key="i">
        <img
          src="@/assets/icons/course/skill__icon.svg"
          alt="icon"
          class="skills__item__img"
        />
        <p class="skills__item__text">
          {{ item.value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

interface IData {
  translatePercentScroll: number;
  coefficientWidthImg: number;
  translateXBg: number;
  translateYBg: number;
}

let refContainer: HTMLDivElement;
let refImg: HTMLImageElement;

export default defineComponent({
  name: "SkillsCourse",
  data(): IData {
    return {
      translatePercentScroll: 0,
      coefficientWidthImg: 0,
      translateXBg: 0 || 478,
      translateYBg: 228,
    };
  },
  props: ["data"],
  mounted() {
    refContainer = this.$refs.container as HTMLDivElement;
    refImg = this.$refs.img as HTMLImageElement;
    window.addEventListener("scroll", this.checkScrollPosition);
    window.addEventListener("resize", this.getResize);
  },
  methods: {
    checkScrollPosition() {
      this.getCoefficientWidthImg();
      this.translatePercentScroll =
        scrollY <= refContainer.offsetTop
          ? Math.round((scrollY / refContainer.offsetTop) * 100)
          : 100;
      this.translateXBg = Math.round(
        478 - (106 * this.translatePercentScroll) / 100
      );
    },

    getResize() {
      this.checkScrollPosition();
    },

    getCoefficientWidthImg() {
      this.coefficientWidthImg =
        (refImg.parentElement?.clientWidth
          ? refImg.parentElement?.offsetWidth - refImg.offsetWidth
          : 0) / refImg.offsetWidth;
    },
  },
  computed: {
    getStyleAnimatedRight: function (): string {
      return `transform: translate(${
        this.translatePercentScroll * this.coefficientWidthImg
      }%, 0)`;
    },
    getStyleAnimatedBacground: function (): string {
      return `backgroundPosition: -${this.translateXBg}px -${this.translateYBg}px ; `;
    },
  },
});
</script>

<style lang="scss" scoped>
.skills {
  margin-top: 148px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 0 0 62px 0;

  @media (max-width: 991px) {
    margin-top: 64px;
    padding: 0 0 146px 0;
  }

  @media (max-width: 576px) {
    margin-top: 64px;
    padding: 0 0 16px 0;
  }
}

.skills__header {
  display: flex;
  gap: 48px;
  align-items: center;

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.skills__title {
  font-family: Gramatika-Medium;
  font-size: 3rem;
  font-weight: 400;
  line-height: 4.5rem;
  background-image: $color-gradient;
  background-clip: text;
  color: transparent;
  max-width: 593px;

  @media (max-width: 1280px) {
    max-width: 100%;
  }

  @media (max-width: 991px) {
    font-size: 2.25rem;
    line-height: 3.375rem;
  }

  @media (max-width: 576px) {
    font-size: 1.75rem;
    line-height: 2.625rem;
  }
}

.skills__animation__container {
  width: 632px;
  height: 213px;
  background: url("@/assets/images/course/skill_bg.jpg");
  background-position: -478px -220px;
  border-radius: 24px;
  flex-shrink: 0;
  position: relative;
  transition: all 1s ease;

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 576px) {
    height: 100px;
  }
}

.skills__animation__img {
  position: absolute;
  bottom: -30px;
  left: 0px;
  width: 308px;
  transform: translate(0, 0);
  transition: all 1s ease;

  @media (max-width: 1280px) {
    bottom: -20px;
  }

  @media (max-width: 991px) {
    width: 240px;
  }

  @media (max-width: 576px) {
    bottom: -12px;
    width: 150px;
  }
}

.skills__list {
  display: grid;
  row-gap: 8px;
  grid-column-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
}

.skills__item {
  display: grid;
  grid-column-gap: 18px;
  grid-template-columns: 53px 1fr;
  padding: 0 8px;
  align-items: center;
  grid-template-rows: minmax(90px, auto);

  @media (max-width: 991px) {
    grid-template-rows: minmax(60px, auto);
    padding: 0;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 0 6px;
  }

  &__img {
    border-radius: 8px;
    background: $color-blue;
    padding: 4px;
    box-sizing: border-box;
    width: 53px;
    height: 49px;

    @media (max-width: 576px) {
      display: none;
    }
  }

  &__text {
    color: $color-dark-E343D;
    font-family: Inter-Regular;
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}
</style>