<template>
    <section class="error404">
        <h2 class="error404__title"><span>404</span> Страница не найдена</h2>
        <div class="error404__container">
            <div class="error404__wrap">
                <p class="error404__text">
                    Запрашиваемой страницы не существует. Возможно в запросе был указан неверный адрес страницы или она была удалена 
                </p>
                <buttton class="error404__btn" @click="linkToMain()">
                    <span
                      class="error404__btn__text"
                      >На главную</span>
                      <svg class="error404__btn__svg" xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24" fill="none">
                        <path d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5L2 10.5ZM27.0607 13.0607C27.6464 12.4749 27.6464 11.5251 27.0607 10.9393L17.5147 1.3934C16.9289 0.807611 15.9792 0.807611 15.3934 1.3934C14.8076 1.97919 14.8076 2.92893 15.3934 3.51472L23.8787 12L15.3934 20.4853C14.8076 21.0711 14.8076 22.0208 15.3934 22.6066C15.9792 23.1924 16.9289 23.1924 17.5147 22.6066L27.0607 13.0607ZM2 13.5L26 13.5V10.5L2 10.5L2 13.5Z" fill="url(#paint0_linear_1443_20558)"/>
                        <defs>
                            <linearGradient id="paint0_linear_1443_20558" x1="2" y1="12" x2="7.59892" y2="22.6625" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#7277FF"/>
                                <stop offset="1" stop-color="#FF5A5A"/>
                            </linearGradient>
                        </defs>
                      </svg>                  
                </buttton>
            </div>

            <div class="error404__wrap">
                <p class="error404__text">
                    Вы можете ознакомиться с курсами, практика которых проходит в виртуальной реальности                
                </p>
                <buttton class="error404__btn" @click="linkToCourses()">
                    <span
                      class="error404__btn__text"
                      >В каталог курсов</span>
                      <svg class="error404__btn__svg" xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24" fill="none">
                        <path d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5L2 10.5ZM27.0607 13.0607C27.6464 12.4749 27.6464 11.5251 27.0607 10.9393L17.5147 1.3934C16.9289 0.807611 15.9792 0.807611 15.3934 1.3934C14.8076 1.97919 14.8076 2.92893 15.3934 3.51472L23.8787 12L15.3934 20.4853C14.8076 21.0711 14.8076 22.0208 15.3934 22.6066C15.9792 23.1924 16.9289 23.1924 17.5147 22.6066L27.0607 13.0607ZM2 13.5L26 13.5V10.5L2 10.5L2 13.5Z" fill="url(#paint0_linear_1443_20558)"/>
                        <defs>
                            <linearGradient id="paint0_linear_1443_20558" x1="2" y1="12" x2="7.59892" y2="22.6625" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#7277FF"/>
                                <stop offset="1" stop-color="#FF5A5A"/>
                            </linearGradient>
                        </defs>
                      </svg>                  
                </buttton>
            </div>

            <div class="error404__wrap">
                <p class="error404__text">
                    Воспользуйтесь возможностью погрузиться в виртуальную среду климатотехники на бесплатном уроке 
                </p>
                <buttton class="error404__btn" @click="linkToFreeLearn()">
                    <span
                      class="error404__btn__text"
                      >Бесплатный урок в VR</span>
                      <svg class="error404__btn__svg" xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24" fill="none">
                        <path d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5L2 10.5ZM27.0607 13.0607C27.6464 12.4749 27.6464 11.5251 27.0607 10.9393L17.5147 1.3934C16.9289 0.807611 15.9792 0.807611 15.3934 1.3934C14.8076 1.97919 14.8076 2.92893 15.3934 3.51472L23.8787 12L15.3934 20.4853C14.8076 21.0711 14.8076 22.0208 15.3934 22.6066C15.9792 23.1924 16.9289 23.1924 17.5147 22.6066L27.0607 13.0607ZM2 13.5L26 13.5V10.5L2 10.5L2 13.5Z" fill="url(#paint0_linear_1443_20558)"/>
                        <defs>
                            <linearGradient id="paint0_linear_1443_20558" x1="2" y1="12" x2="7.59892" y2="22.6625" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#7277FF"/>
                                <stop offset="1" stop-color="#FF5A5A"/>
                            </linearGradient>
                        </defs>
                      </svg>                  
                </buttton>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'Error404Component',
    methods: {
    linkToFreeLearn() {
      this.$router.push({ name: "free-learn" });
    },
    linkToCourses() {
        this.$router.push({ name: "courses" });
    },
    linkToMain() {
        this.$router.push({ name: "main" });
    }
  },
};
</script>

<style lang="scss" scoped>
.error404{
    box-sizing: border-box;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 73px;
    padding-left: 80px;
    padding-right: 80px;
    height: 770px;
    background-image: url('@/assets/images/error/error-background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 991px){
        background-image: url('@/assets/images/error/error-background991.png');
        margin-top: 52px;
        padding-left: 60px;
        padding-right: 60px;
        height: 694px;
    }

    @media (max-width: 746px){
        height: 804px;
    }

    @media (max-width: 576px){
        background-image: url('@/assets/images/error/error-background576.png');
        margin-top: 44px;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.error404__title {
    padding-top: 66px;    
    margin-bottom: 60px;    
    font-family: "Gramatika-Medium";
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    color: $color-dark-E343D;
    display: flex;
    align-items: center;

    @media (max-width: 991px){
    padding-top: 53px;
    margin-bottom: 46px;
    }

    @media (max-width: 576px){
        display: block;
        padding-top: 14px;
        margin-bottom: 28px;
    }

    span{
        font-size: 4rem;
        line-height: 6rem;
        margin-right: 24px;
        margin-top: -19px;

        @media (max-width: 576px){
            display: block;
            font-size: 3rem;
            line-height: 4rem;
            margin-top: 0px;
            margin-bottom: 6px;
        }
    
    }
}
.error404__container {
    display: flex;
    flex-wrap: wrap;
    gap: 64px;

    @media (max-width: 1360px){
        justify-content: space-between;
        gap: 10px;
    }
}
.error404__wrap {
    width: 352px;
    margin-bottom: 30px;

    @media (max-width: 991px){
        width: 300px;
    }

    @media (max-width: 576px){
    margin-bottom: 22px;
    }
}
.error404__text {
    font-family: 'Inter-Regular';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: $color-dark-E343D;
    margin-bottom: 18px;
}

.error404__btn {
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-out;
    user-select: none;
  
    &__text {
        font-family: 'Gramatika-Medium';
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.875rem;
        margin-right: 16px;
        margin-top: -5px;
        background-image: $color-gradient;        
        background-clip: text;
        color: transparent;
    }
  
    &__svg {
        width: 24px;
    }
}
  
.error404__btn:hover {
    .error404__btn__text {
      color: $color-hover;
    }
  
    .error404__btn__svg path {
      fill: $color-hover;
    }
}

.error404__btn:active {
    .error404__btn__text {
      color: $color-form-text;
    }
  
    .error404__btn__svg path {
      fill: $color-form-text;
    }
}
  
.error404__btn:disabled {
    .error404__btn__text {
      color: $color-blue;
    }
  
    .error404__btn__svg path {
      fill: $color-blue;
    }
}
</style>